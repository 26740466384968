import React, { Component } from 'react'
import { Layout,Modal,Radio,message,Tag} from 'antd'
import { NavLink } from "react-router-dom"
import Pubsub from 'pubsub-js'
import TableComponents from '../../../components/common/tables/TableComponentsNew'
import Conditions from '../../../components/common/conditions/Conditions'
import {TYPE_NAME} from '../../../components/Const'
import {PlusSquareOutlined,EditOutlined} from '@ant-design/icons';
import FormsTwo from '../../../components/common/forms/FormsTwo'
import {funTraceInfo} from '../../../services/common'

import {getDeviceTypes,pcGetUserDevicesByCondition} from '../../../services/IKit'

export default class PointManagement extends Component {

    state = {
		// 表格参数
		tableParamenter: {
			manageName: "数据项管理",
			// 表格行是否可选
            rowSelection: false,
			//下拉框长度
			selectWidth: '6.5vw',
			// 表头：变量和变量解释
			header: {
				deviceName: '设备名称',
				address: '安装地址',
				location:'安装位置',
				deviceTypeName:'设备类型',
				userPhone:'用户',
				addTime: '添加时间',
			},
			// 弹出框
			headers: {
				deviceName: '设备名称',
				address: '安装地址',
				location:'安装位置',
				deviceTypeName:'设备类型',
				userPhone:'用户',
				addTime: '添加时间',
			},
			params: {
				page:1,
				size:10,
		},
			// 是否绑定左侧区域
			bindArea: true
		},
		// 条件选择器参数
		conditionsParamenter: {
			parameter: [
				{
					type: TYPE_NAME.NAME,
					variableName: 'deviceName',
					str: '设备名称'
				},
				{
					type: 'select',
					variableName: 'deviceType',
					str: '设备类型',
					data: []
				},
			]
		},
		    	// 总条数
		totalCount: 0,
	}

	setTableValue = (value,text,rowData) => {
		if(text === 'null') return '-'
		switch (value) {
			default:
				return text ? <div className={'table_overflow'} title={text}>{text}</div> : ''
		}
	}
	componentDidMount(){
		getDeviceTypes().then(
			response => {
				const {data} = response?.data;
				let selectArry=[]
				if(data){
					for(var i=0;i<data.length;i++){
						selectArry.push({key:data[i].id,value:data[i].name})
					}
				}
				this.state.conditionsParamenter.parameter[1].data=selectArry;
			}
		)
		console.log(this.state.params)
	}
	onChangeSelect = e => {
		console.log(111,e)
	}

	// 条件选择：获取输入的条件
	getConditionsValue = async value => {
		let params = this.state.tableParamenter.params
		Object.assign(params,value)
		let {tableParamenter} = this.state
		tableParamenter.params = params
		this.setState({tableParamenter})
		await this.table.refreshTable(params);

	};
    getAreaId = () => {
		Pubsub.subscribe('areaIds', (_, data) => {
		  let {params, initParams} = this.state
		  params.areaIds = data
		  params.page = 1
		  params.size = initParams.size
		  this.setState({params})
		  this.getData()
		})
	  }
// 初始化表格数据
getData = () => {
	this.setState({})
	this.table.refreshTable();
  }


	// 清空条件
	clearCondition = () => {
		let {params} = this.state
		// params.relevanceInfoAssociationName=null,
		// params.alarmLevel=null,

	   this.setState({params:params})
	   this.getData();
	}
    render() {
        return (
            <Layout className={'item_body_number_back tables'}>
				<div className={'topSearchBox'}>
				<h3>设备信息</h3>
				<Conditions
                        conditionsParamenter={this.state.conditionsParamenter} // 条件框输入框设置
                        getConditionsValue={this.getConditionsValue} // 获取条件框输入的值
                        clearCondition={this.clearCondition} // 清除条件
                    >
				</Conditions>
				</div>
				{/* <div className={'topBtnBox'}>
					<span className={'addLink'} onClick={() => this.addQrcodeStatus(true)}><PlusSquareOutlined />新增</span>
					<span className={'addLink'} onClick={() => this.updatePoint(true)}><EditOutlined />修改</span>

				</div>	 */}
				<TableComponents
					paramenter={this.state.tableParamenter}
					setData = {pcGetUserDevicesByCondition}
					setTableValue={this.setTableValue}
					ref={e => this.table = e}
				>
				</TableComponents>
            </Layout>
        )
    }
}
