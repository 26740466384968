import React, { Component } from 'react'
import {Button, Form, Checkbox, Modal, Tag, Input, Select, Divider, Space, DatePicker, message} from "antd";
import './myWorkOrders.less'
import FormsTwo from "../../components/common/forms/FormsTwo";

import { FileTextOutlined,ToolOutlined,CheckCircleOutlined,ExclamationCircleOutlined,PlusOutlined,UserAddOutlined,EyeOutlined,BellOutlined } from '@ant-design/icons';
import {TYPE_NAME} from "../../components/Const";
import Table from "antd/es/table";
import {
    pageHiddenDanger,
    queryHandler, queryPageComplaintTypes,
    queryPageHandler,
    queryPageWorkOrder,
    saveHandler, urging,
    workAllocation
} from "../../services/meter";
import TableComponents from "../../components/common/tables/TableComponentsNew";
import {getDataIgnoreLevel} from "../../services/steamReport";
import moment from "moment";

const {TextArea} =Input;
const { Option } = Select;
export default class myWorkOrders extends Component {
    state={

        complaintTypeS:[],// 投诉类型

        // 表格参数
        tableParamenter: {
            manageName: "SIM卡申请管理",
            // 表格行是否可选
            rowSelection: false,
            //下拉框长度
            selectWidth: '6.5vw',
            bordered:false,
            isNotInitData:false,// 初始化禁止访问后端
            // 表头：变量和变量解释
            header: {
                id: '工单编号',
                processNote: '投诉内容',
                complainantName: '投诉人',
                area: '区域',
                state: '状态',
                emergencyLevel: '紧急程度',
                assignee: '处理人',
                createdTime: '创建时间',
                edit: '操作',
            },
            params:{
                page:1,
                size:6
            },
        },
    };
    // 初始化
    componentDidMount(): void {
        this.queryPageComplaintTypes();
    }

    // 分页查询所有投诉类型
    queryPageComplaintTypes= () =>{
        var param = {
            page:1,
            size:999
        };
        queryPageComplaintTypes(param).then(
            response => {
                if(response?.data?.data){
                    const {data} = response.data;
                    let selectArry=[];
                    if(data){
                        this.setState({areaInfos:data});
                        for(var i=0;i<data.length;i++){
                            selectArry.push(Object.assign(data[i],{key:data[i].id,value:data[i].typeName}))
                        }
                    }
                    this.setState({complaintTypeS:selectArry})
                }
            }
        )
    };


    setTableValue = (value,text,rowData) => {
        switch (value) {
            case 'emergencyLevel':
                return text === 'high' ? <Tag color={'#ff4d4f'}>高</Tag> :
                    text === 'medium' ? <Tag color={'#faad14'}>中</Tag> :
                        text === 'low' ? <Tag color={'#52c41a'}>低</Tag>:'';

            case 'state':
                return text === '待处理' ? <Tag color={'#1890ff'}>待处理</Tag> :
                    text === '处理中' ? <Tag color={'#faad14'}>处理中</Tag> :
                        text === '已超时' ? <Tag color={'#ff4d4f'}>已超时</Tag>:
                            text === '已完成' ? <Tag color={'#52c41a'}>已完成</Tag>:'';
            case 'createdTime':
                return text?moment(text).format('yyyy-MM-DD HH:mm:ss'):null;
            case 'edit':
                return (
                    <div>
                        <Button
                            icon={<EyeOutlined />}
                            onClick={()=>{
                                this.props.history.push({
                                    pathname: '/workOrder/workOrderDetails',
                                    state: {rowData:rowData}
                                })
                            }}
                        >
                            查看
                        </Button>
                        &nbsp;&nbsp;
                        {
                            rowData?.assignee ?
                                null:
                                <Button icon={<UserAddOutlined />}
                                        onClick={async ()=>{
                                            rowData.expectedProcessTime=rowData?.expectedProcessTime?moment(rowData?.expectedProcessTime):null;
                                            await this.setState({
                                                windowSwitch_1:true,
                                                initialValues_1:rowData
                                            });
                                            this.forms1.onModalClose();
                                        }}
                                >分配</Button>
                        }

                        &nbsp;&nbsp;
                        <Button type="primary" danger icon={<BellOutlined />}
                                onClick={()=>{
                                    this.urging(rowData?.id??null);    // 催办
                                }}
                        >催办</Button>
                    </div>
                );
            default:
                return text ? <div className={'table_overflow'} title={text}>{text}</div> : ''
        }
    };
    render(){

        return(
            <div id={'myWorkOrders'}>
                {/*标题*/}
                <div className={'myWorkOrders_topSearchBox'}>
                    <h3>我的工单</h3>
                </div>

                {/*数据栏 ---------------------------------------------------*/}
                <div className={'myWorkOrders_css_div_head_1'}>
                    <div style={{width:'100%',height:'30%',padding:'18px 0 0 10px'}}>
                        <Button type={'primary'}>刷新</Button>
                    </div>
                    <div style={{width:'100%',height:'70%'}}>
                        {/*待处理工单*/}
                        <div className={'myWorkOrders_css_div_head_1_div_frame'}>
                            <div className={'myWorkOrders_css_div_head_1_div_frame_statistic-card'}>
                                <div className={'myWorkOrders_css_div_head_1_div_frame_icons'} style={{backgroundColor:'#4a8fd9'}}>
                                    <FileTextOutlined style={{fontSize:'36px',color:"#fff"}}/>
                                </div>
                                <div style={{width:'70%',height:'100%',padding:'5px 0 0 15px',float:'left' }}>
                                    <div><span>待处理工单</span></div>
                                    <div>
                                        <span style={{fontSize:'26px'}}>3</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/*处理中工单*/}
                        <div className={'myWorkOrders_css_div_head_1_div_frame'}>
                            <div className={'myWorkOrders_css_div_head_1_div_frame_statistic-card'}>
                                <div className={'myWorkOrders_css_div_head_1_div_frame_icons'} style={{backgroundColor:'#ffbd00'}}>
                                    <ToolOutlined style={{fontSize:'36px',color:"#fff"}}/>
                                </div>
                                <div style={{width:'70%',height:'100%',padding:'5px 0 0 15px',float:'left' }}>
                                    <div><span>处理中工单</span></div>
                                    <div>
                                        <span style={{fontSize:'26px'}}>3</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/*已完成工单*/}
                        <div className={'myWorkOrders_css_div_head_1_div_frame'}>
                            <div className={'myWorkOrders_css_div_head_1_div_frame_statistic-card'}>
                                <div className={'myWorkOrders_css_div_head_1_div_frame_icons'} style={{backgroundColor:'#52C41A'}}>
                                    <CheckCircleOutlined style={{fontSize:'36px',color:"#fff"}}/>
                                </div>
                                <div style={{width:'70%',height:'100%',padding:'5px 0 0 15px',float:'left' }}>
                                    <div><span>已完成工单</span></div>
                                    <div>
                                        <span style={{fontSize:'26px'}}>3</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/*已完成工单*/}
                        <div className={'myWorkOrders_css_div_head_1_div_frame'}>
                            <div className={'myWorkOrders_css_div_head_1_div_frame_statistic-card'}>
                                <div className={'myWorkOrders_css_div_head_1_div_frame_icons'} style={{backgroundColor:'#FF4D4F'}}>
                                    <ExclamationCircleOutlined style={{fontSize:'36px',color:"#fff"}}/>
                                </div>
                                <div style={{width:'70%',height:'100%',padding:'5px 0 0 15px',float:'left' }}>
                                    <div><span>超时工单</span></div>
                                    <div>
                                        <span style={{fontSize:'26px'}}>3</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* 表格 */}
                <div className={'myWorkOrders_css_div_head_2'}>
                    {/*内框*/}
                    <div className={'myWorkOrders_css_div_head_2_div_frame'}>
                        {/*内标题*/}
                        <div className="myWorkOrders_css_div_head_2_card-header">
                            <h3 style={{width:'85%', margin: '0', fontSize: '16px', fontWeight: '500',float:'left'}}>工单列表</h3>
                            <div style={{float:'right'}}>

                                <Select
                                    defaultValue="全部状态"
                                    style={{ width: 120 }}
                                    options={[
                                        { value: '全部状态', label: '全部状态' },
                                        { value: '待处理', label: '待处理' },
                                        { value: '处理中', label: '处理中' },
                                        { value: '已完成', label: '已完成' },
                                        { value: '已超时', label: '已超时' }
                                    ]}
                                />

                                &nbsp; &nbsp;
                                <Select
                                        defaultValue="全部类型"
                                        options={this.state.complaintTypeS}

                                />
                            </div>
                        </div>
                        {/*表格*/}
                        <div style={{padding:'10px'}}>
                            <TableComponents
                                paramenter={this.state.tableParamenter}
                                setData={queryPageWorkOrder}
                                setTableValue={this.setTableValue}
                                ref={e => this.table = e}
                            />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
