import React, { Component  } from 'react'
import {
    Layout,
    Input,
    Button,
    Tree,
    Collapse,
    Drawer,
    Checkbox,
    Modal,
    Typography,
    Descriptions,
    message,
    Tooltip,
    Radio,
    Popconfirm
} from "antd";
import FormsTwo from "../../../components/common/forms/FormsTwo";
import {TYPE_NAME} from "../../../components/Const";
import {funTraceInfo} from '../../../services/common'
import './addEquipment.less'
import {SearchOutlined,DownOutlined,SettingOutlined , } from '@ant-design/icons';
import {listClassAndModelInfo,addDevClass,listDevModelInfo,getDevModelAttr,deleteDevModelItems,addDevModel,updateDevModel} from "../../../services/equipment";
import {deleteModel, getAllItem} from '../../../services/device'
const { Title } = Typography;
const { DirectoryTree } = Tree;
const { Panel } = Collapse;
const { Search } = Input;
export default class addEquipment extends Component {
    state = {
        selectClassModel:null,
        treeData:[],     //原版数据未进行转换
        treeNodeInfo: [],//转换符合当前树形结构的数据
        expandedKeys: [],//树节点筛选集-模糊查询使用
        searchValue: '', //保留模糊查询输入内容
        // 表单参数
        formParamenter: {
            modal: true,
            number: 4,
            labelCol: 8,
            layout: "horizontal",
            parameter: [
                {variableName: 'classId', str: '设备类型',
                    rules: TYPE_NAME.REQ_SELECT,
                    disabled: false,
                    data: [ ]
                },
                {variableName: 'devModelName', str: '设备型号', rules: TYPE_NAME.NOT_BLANK},
                {variableName: 'manufacture', str: '生产厂商', rules: TYPE_NAME.NOT_BLANK},
                {variableName: 'searchName', str: '检索名称', rules: TYPE_NAME.NOT_BLANK},
                {variableName: 'remark', str: '备注', rules: TYPE_NAME.NOT_BLANK},
                {variableName: 'thresholdValue', str: '掉线阈值', rules: TYPE_NAME.ID_NUMBER},
                {
					variableName: 'itemId',
					str: '唯一标识',
					isMultiple:true,
                    disabled: false,
					data:[],
					rules: TYPE_NAME.SELECTINPUT,
				},

            ]
        },
        essentialData: {},      // 本型号基本数据
        differentAttributes:[], // 本型号中各设备值不同的属性
        gatherAttributes:[],    // 本型号中各设备需要采集的属性-暂不管已配置好-
        newGatherAttributes:[], // 本型号中各设备需要采集的属性-转变合适的数据-暂不管已配置好
        sameAttributes:[],      // 本型号中各设备值相同的属性
        configData:[],          // 本型号中设备配置项
        plainOptions:[],        // 数据项多选框
        // isShow: false,
        text :`暂无数据`,

        // 复选框的数据集
        optionsWithDisabled : [],  // 获得数据库的数据项
        newOptionsWithDisabled:'', // 文本输入要求获得数据

        // 型号数据-新增删除用
        selectModel:{
            devModelId:null,    // 型号id
            devModelName:null   // 型号名
        },
        VoDevModelQueryAttr:{
            devModelId:null,
            common:null,//属性类型，为空则默认查询所有类型,1/共有，2/私有
        },
        buttonTile:"修改",
        drawerSwitchData:null,  //折叠框层级
        visibles:false,     // 右边弹出框开关器
        moda1Visible:false, // 小窗口开关器-1
        moda2Visible:false, // 小窗口开关器-2
    };
    //系统初始化配置-及其他配置
    componentDidMount() {
        console.log("初始化启动");
        this.getTreeDataInfo();  // 获得树形结构的数据
        this.queryAttrVo();      // 获取全部数据项-用于右侧多选框数据
        getAllItem().then(
			response => {
			    if(response?.data?.data){
                    const {data} = response.data;
                    let selectArry=[];
                    if(data){
                        for(var i=0;i<data.length;i++){
                            selectArry.push({key:data[i].id,value:data[i].name});
                        }
                    }
                    this.state.formParamenter.parameter[6].data=selectArry;
                }

			}
		)
    }
    // 弹出小窗口开关-1
    derailingSwitch1=(moda1Visible)=>{
        this.setState({moda1Visible});
    };
    // 弹出小窗口开关-1
    derailingSwitch2=(moda2Visible)=>{
        this.setState({moda2Visible});
    };
    // 折叠框 弹出右框功能开关
    genExtra =(data)=>{
        // 开关按钮-数据项
        return <a
            onClick={event => {
                this.setState({visibles:true,drawerSwitchData:data} );
                this.genExtraVisibles(data);// 将折叠面板框选择框内的数据传递给右侧弹出框
                event.stopPropagation();

            }}
        >数据项</a>
    };
    // 右侧弹出框关闭
    onClose=()=>{
        console.log("右侧弹出框开关");
        this.exportData();
        this.setState({visibles:false} )
    };
    //---------------------------------------------------左侧功能--------------------------------------------------------
    // 左侧框新增型号输入框功能
    addModelInput=(data)=>{
        const voDevModel = this.state.selectModel;
        voDevModel.devModelName=null;
        voDevModel.devModelName=data.target.value;
        this.setState({voDevModel})
    };
    // 左侧框新增型号-访问后新增型号
    addModel=(value)=>{
        console.log("新增型号");
        addDevClass({devClassName:this.state.selectModel.devModelName}).then(data=>{
            console.log(data)
            let {formParamenter} = this.state;
            if(data.data.data==true){
                message.config("添加成功")
                funTraceInfo("设备管理", "新增型号", null,this.state.selectModel.devModelName)
                this.derailingSwitch1(false)
                formParamenter.parameter[0].data=[]
                this.getTreeDataInfo();  // 获得树形结构的数据

            }else{
                this.derailingSwitch1(false)
                message.config('测试失败')
                formParamenter.parameter[0].data=[]
                this.getTreeDataInfo();  // 获得树形结构的数据

            }
        });
    };

    // 刪除功能-文本提示
    reminderHint=()=>{
        const voDevModel = this.state.selectModel;
        console.log("刪除功能-文本提示");
        console.log(voDevModel);
        if(voDevModel.devModelId===null){
            return <Title style={{paddingLeft: 35}} level={5}>请点击选择要删除的类型</Title>
        }else {
            return<Title style={{paddingLeft: 35}} level={5}>确认删除当前选择类型</Title>
        }
    };
    // 刪除功能
    reminder=(value)=>{
        console.log('刪除功能');
        this.derailingSwitch2(value);
        const voDevModel = this.state.selectModel;
        console.log(voDevModel);
        if(voDevModel.devModelId!==null){
            const VoDelModelItems = {devModelId:voDevModel.devModelId.toString(),itemIdsList:[] };
            deleteDevModelItems(VoDelModelItems).then(item=>{
                funTraceInfo("设备管理", "删除页面", null,VoDelModelItems)
                console.log(item)
            })
        }
    };

    // 获取 数据库内 的 树形结构数据
    getTreeDataInfo = () => {
        console.log("获得树形结构的数据");
        listClassAndModelInfo().then(data=>{
            if(data?.data?.data){
                let {treeData,formParamenter} = this.state;
                formParamenter.parameter[0].data=[];
                treeData=data.data.data;
                treeData.forEach(item=>{
                    formParamenter.parameter[0].data.push({key:item.devClassId,value:item.devClassName})
                });
                this.setState({treeData:treeData});
                this.fatherNode();  // 树形结构-内容转换
            }
        })
    };
    // 树形结构-转换父节点用于前端需要
    fatherNode=()=>{
        // console.log("树形结构-转换父节点");
        let {treeData,treeNodeInfo} = this.state;
        let keys=[];
        keys = treeData?.map(({devClassName,devClassId,devModels}) => {
            return {
                title: devClassName,
                key: devClassId,
                children: this.childNode(devModels)
            }
        });
        treeNodeInfo = JSON.parse(JSON.stringify(keys));
        this.setState({treeNodeInfo:treeNodeInfo});
    };
    // 树形结构-转换子节点用于前端需要
    childNode=(treeData)=>{
        // console.log("树形结构-转换子节点");
        let keys = [];
        keys = treeData?.map(({devModelName,devModelId,devModels}) => {
            return {
                title: devModelName,
                key: devModelId,
                children: this.childNode(devModels),
            }
        });
        return keys
    };
    // 树形结构点击触发-获取选取的型号详情-该功能用于清除原选的数据
    onExpand=(value)=> {
        // console.log("树形结构点击触发");
        // console.log(value);
        this.state.formParamenter.parameter[6].disabled=true;
        // 本型号基本数据,本型号中各设备值不同的属性,本型号中各设备需要采集的属性,本型号中各设备值相同的属性,本型号中各设备需要采集的属性
        // 树形结构选取的型号id,本型号中设备配置项
        let {essentialData,differentAttributes,sameAttributes,gatherAttributes,newGatherAttributes,selectModel,configData}  =this.state;
        selectModel.devModelId=value;   // 树形结构选取的型号id
        essentialData=[];               // 本型号基本数据
        differentAttributes=[];         // 本型号中各设备值不同的属性
        gatherAttributes=[];            // 本型号中各设备需要采集的属性
        sameAttributes=[];              // 本型号中各设备值相同的属性
        newGatherAttributes=[];         // 本型号中各设备需要采集的属性-转变成适合的数据
        configData=[];                  // 本型号中设备配置项
        this.setState({
            selectModel:selectModel,
            essentialData:essentialData,
            differentAttributes:differentAttributes,
            sameAttributes:sameAttributes,
            gatherAttributes:gatherAttributes,
            newGatherAttributes:newGatherAttributes,
            configData:configData
        });
        this.forms.onModalClose();
        this.getModeData(value);    // 树形结构数据
    };
    // 树形结构数据-点击获取指定数据
    getModeData=(value)=>{
        console.log("树形结构数据-获取指定数据");
        // 根据设备类型id查询设备型号信息-设备页面
        listDevModelInfo({modelIds:value}).then(response => {
            console.log(response);
            // 判断获取数据是否为空
            if (response.data.data){
                // 配置数据
                this.state.formParamenter.parameter[0].disabled=true;
                // 本型号基本数据,本型号中各设备值不同的属性,本型号中各设备需要采集的属性,本型号中各设备值相同的属性,本型号中各设备需要采集的属性
                // 树形结构选取的型号id,本型号中设备配置项
                let {essentialData,differentAttributes,sameAttributes,gatherAttributes,newGatherAttributes,selectClassModel,configData} = this.state;
                essentialData = response?.data?.data?.[0];      // 基本数据
                // 转换符合当前树形结构的数据
                this.state.treeNodeInfo?.forEach(item=>{
                    if(item.children.some(e=> e.key.toString()===value.toString())){
                        console.log(item);
                        selectClassModel=item.key;
                        essentialData.devModelId=
                            essentialData.classId=item.key;
                    }
                });
                // 后台数据里面有个对象的数据是分配给两个的
                essentialData.attr.forEach(item=>{
                    if(item.common===2){
                        differentAttributes.push(item)  // 本型号中各设备值不同的属性
                    }else{
                        sameAttributes.push(item)       // 本型号中各设备值相同的属性
                    }
                });

                gatherAttributes=essentialData.data;    // 本型号中各设备需要采集的属性
                if(essentialData.config!==null){
                    configData=essentialData.config;        // 本型号中设备配置项
                }

                this.state.optionsWithDisabled.forEach(disabled=>{
                    if(essentialData.data.some(essential=> essential.id?.toString() === disabled.id.toString())){
                        newGatherAttributes.push(disabled)
                    }
                });
                this.setState({
                    selectClassModel:selectClassModel,
                    essentialData:essentialData,
                    differentAttributes:differentAttributes,
                    sameAttributes:sameAttributes,
                    gatherAttributes:gatherAttributes,
                    newGatherAttributes:newGatherAttributes,
                    configData:configData
                });
                this.forms.onModalClose();
                console.log(newGatherAttributes);
            }
        });
    };
    // 获取需要筛选的节点名-输入框内容
    inputFindNode=(e)=>{
        const { value } = e.target;
        if(!value){
            this.setState({expandedKeys:[], searchValue: value});
            return null
        }
        let treedata = this.traverseTree();// 平摊化数据
        const expandedKeys =treedata
            .map(item =>{
                if(item.title.indexOf(value)!==-1){
                    return item.key.toString();
                }
                return null;
            })
            .filter((item, i, self) => item && self.indexOf(item) === i);

        this.setState({
            expandedKeys,
            searchValue: value
        })
    };
    // 平摊分层的树节点-用于检索功能需要
    traverseTree=()=>{
        const treeDataList=[];
        this.state.treeNodeInfo.forEach(
            item => {
                treeDataList.push(item);
                if(item.children.length!==0){
                    item.children.forEach(data =>{
                        treeDataList.push(data)
                    })
                }
            });
        return treeDataList;
    };
    // 筛选根节点数据-用于检索功能需要
    selectedTree=()=>{
        let treedata = this.traverseTree();         // 将树结构内的数据平摊化
        const expandedKeys= this.state.expandedKeys;// 树节点筛选集-模糊查询使用
        const newTreeData=[];
        if(expandedKeys.length!==0){
            expandedKeys.forEach(item=>{
                treedata?.forEach(data=>{
                    if(item===data.key.toString()){
                        data.children=[];
                        newTreeData.push(data)
                    }
                })
            });
        }else{
            return this.state.treeNodeInfo
        }
        return newTreeData;
    };

    // 树形构造-样式修改
    loop = (data,display) =>
        data.map(item => {
            const index = item.title.indexOf(this.state.searchValue);// 获取相同值的位置

            const beforeStr = index!==-1?item.title.substr(0, index):null;  // 截取前半部分没有相同的值
            const afterStr = index!==-1?item.title.substr(index + this.state.searchValue.length):null;  // 获取后半部分不相同的值
            // const title =
            //     index > -1 ?
            //         (<span>{beforeStr}<span className="site-tree-search-value">{this.state.searchValue}</span>{afterStr}</span>)
            //         :
            //         (<span>{item.title}</span>);

            const title =
                index > -1 ?
                    (
                        <div>
                            <div style={{width:'70%',float:'left'}}>
                                <span>{beforeStr}<span className="site-tree-search-value">{this.state.searchValue}</span>{afterStr}</span>
                            </div>
                            <div style={{width:'25%',float:'right',textAlign: 'right', display: display}}>
                                <Popconfirm title={'是否删除'} onConfirm={()=>{this.deleteModel(item.key);}}
                                            okText="是" cancelText="否">
                                    <a>{'删除'}</a>
                                </Popconfirm>
                            </div>
                        </div>
                    )
                    :
                    (<span>{item.title}</span>);

            if(index>=-1){
                if (item.children) {
                    return { title, key: item.key, children: this.loop(item.children,null) };
                }
            }
            return {title, key: item.key,};
        });
    filtrateNode = (data) =>
        data.map(item => {
            const index = item.title.indexOf(this.state.searchValue);   // 获取相同值的位置
            const beforeStr = item.title.substr(0, index);                              // 截取前半部分没有相同的值
            const afterStr = item.title.substr(index + this.state.searchValue.length);  // 获取后半部分不相同的值
            const title =
                index > -1 ? (
                        <span>{beforeStr}<span className="site-tree-search-value">{this.state.searchValue}</span>{afterStr}</span>)
                    :
                    (<span >{item.title}</span>);
            if (item.children) {
                return { title, key: item.key, children: this.filtrateNode(item.children) };
            }
            return {
                title,
                key: item.key,
            };
        });

    // 删除型号
    deleteModel=(modelId)=>{
        if(!modelId){
            return message.info('未获取型号id')
        }
        deleteModel({modelId}).then(
            response=>{
                if(response?.data?.data){
                    message.info('删除成功')
                }else{
                    message.info('删除失败')
                }
                this.getTreeDataInfo();  // 获得树形结构的数据
            }
        )
    };
    //-----------------------------------------------中间功能------------------------------------------------------------
    // 折叠面板框--待用功能
    callback=(key) =>{
        console.log(key);
    };
    // 将折叠面板框选择框内的数据传递给右侧弹出框
    genExtraVisibles=(data)=>{
        console.log("传递层级数据到右框内");
        console.log("层级参数"+data);
        // 本型号中各设备值不同的属性，本型号中各设备需要采集的属性，本型号中各设备值相同的属性
        // 数据项多选框，本型号基本数据,本型号中设备配置项
        let {differentAttributes,newGatherAttributes,sameAttributes,plainOptions,essentialData,configData} = this.state;

        plainOptions=[];// 置空多选框内容
        switch (data) {
            case 1:
                //2022-7-21 新增需求，对于已有型号数据项控制，只增不减
                // if(essentialData?.classId!==undefined){
                //     this.disabledCtl(true,true,true,true);//右框-多选框可选控制-禁止部分不可选
                // }else {
                //     this.disabledCtl(false,true,true,true);//右框-多选框可选控制-禁止部分不可选
                // }
                this.disabledCtl(false,true,true,true);//右框-多选框可选控制-不控制
                // 控制该层级已勾选的数据
                differentAttributes?.forEach(item=>{
                    plainOptions.push(item.id.toString())
                });
                break;
            case 2:
                //2022-7-21 新增需求，对于已有型号数据项控制，只增不减
                // if(essentialData?.classId!==undefined){
                //     console.log("111111111111111");
                //     this.disabledCtl(true,true,true,true);//右框-多选框可选控制-禁止部分不可选
                // }else {
                //     console.log("222222222222222222");
                //     this.disabledCtl(true,false,true,true);//右框-多选框可选控制-禁止部分不可选
                // }
                this.disabledCtl(true,false,true,true);//右框-多选框可选控制-不控制
                // 控制该层级已勾选的数据
                newGatherAttributes?.forEach(item=>{
                    console.log(item);
                    plainOptions.push(item.id.toString())
                });
                break;
            case 3:
                //2022-7-21 新增需求，对于已有型号数据项控制，只增不减
                // if(essentialData?.classId!==undefined){
                //     console.log("1122222222222222")
                //     this.disabledCtl(true,true,true,true);//右框-多选框可选控制-禁止部分不可选
                // }else {
                //     console.log("221111111111111")
                //     this.disabledCtl(true,true,false,true);//右框-多选框可选控制-禁止部分不可选
                // }
                this.disabledCtl(true,true,false,true);//右框-多选框可选控制-不控制
                // 控制该层级已勾选的数据
                sameAttributes?.forEach(item=>{
                    plainOptions.push(item.id.toString())
                });
                break;
            case 4:
                //2022-7-21 新增需求，对于已有型号数据项控制，只增不减
                // if(configData?.classId!==undefined){
                //     this.disabledCtl(true,true,true,true);//右框-多选框可选控制-禁止部分不可选
                // }else {
                //     this.disabledCtl(true,true,true,false);//右框-多选框可选控制-禁止部分不可选
                // }
                this.disabledCtl(true,true,true,false);//右框-多选框可选控制-不控制
                // 控制该层级已勾选的数据
                configData?.forEach(item=>{
                    plainOptions.push(item.id.toString())
                });
                break;
            case 5:
                break;
            default:
                break;
        }
        this.setState({plainOptions:plainOptions});
        console.log(newGatherAttributes);
    };

    //--------------------------------------------右框功能--------------------------------------------------------------
    // 获取全部数据项-用于右侧多选框数据
    queryAttrVo=()=>{
        console.log("查询获取全部数据项");
        let optionsWithDisabled = this.state.optionsWithDisabled;
        // 访问后台获取数据项
        getDevModelAttr().then(item=>{
            if(item?.data?.data){
                // console.log(item);
                // 遍历数据项添加状态
                item.data.data.forEach(item=>{
                    item.disabled=false;
                    optionsWithDisabled.push(item);
                });
                console.log(optionsWithDisabled);
                optionsWithDisabled=item.data.data;
                this.setState({optionsWithDisabled});
            }
        });
        // console.log(optionsWithDisabled);
    };
    // 右框关闭就触发事件 输出数据到中间的折叠框内
    exportData=()=>{
        // 多选框，本型号中各设备值不同的属性，本型号中各设备需要采集的属性，本型号中各设备需要采集的属性
        // 本型号中各设备值相同的属性,configData
        let {plainOptions,differentAttributes,gatherAttributes,newGatherAttributes,sameAttributes,configData} = this.state;
        const dataList=[];  // 新建一个本地对象获取所有多选框选择的值
        this.state.optionsWithDisabled.forEach(item=> { //获取数据线中选取得数据线得完整数据
                if (plainOptions.some(value => value === item.id.toString())) {
                    dataList.push(item)
                }
            }
        );
        console.log(this.state.drawerSwitchData);
        switch (this.state.drawerSwitchData) {
            case 1:
                console.log("1");
                differentAttributes=[];
                dataList.forEach(item=>{
                    item.id=item.id.toString();
                    item.common=2;
                });
                differentAttributes=dataList;
                break;
            case 2:
                console.log("2");
                gatherAttributes=[];
                newGatherAttributes=[];
                dataList.forEach(item=>{
                    gatherAttributes.push({id:item.id});
                });
                newGatherAttributes=dataList;
                break;
            case 3:
                console.log(dataList);
                const temporary=[];     // 临时的变量集-用于存储数据
                sameAttributes.forEach(item=>{
                   if(dataList.some(same=> same.id.toString()===item.id)){
                       // console.log("111相同"+item.id);
                       temporary.push(item)
                   }
                });
                // console.log(dataList);
                // 针对第三个有输入框的需要配置内容
                dataList.forEach(item=>{
                    if(temporary.some(same=> same.id===item.id.toString())){
                        // console.log("22相同"+item.id);

                    }else{
                        // console.log("22不相同"+item.id);
                        item.value="未知";
                        item.common=1;
                        temporary.push(item)
                    }
                });
                console.log(temporary);
                sameAttributes=[];
                sameAttributes=temporary;
                break;
            case 4:
                console.log("4");
                configData=[];
                // 转换输出值的属性
                dataList.forEach(item=>{
                    item.id=item.id.toString();
                    item.common=2;
                });
                // eslint-disable-next-line no-unused-vars
                configData=dataList;
                break;
            case 5:
                console.log("5");
                break;
            default:
                break;
        }
        // console.log(sameAttributes);
        this.setState({
            differentAttributes:differentAttributes,
            gatherAttributes:gatherAttributes,
            newGatherAttributes:newGatherAttributes,
            sameAttributes:sameAttributes,
            configData:configData
        });
        // this.forms.onModalClose();
    };
    // 右框-多选框可选控制-禁止部分不可选
    // 参数判断是否控制三大部分的参数-22-11-9新增控制第四个
    disabledCtl=(data1,data2,data3,data4)=>{
        console.log("2-右框-多选框可选控制-禁止部分不可选");
        // 本型号中各设备值不同的属性,本型号中各设备需要采集的属性,本型号中各设备值相同的属性,本型号中设备配置项
        // 获得数据库的数据项
        let {differentAttributes,gatherAttributes,sameAttributes,configData,optionsWithDisabled} = this.state;
        const number=[];

        if(data1){
            differentAttributes.forEach(item=>{
                number.push(item.id.toString())
            });
        }

        if(data2){
            gatherAttributes.forEach(item=>{
                number.push(item.id.toString())
            });
        }

        if(data3){
            console.log("本型号中各设备值相同的属性+11"+sameAttributes);
            sameAttributes.forEach(item=>{
                number.push(item.id.toString())
            });
        }
        if(data4){
            console.log("本型号中设备配置项"+configData);
            configData.forEach(item=>{
                console.log("本型号中设备配置项-单个"+item.id);
                // number.push(item.id.toString())
            });
        }
        // 用于验证获得要约束的数据
        // console.log(number);
        optionsWithDisabled.forEach(item=>{
            if(number.some(data => data===item.id.toString())){
                item.disabled=true;
            }else{
                item.disabled=false;
            }
        });
    };
    // 右框多选框选择内容保存
    onChange=(checkedValues)=> {
        console.log('右框多选框选择内容保存 ');
        console.log(checkedValues);
        let {plainOptions,newOptionsWithDisabled} = this.state;
        this.setState({plainOptions: checkedValues})


    };
    // 右框检索多选框的数据的名称-检索输入框的功能
    inputFindCheckbox=(e)=>{
        console.log('输入-筛选查询多选框',e.target);
        const { value } = e.target;
        console.log(value);
        this.setState({newOptionsWithDisabled:value});
    };


    // 添加或修改按钮
    clearAllData = async ()=>{

        var formParamenter = this.state.formParamenter;
        formParamenter.parameter[6].disabled=false;
        formParamenter.parameter[0].disabled=false;
        console.log('formParamenter');
        console.log(formParamenter);
        this.setState({
            formParamenter:formParamenter,
            selectModel:{
                devModelId:null,    // 型号id
                devModelName:null   // 型号名
            },
            VoDevModelQueryAttr:{
                devModelId:null,
                common:null,        //属性类型，为空则默认查询所有类型,1/共有，2/私有
            },
            essentialData: [],
            differentAttributes:[], // 本型号中各设备值不同的属性
            gatherAttributes:[],    // 本型号中各设备需要采集的属性
            newGatherAttributes:[], // 本型号中各设备需要采集的属性-转变合适的数据
            sameAttributes:[],      // 本型号中各设备值相同的属性
            plainOptions:[],        // 数据项多选框
            // 复选框的数据集
            // optionsWithDisabled : [],  // 获得数据库的数据
            newOptionsWithDisabled:'', // 文本输入要求获得数据
        });

        this.forms.CloseFieldsValues(formParamenter.parameter);
    };
    copyData = async ()=>{
        let {selectModel, essentialData} = this.state;
        let VoDevModel = await this.forms.onFinishNotReset();
        this.state.formParamenter.parameter[0].disabled=false;
        if(VoDevModel){
            essentialData.devModelName = VoDevModel.devModelName+'副本'
            this.setState({essentialData})
            await this.forms.onModalClose()
        }
        // console.log(VoDevModel.devModelName)
        if(selectModel.devModelId){
            selectModel.devModelId[0]=null;
        }
        message.info("复制成功！");
    }
    switchover= async (data)=>{
        console.log("添加或修改按钮");
        if(data===null){return null}        // 判断参数不能为空
        let VoDevModel = await this.forms.onFinishNotReset();   // 获取输入表单数据
        if(VoDevModel===null){return null}  // 判断表单数据是否为空

        let {differentAttributes,gatherAttributes,newGatherAttributes,sameAttributes,selectModel,configData} = this.state;
        console.log(differentAttributes);
        console.log(differentAttributes);
        if(differentAttributes.length===0){return message.info('本型号中各设备值不同的属性不能为空')}
        if(gatherAttributes.length===0){return message.info('本型号中各设备需要采集的属性不能为空')}
        VoDevModel.devModelId=selectModel.devModelId?.[0];
        let attr=[];
        let config=[];
        // 配置数据
        differentAttributes.forEach(item=> attr.push(item));
        sameAttributes.forEach(item=> attr.push(item));
        VoDevModel.attr=attr;
        VoDevModel.data=gatherAttributes;
        VoDevModel.config=configData;
        console.log(gatherAttributes);
        // 新增型号
        this.addNewModelInfo(VoDevModel);
    };
    // 保存修改型号-没用了
    updataModelInfo=(VoDevModel)=>{
        updateDevModel(VoDevModel).then(tiem=>{
            console.log(tiem);
            if(tiem.data.data){
                message.info("保存成功");
                this.getTreeDataInfo();  // 获得树形结构的数据
            }else{
                message.info(tiem.data.responseInfo.description);
                this.getTreeDataInfo();  // 获得树形结构的数据
            }
        })
    };
    // 新增型号
    addNewModelInfo=(VoDevModel)=>{
        addDevModel(VoDevModel).then(tiem=>{
            console.log(tiem);
            if(tiem.data.data){
                message.info("操作成功");
                this.getTreeDataInfo();  // 获得树形结构的数据
            }else{
                message.info(tiem.data.responseInfo.description);
                this.getTreeDataInfo();  // 获得树形结构的数据
            }
        });
    };
    onExpands = (keys, info)  => {
        console.log("展开");
        console.log(keys, info);
        console.log("展开");
    };
    render() {
        const  autoExpandParent =false;
        return (
            <Layout className={'item_body_number_back tables'} style={{backgroundColor: '#ffffff'}}>

                <div>
                    {/*左框内容*/}
                    <div className={"left_tree_div"}>
                        <div className={"left_tree_div_input"}>
                            <Input.Group compact>
                                <Input style={{ width: 200, }} placeholder="输入查询数据" onChange={this.inputFindNode}/>
                                {/* <Button  icon={<SearchOutlined />}  /> */}
                            </Input.Group>
                        </div>
                        {/* <div className={"left_tree_div_input"}>
                            <Button onClick={() => this.derailingSwitch1(true)} type="primary">新增类型</Button>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <Button onClick={() => this.derailingSwitch2(true)} type="primary" danger>删除类型/型号</Button>
                        </div> */}
                        <div >
                            <div className={"left_tree_div_tree_div"}>
                                <DirectoryTree
                                    onExpand={this.onExpands}
                                    // autoExpandParent={true}
                                    className={"left_tree_div_tree_data_div"}
                                    onSelect={this.onExpand}
                                    icon={false}
                                    switcherIcon={<DownOutlined/>}
                                    treeData={this.loop(this.selectedTree(),'none')}
                                    // treeData={this.state.treeNodeInfo}
                                    // treeData={this.filtrateNode(this.state.treeNodeInfo)}
                                />
                            </div>

                        </div>
                    </div>
{/*--设备基本信息---------------------------------------------------------------------------------*/}
                    <div  className={"right_head_div"}>
                        <div style={{padding:'10px 10px 20px 10px'}}>
                                <div style={{float:"right"}}>
                                    {/* <Button type="primary" style={{fontSize:15,margin:'0 0 0 10px'}} onClick={() => this.derailingSwitch1(true)}>新增类型</Button> */}
                                    <Button type="primary" style={{fontSize:15,margin:'0 0 0 10px'}} onClick={()=>{this.clearAllData()}}>
                                        创建新型号
                                    </Button>
                                    <Button type="primary" style={{fontSize:15,margin:'0 0 0 10px'}} onClick={()=>{this.copyData()}}>
                                        复制
                                    </Button>
                                    <Button type="primary"  value="b" style={{fontSize:15,margin:'0 0 0 10px'}} onClick={()=>{this.switchover("b")}}>
                                        保存
                                    </Button>
                                </div>


                        </div>
                        {/*设备基本信息填写*/}
                        <div className={"right_head_input_div"} style={{clear:'both'}}>
                            <FormsTwo
                                ref={e => this.forms = e}
                                formParamenter={this.state.formParamenter}
                                data={this.state.essentialData}
                            />

                        </div>
{/*--详细属性配置----------------------------------*/}
                        <div className={"right_head_collapse_div"}>
                            <div>
                                <Collapse
                                    defaultActiveKey={['1','2','3','4']}
                                    onChange={this.callback}>
                                    {/*本型号中各设备值不同的属性*/}
                                    <Panel header="本型号中各设备值不同的属性" key="1" extra={this.genExtra(1)}>
                                        <div>
                                            <Descriptions
                                                column={{ sm: 4}}
                                            >
                                                {
                                                    this.state.differentAttributes?.map(
                                                    item => (
                                                    <div style={{width: 200,height:50}}>
                                                    <Descriptions.Item>
                                                    <Title style={{paddingLeft: 100}} level={5}>{item.name}
                                                    </Title>
                                                    </Descriptions.Item  >
                                                    </div>
                                                    )
                                                    )
                                                }
                                            </Descriptions>
                                        </div>
                                    </Panel>
                                    <Panel header="本型号中各设备需要采集的属性" key="2" extra={this.genExtra(2)}>
                                        <div>
                                            <Descriptions
                                                column={{ sm: 4}}>
                                                {this.state.newGatherAttributes?.map(
                                                    item => (
                                                        <div style={{width: 200,height:50}}>
                                                            <Descriptions.Item>
                                                                <Title style={{paddingLeft: 100}} level={5}>{item.name}
                                                                </Title>
                                                            </Descriptions.Item  >
                                                        </div>
                                                    )
                                                )
                                                }
                                            </Descriptions>
                                        </div>
                                    </Panel>
                                    <Panel header="本型号中各设备值相同的属性" key="3" extra={this.genExtra(3)}>
                                        <div>
                                            <Descriptions
                                                column={{ sm: 4}}>
                                                {
                                                    this.state.sameAttributes?.map(
                                                    item => (
                                                        <Descriptions.Item>
                                                            <div className={"right_head_collapse_Descriptions_3_div"}>
                                                                <Title level={5}>{item.name}： <br/>
                                                                <Input  className={"right_head_collapse_Descriptions_3_input_div"}
                                                                        key={item.id}
                                                                        defaultValue={item.value}
                                                                        style={{ width: 150 }}
                                                                        onChange={(dd)=>{
                                                                            (
                                                                                item.value=dd.target.value)}}
                                                                />
                                                                </Title>
                                                            </div>
                                                        </Descriptions.Item  >
                                                    )
                                                )}
                                            </Descriptions>
                                        </div>
                                    </Panel>
                                    <Panel header="本型号中设备配置项" key="4" extra={this.genExtra(4)}>
                                        <div>
                                            <Descriptions
                                                column={{ sm: 4}}
                                            >
                                                {
                                                    this.state.configData?.map(
                                                        item => (
                                                            <div style={{width: 200,height:50}}>
                                                                <Descriptions.Item>
                                                                    <Title style={{paddingLeft: 100}} level={5}>{item.name}
                                                                    </Title>
                                                                </Descriptions.Item  >
                                                            </div>
                                                        )
                                                    )
                                                }
                                            </Descriptions>
                                        </div>
                                    </Panel>
                                    <Panel header="本型号中设备操作项" key="5" >
                                        <div>{this.state.text}</div>
                                    </Panel>
                                </Collapse>
                            </div>
                        </div>
                    </div>
{/*---------------------------------------右侧弹出框----------------------------------------------*/}
                    <div>
                        <Drawer  width={500}
                                 title={
                                     <Input.Group compact style={{float:"lift"}}>
                                         <Title level={5} >数据项名：</Title>
                                         <Input style={{ width: 220,}} placeholder="输入查询数据"  onChange={this.inputFindCheckbox}/>
                                         <Button  icon={<SearchOutlined />}  />
                                         {/*<Button style={{float:"right"}} type="primary">新增数据项</Button>*/}
                                     </Input.Group>
                                 }
                                 placement="right"
                                 onClose={this.onClose}         // 弹出框关闭触发事件
                                 visible={this.state.visibles}  // 控制弹出框开关
                        >

                            <Checkbox.Group
                                key={this.state.plainOptions}
                                defaultValue={this.state.plainOptions} // 多选框的值
                                style={{ width: '100%' }}
                                onChange={this.onChange}    //多选框点击事件
                            >
                                {/*{this.checkBoxInfo()}*/}
                                {
                                    // 根据右框各个数据展示出来
                                    this.state.optionsWithDisabled.map(item => (
                                        <div className={"drawer_Checkbox_div"}
                                             // 根据文本框输入内容控制输出样式，把部分数据隐藏，indexOf判断字段有无相同字
                                             style={item?.name?.indexOf(this.state.newOptionsWithDisabled) >-1 ? {}:{display: "none"}}>
                                            {/*悬浮提示每个属性框的迷你窗口-START*/}
                                            <Tooltip title={item.name}>
                                                <Checkbox
                                                    disabled={item.disabled}    // 状态控制
                                                    key={item.id}               // id
                                                    value={item.id.toString()}>
                                                    <span>{item.name}</span>
                                                </Checkbox>
                                            </Tooltip>
                                            {/*悬浮提示每个属性框的迷你窗口-END*/}
                                        </div>
                                    ))
                                }
                            </Checkbox.Group>
                        </Drawer>
                    </div>
                </div>
                {/*----------------------------------------------------弹出窗口---------------------------------------*/}
                <div>
                    <Modal
                        title="新增设备类型"
                        style={{ top: 0 }}
                        visible={this.state.moda1Visible}
                        okText="确定"
                        onOk={() => this.addModel(true)}           // 点击确定
                        onCancel={() => this.derailingSwitch1(false)}       // 点击取消
                        width={300}
                        centered
                    >
                        <div>
                            <span>类型名称：</span>
                            <Input style={{ width: 180 }} placeholder="型号" onChange={this.addModelInput}/>
                        </div>
                    </Modal>
                    {/*提示窗口-左框选择刪除內容不匹配*/}
                    <Modal
                        title="提示"
                        style={{ top: 0 }}
                        visible={this.state.moda2Visible}
                        okText="确定"
                        onOk={() => this.derailingSwitch2(false)}           // 点击确定
                        onCancel={() => this.derailingSwitch2(false)}       // 点击取消
                        width={300}
                        centered
                    >
                        {/*{this.reminderHint()}    /!*提示接口*!/*/}
                        <Title style={{paddingLeft: 35}} level={3}>不能用</Title>
                    </Modal>
                </div>
            </Layout>
        )
    }
}
