import React, { Component } from 'react'
import {Button, Image, Layout, message, Modal, Tag, Upload} from "antd";
import './createWorkOrder.less'
import FormsTwo from "../../components/common/forms/FormsTwo";

import { SearchOutlined, RollbackOutlined,EyeOutlined,PlusOutlined,UploadOutlined,UserAddOutlined } from '@ant-design/icons';
import {TYPE_NAME} from "../../components/Const";
import Table from "antd/es/table";
import {
    createOrUpdateComplaintTypes, queryPageComplaintTypes, queryPageHandler, saveHandler, saveOrUpdateWorkOrder,
} from "../../services/meter";
import TableComponents from "../../components/common/tables/TableComponentsNew";
import {getDataIgnoreLevel} from "../../services/steamReport";
export default class createWorkOrder extends Component {
    state={
        // 表单参数 -基本信息
        formParam: {
            modal: true,
            number: 2,
            labelCol: 8,
            layout: "vertical",
            parameter: [
                {variableName: 'complainantName', str: '投诉人姓名', rules: TYPE_NAME.NOT_BLANK},
                {variableName: 'contactPhone', str: '联系电话', rules: TYPE_NAME.PHONE_NUM_NOT_NULL,},
                {variableName: 'areaId', str: '所在区域', rules: TYPE_NAME.REQ_SELECT, data:[]},
                {variableName: 'address', str: '详细地址', rules: TYPE_NAME.NOT_BLANK,},
            ]
        },
        // 表单参数 -投诉信息
        formParam_2: {
            modal: true,
            number: 2,
            labelCol: 8,
            layout: "vertical",
            parameter: [
                {variableName: 'complaintType', str: '投诉类型', rules: TYPE_NAME.REQ_SELECT, data:[],
                    onChange:(a,b)=>{this.findFormDataByKey_1(a);}
                },
                {variableName: 'emergencyLevel', str: '紧急程度', rules: TYPE_NAME.REQ_SELECT,disabled:true,
                    data:[
                        {key:'high',value:'高'},
                        {key:'medium',value:'中'},
                        {key:'low',value:'低'},
                    ]
                },
                {variableName: 'complaintContent', str: '投诉内容',disabled:true, rules: TYPE_NAME.TEXT_AREA, autoSize:{ minRows: 2, maxRows: 5 }},
            ]
        },
        // 表单参数 -处理信息
        formParam_3: {
            modal: true,
            number: 2,
            labelCol: 8,
            layout: "vertical",
            parameter: [
                {variableName: 'assigneeId', str: '指派处理人', rules: TYPE_NAME.REQ_SELECT, data:[{key:'暂不指派',value:'暂不指派'},],
                    onChange:(a,b)=>{this.findFormDataByKey_2(a);}
                },
                {variableName: 'expectedProcessTime', str: '预计处理时间', rules: TYPE_NAME.DATE_TIME_FORMAT,disabled:true},
                {variableName: 'processNote', str: '处理说明', rules: TYPE_NAME.TEXT_AREA,autoSize:{ minRows: 2, maxRows: 5 },disabled:true},
            ]
        },
        // 弹窗-新建投诉类型- 表单参数
        formParam_4: {
            modal: true,
            number: 1,
            labelCol: 7,
            layout: "horizontal",
            parameter: [
                {variableName: 'typeName', str: '类型名称', rules: TYPE_NAME.NOT_BLANK},

                {variableName: 'status', str: '状态', rules: TYPE_NAME.REQ_SELECT,
                    data:[{key:'enabled',value:'启用'}, {key:'disabled',value:'停用'}]
                },

                {variableName: 'defaultEmergencyLevel', str: '紧急程度', rules: TYPE_NAME.REQ_SELECT,
                    data:[
                        {key:'high',value:'高'},
                        {key:'medium',value:'中'},
                        {key:'low',value:'低'},
                    ]
                },
                {variableName: 'processingTimeLimit', str: '处理时限（小时）', rules: TYPE_NAME.NO_INPUT_NUMBER},


                {variableName: 'description', str: '类型描述', rules: TYPE_NAME.TEXT_AREA,autoSize:{ minRows: 3, maxRows: 5 }},
            ]
        },
        // 弹窗-新建联系人- 表单参数
        formParam_5: {
            modal: true,
            number: 1,
            labelCol: 6,
            layout: "horizontal",
            parameter: [
                {variableName: 'name', str: '处理人姓名', rules: TYPE_NAME.NOT_BLANK},
                {variableName: 'phone', str: '处理人电话', rules: TYPE_NAME.PHONE_NUM_NOT_NULL},
                {variableName: 'mail', str: '邮箱地址', rules: TYPE_NAME.EMAIL_NOT_NULL,},
            ]
        },



        // 弹窗-新建投诉类型- 表单参数-提示
        promptContent_1:'1',
        // 弹窗-新建投诉类型-加载
        loading_1:false,
        // 弹窗-新建投诉类型-开关
        windowSwitch_1:false,

        // 弹窗-新建处置人- 表单参数-提示
        promptContent_2:'1',
        // 弹窗-新建处置人-加载
        loading_2:false,
        // 弹窗-新建处置人-开关
        windowSwitch_2:false,

        // 上传参数信息
        fileUrl:'http://47.97.117.248:8352/ossManagement/ossUpload',
        fileList: [], // 设备照片
        newFileList:[],


    };

    // 初始化
    componentDidMount(): void {
        this.getDataIgnoreLevel();      // 获取所有区域
        this.queryPageComplaintTypes(); // 分页查询所有投诉类型
        this.queryPageHandler();        // 查询所有的处理人
    }
    // 查询所有的处理人
    queryPageHandler=()=>{
        var param = {page:1, size:999};
        queryPageHandler(param).then(
            response=>{
                console.log(response);
                if(response?.data?.data){
                    var formParam3 = this.state.formParam_3;
                    formParam3.parameter[0].data=[{key:'暂不指派',value:'暂不指派'}];
                    response?.data?.data.map(one=>{
                        formParam3.parameter[0].data.push({key:one?.id??null, value:one.name??null,label:one.name??null})
                    });
                    this.setState({formParam_3:formParam3,})
                }
            }
        )
    };
    // 分页查询所有投诉类型
    queryPageComplaintTypes= () =>{
        var param = {
            page:1,
            size:999
        };
        queryPageComplaintTypes(param).then(
            response => {
                if(response?.data?.data){
                    const {data} = response.data;
                    var formParam2 = this.state.formParam_2;
                    let selectArry=[];
                    if(data){
                        this.setState({areaInfos:data});
                        for(var i=0;i<data.length;i++){
                            selectArry.push(Object.assign(data[i],{key:data[i].id,value:data[i].typeName}))
                        }
                    }
                    formParam2.parameter[0].data=selectArry;
                    this.setState({formParam_2:formParam2})
                }

            }
        )
    };
    // 获取所有区域
    getDataIgnoreLevel=()=>{
        let user = JSON.parse(sessionStorage.getItem("user"));
        getDataIgnoreLevel({userId:user.id}).then(
            response => {
                const {data} = response.data;
                let selectArry=[];
                if(data){
                    this.setState({areaInfos:data});
                    for(var i=0;i<data.length;i++){
                        console.log(data[i]);
                        selectArry.push({key:data[i].id,value:data[i].name})
                    }
                }
                this.state.formParam.parameter[2].data=selectArry;
            }
        );
    };

    // 新建-修改工单 response
    saveOrUpdateWorkOrder = async () => {
        var param1 = await this.forms1.onFinishNotReset();
        var param2 = await this.forms2.onFinishNotReset();
        var param3 = await this.forms3.onFinishNotReset();
        var fileList = this.state.fileList;

        var picGroupsIds =[];
        fileList.map(one=>{if(one?.response){picGroupsIds.push(one?.response)}});

        var param = Object.assign(param1,param2,param3,{picGroupsId:picGroupsIds});

        console.log(param);
        saveOrUpdateWorkOrder(param).then(
            response => {
                console.log(param);
            }
        )
    };

    // 新建-修改投诉类型
    createOrUpdateComplaintTypes =async () => {
        var param4 = await this.forms4.onFinishNotReset();
        createOrUpdateComplaintTypes(param4).then(
            response => {
                if(response?.data?.data){
                    this.setState({
                        loading_1:false,
                        windowSwitch_1:false
                    })
                }else{
                    this.setState({
                        loading_1:false,
                        promptContent:'提交失败，请重新尝试'
                    })
                }
            }
        )
    };

    // formParam_2 - 根据key查找匹配数据，并赋值
    findFormDataByKey_1=(key)=>{
        var param2 = this.state.formParam_2;
        param2.parameter[0].data.map(one=>{
            if(key===one.key){
                this.forms2.setFieldsValue('emergencyLevel',one?.defaultEmergencyLevel??null);
                this.forms2.setFieldsValue('complaintContent',one?.description??null);
            }
        });
    };
    // formParam_3 - 根据key查找匹配数据，并赋值 processNote
    findFormDataByKey_2=(key)=>{
        var param3 = this.state.formParam_3;
        if(key.toString().indexOf('暂不指派')!== -1){
            param3.parameter[1].disabled=true;
            param3.parameter[2].disabled=true;
            this.forms3.setFieldsValue('expectedProcessTime',null);
            this.forms3.setFieldsValue('processNote',null);
        }else{
            param3.parameter[0].data.map(one=>{
                if(key===one.key){
                    param3.parameter[1].disabled=false;
                    param3.parameter[2].disabled=false;
                    this.forms2.setFieldsValue('expectedProcessTime',null);
                    this.forms2.setFieldsValue('expectedProcessTime',null);
                }
            });
        }
        this.setState({formParam_3:param3})
    };

    saveHandler =async ()=>{
        let voHandler = await this.forms5.onFinishNotReset();
        saveHandler(voHandler).then(
            response=>{
                if(response?.data?.data){
                    console.log('1');
                    this.setState({windowSwitch_2:false,promptContent_2:'', loading_2:false})
                }else{
                    console.log('2');
                    this.setState({
                        promptContent_2:response?.data?.responseInfo?.description ??'',
                        loading_2:false
                    })
                }
            }
        )
    };
    // 照片点击事件
    onChangeUpload =  file => {
        if (file.file.status !== 'uploading') {
        }
        if (file.file.status === 'done') {
            message.success(`${file.file.name} 上传成功`);
        } else if (file.file.status === 'error') {
            message.error(`${file.file.name} 上传异常.`);
        }
        console.log(file.fileList);
        this.setState({fileList:file.fileList});
    };
    render(){
        return(
            <div id={'createWorkOrder'}>



{/*标题----------*/}
                <div className={'createWorkOrder_topSearchBox'}>
                    <div className={'createWorkOrder_topSearchBoxH3'}>
                        <h3>新建工单</h3>
                    </div>
                    <div style={{float:'left'}}>
                        <Button onClick={()=>{
                            this.props.history.go(-1)
                        }}>取消</Button>
                        &nbsp;&nbsp;
                        <Button type="primary"
                                onClick={()=>{
                                    this.saveOrUpdateWorkOrder();
                                }}
                        >保存</Button>
                    </div>
                </div>
{/*内容---------*/}
                <div className={'createWorkOrder_css_div_head_1'}>
                    <div className={'createWorkOrder_css_div_head_1_div_frame'}>
{/*基本信息--------------*/}
                        <div style={{width:'100%',height:'230px'}}>
                            <div style={{width:'100%',height:'40px'}}>
                                <span style={{fontSize:'18px',fontWeight: 550}}>基本信息</span>
                            </div>
                            <FormsTwo
                                ref={e => this.forms1 = e}
                                formParamenter={this.state.formParam}
                                data={this.state.essentialData}
                            />
                        </div>
{/*投诉信息--------------*/}
                        <div style={{width:'100%',height:'240px'}}>
                            <div style={{width:'100%',height:'40px'}}>
                                <span style={{fontSize:'18px',fontWeight: 550}}>投诉信息</span>
                            </div>
                            {/* 新建类型 */}
                            <div style={{
                                width:'100px',height:'30px',position: 'absolute',
                                left:'44.5%',zIndex: 999
                            }}>
                                {/*新建类型*/}
                                <a type="primary"
                                   onClick={ async  () =>{
                                       await this.setState({windowSwitch_1:true});
                                       this.forms4.onModalClose();
                                   }}>
                                    <PlusOutlined />&nbsp;新建类型
                                </a>
                            </div>
                            <FormsTwo
                                ref={e => this.forms2 = e}
                                formParamenter={this.state.formParam_2}
                                data={this.state.essentialData}
                            />
                        </div>
{/*处理信息---------------*/}
                        <div style={{width:'100%',height:'230px'}}>
                            <div style={{width:'100%',height:'40px'}}>
                                <span style={{fontSize:'18px',fontWeight: 550}}>处理信息</span>
                            </div>
                            {/* 新建类型 */}
                            <div style={{
                                width:'100px',height:'30px',position: 'absolute',
                                left:'44%',zIndex: 999
                            }}>
                                {/*新建类型*/}
                                <a type="primary"
                                   onClick={ async () =>{
                                       await this.setState({windowSwitch_2:true});
                                       this.forms5.onModalClose();
                                   }}>
                                    <PlusOutlined />&nbsp;新建处理人
                                </a>
                            </div>
                            <FormsTwo
                                ref={e => this.forms3 = e}
                                formParamenter={this.state.formParam_3}
                                data={this.state.essentialData}
                            />
                        </div>
{/*文件上传--------------*/}
                        <div style={{width:'100%',height:'230px'}}>
                            <Upload
                                action={this.state.fileUrl}
                                // listType="picture-card"
                                onChange={this.onChangeUpload}
                            >
                                <button
                                    style={{ color: 'inherit', cursor: 'inherit', border: 0, background: 'none' }}
                                    type="button"
                                >
                                    <Button icon={<UploadOutlined />}>上传附件</Button>
                                </button>
                            </Upload>
                        </div>
                    </div>
                </div>

{/*弹窗-新建投诉类型--------*/}
                <Modal
                    title='新建投诉类型'
                    style={{ top: 0 }}
                    visible={this.state.windowSwitch_1}
                    okText="确定"
                    onOk={() => this.setState({windowSwitch_1:false})}           // 点击确定
                    onCancel={() => this.setState({windowSwitch_1:false})}       // 点击取消
                    width={480}
                    centered
                    footer={[
                        <Button key="back" onClick={()=>{this.setState({windowSwitch_1:false});}}
                        >返回</Button>,
                        <Button key="submit" type="primary"
                                loading={this.state.loading_1}
                                onClick={()=>{
                                    this.setState({loading_1:true,promptContent_1:''});
                                    this.createOrUpdateComplaintTypes(); // 新建-修改投诉类型
                                }}
                        >提交</Button>
                    ]}
                >
                    <FormsTwo
                        ref={e => this.forms4 = e}
                        formParamenter={this.state.formParam_4}
                        data={this.state.essentialData}
                    />
                    <div style={{ textAlign: 'center'}}>
                        <span style={{color:'red'}}>{this.state.promptContent_1}</span>
                    </div>
                </Modal>
{/*新建处理人-------*/}
                <Modal
                    title='新建处理人'
                    style={{ top: 0 }}
                    visible={this.state.windowSwitch_2}
                    okText="确定"
                    onOk={() => this.setState({windowSwitch_2:false})}           // 点击确定
                    onCancel={() => this.setState({windowSwitch_2:false})}       // 点击取消
                    width={480}
                    centered
                    footer={[
                        <Button key="back" onClick={()=>{this.setState({windowSwitch_2:false});}}
                        >返回</Button>,
                        <Button key="submit" type="primary"
                                loading={this.state.loading_2}
                                onClick={()=>{
                                    this.setState({loading_2:true,promptContent_2:''});
                                    this.saveHandler();
                                }}
                        >提交</Button>
                    ]}
                >
                    <FormsTwo
                        ref={e => this.forms5 = e}
                        formParamenter={this.state.formParam_5}
                        data={this.state.essentialData}
                    />
                    <div style={{ textAlign: 'center'}}>
                        <span style={{color:'red'}}>{this.state.promptContent_2}</span>
                    </div>
                </Modal>
            </div>
        )
    }
}
