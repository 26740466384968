// import React, { useContext, useState, useEffect, useRef } from "react";
// import { Table, Input, Button, Popconfirm, Form } from "antd";
//
// const EditableContext = React.createContext(null);
// const EditableRow=(props)=> {//编辑表格行
//     let [form] = Form.useForm();//定义表单对象
//     return (
//         <Form form={form} component={false}>
//             <EditableContext.Provider value={form}>
//                 <tr {...props} />
//             </EditableContext.Provider>
//         </Form>
//     );
// }
// const  EditableCell=({ title, editable, children, dataIndex, record, handleSave, ...restProps })=>{//编辑表格单元格
//     let inputRef = useRef(null);
//     const [editing, setEditing] = useState(false); //定义编辑状态
//     const form = useContext(EditableContext);
//
//     useEffect(() => {//监听编辑状态值的变化
//         if (editing) {//如果开启编辑状态
//             inputRef.current.focus(); //input输入框聚焦
//         }
//     }, [editing]);
//
//     function toggleEdit() {//切换编辑状态
//         setEditing(!editing);
//         form.setFieldsValue({//将表格中的值赋值到表单中
//             // name:Easdf
//             [dataIndex]: record[dataIndex],
//         });
//     }
//
//     async function save() {//保存事件
//         try {
//             const values = await form.validateFields();//获取表单中的数据
//             toggleEdit();//切换编辑状态
//             handleSave({ ...record, ...values });//调用保存方法
//         } catch (errInfo) {
//             console.log("保存失败:", errInfo);
//         }
//     }
//
//     let childNode = children;
//     if (editable) {//如果开启了表格编辑属性
//         // 是否开启了编辑状态 (开启:显示输入框 关闭:显示div)
//         childNode = editing ? (
//             <Form.Item
//                 name={dataIndex}
//                 rules={[
//                     {
//                         required: true,
//                         message: `${title}是必填的.`,
//                     },
//                 ]}
//             >
//                 <Input ref={inputRef} onPressEnter={save} onBlur={save} />
//             </Form.Item>
//         ) : (
//             <div onClick={toggleEdit}>{children}</div>
//         );
//     }
//     return <td {...restProps}>{childNode}</td>;
// }
// export default  App=()=> {
//     let tableColumns = [//定义表格头
//         {
//             title: "姓名",
//             dataIndex: "name",
//             with: "30%",
//             editable: true,
//         },
//         {
//             title: "年龄",
//             dataIndex: "age",
//         },
//         {
//             title: "地址",
//             dataIndex: "address",
//         }
//     ];
//     let [tableData, setTableData] = useState([//定义表格数据
//         {
//             key: "0",
//             name: "Edward King 0",
//             age: "32",
//             address: "London, Park Lane no. 0",
//         },
//         {
//             key: "1",
//             name: "Edward King 1",
//             age: "32",
//             address: "London, Park Lane no. 1",
//         },
//     ]);
//
//     tableColumns = tableColumns.map((item) => {//遍历表格头数组
//         if (item.editable) {//如果表格头列开启了编辑属性
//             return {
//                 ...item,
//                 onCell: (record) => {
//                     return {
//                         record: record,
//                         editable: item.editable,
//                         dataIndex: item.dataIndex,
//                         title: item.title,
//                         handleSave: (row) => {//这个方法可以获取到行编辑之后的数据
//                             let findEditIndex = tableData.findIndex((item) => {//找到编辑行的索引
//                                 return item.key === row.key;
//                             });
//                             let findEditObj = tableData.find((item) => {//找到编辑行的数据对象
//                                 return item.key === row.key;
//                             });
//                             tableData.splice(findEditIndex, 1, { ...findEditObj, ...row });//将最新的数据更新到表格数据中
//                             setTableData([...tableData]);//设置表格数据
//                         },
//                     };
//                 },
//             };
//         } else {
//             return item;
//         }
//     });
//
//     return (
//         <div>
//             <Table
//                 bordered
//                 components={{
//                     body: {
//                         row: EditableRow,
//                         cell: EditableCell,
//                     },
//                 }}
//                 columns={tableColumns}
//                 dataSource={tableData}
//             />
//         </div>
//     );
// }
//



// // 活动圈圈
// import React, { Component } from "react";
//
// class Circle extends Component {
//     constructor(color, radius, v, angle, x, y) {
//         super();
//         this.color = color;
//         this.radius = radius;
//         this.v = v;
//         this.angle = angle;
//         this.x = x;
//         this.y = y;
//         // 创建一个 ref 来存储 canvas 的 DOM 元素
//         this.canvas = React.createRef();
//     }
//
//     componentDidMount() {
//         //获取真实canvasDOM
//         const canvas = this.canvas.current;
//         //圆对象数组
//         let arr = [];
//         //圆数量
//         const CNT = 2;
//         //绘制区域中心点
//         let centerX, centerY;
//         //绘制上下文
//         const ctx = canvas.getContext("2d");
//         //设置canvas满屏
//         canvas.width = window.innerWidth;
//         canvas.height = window.innerHeight;
//         //设置中心点
//         centerX = canvas.width / 2;
//         centerY = canvas.height / 2;
//         //实例化圆
//         for (let i = 0; i < CNT; i++) {
//             let c1 = new Circle(
//                 //随机颜色
//                 "rgba(" +
//                 255 * Math.random() +
//                 "," +
//                 255 * Math.random() +
//                 "," +
//                 255 * Math.random() +
//                 "," +
//                 Math.random() +
//                 ")",
//                 //随机半径
//                 66 * Math.random() + 1,
//                 //随机速度
//                 4 * Math.random() + 1,
//                 //随机角度
//                 360 * Math.random(),
//                 //x坐标
//                 centerX,
//                 //y坐标
//                 centerY
//             );
//             arr.push(c1);
//         }
//
//         function draw() {
//             //清除画布
//             ctx.clearRect(0, 0, canvas.width, canvas.height);
//             for (let i = 0; i < CNT; i++) {
//                 //移动x坐标
//                 arr[i].x += arr[i].v * Math.cos(arr[i].angle);
//                 //移动y坐标
//                 arr[i].y += arr[i].v * Math.sin(arr[i].angle);
//                 //反弹（angle在笛卡尔坐标系）
//                 if (arr[i].y < arr[i].radius) {
//                     //上
//                     arr[i].angle = 0 - arr[i].angle;
//                 }
//                 if (arr[i].y > canvas.height - arr[i].radius) {
//                     //下
//                     arr[i].angle = 0 - arr[i].angle;
//                 }
//                 if (arr[i].x < arr[i].radius) {
//                     //左
//                     arr[i].angle = Math.PI - arr[i].angle;
//                 }
//                 if (arr[i].x > canvas.width - arr[i].radius) {
//                     //右
//                     arr[i].angle = Math.PI - arr[i].angle;
//                 }
//                 //调用圆的绘制方法
//                 arr[i].draw(ctx);
//             }
//             //延迟50ms
//             setTimeout(draw, 42);
//         }
//         //调用绘制
//         draw();
//     }
//
//     /**
//      * 绘制圆
//      * @param  ctx 绘制上下文
//      */
//     draw(ctx) {
//         //开始绘制路径
//         ctx.beginPath();
//         //绘制圆
//         ctx.arc(this.x, this.y, this.radius, 0, 2 * Math.PI);
//         //关闭绘制路径
//         ctx.closePath();
//         //设置fill颜色
//         ctx.fillStyle = this.color;
//         //fill
//         ctx.fill();
//     }
//
//     render() {
//         return <canvas ref={this.canvas} className="circle"></canvas>;
//     }
// }
// export default Circle;



import React, { useRef, useEffect } from 'react';

const CanvasComponent = () => {
    const canvasRef = useRef(null);

    useEffect(() => {
        const canvas = canvasRef.current;
        const ctx = canvas.getContext('2d');

        // 绘制函数
        function drawRectangle() {
            ctx.fillStyle = 'red';
            ctx.fillRect(20, 20, 20, 20);
        }

        // 检查canvas是否已经准备好
        if (canvas.offsetHeight > 0) {
            drawRectangle();
        }
    }, []);

    return (
        <canvas ref={canvasRef} width={200} height={120} style={{ border: '1px solid #000000' }} />
    );
};

export default CanvasComponent;
