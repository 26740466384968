import React, { Component } from 'react'
import { NavLink } from "react-router-dom"
import {
    Layout,
    DatePicker,
    Select,
    Input,
    Divider,
    Descriptions, Radio, message, Upload, Button, Modal, Spin
} from 'antd'
import {
    addInspectionDev,
    addPoPatrolScheme,
    getInspectionDevInPatrolId, getInspectionResultInId, queryInspectionContent,
    queryList,
    removeInspectionDev,
    removePatrolSchemeInId, saveOrUpdateBatchInspectionResult,
    updatePoPatrolScheme
} from "../../../services/meter";
import moment from "moment";
import {getAllClassAndTemp} from "../../../services/equipment";
import { ArrowLeftOutlined ,StepForwardOutlined,PlusOutlined} from '@ant-design/icons';
import {pageValveInfo} from "../../../services/qrCode";
import FormsText from "../../../components/common/forms/FormsText";
import {funTraceInfo} from "../../../services/common";
const { RangePicker } = DatePicker;
const { TextArea } = Input;
const { Option } = Select;
// 巡检计划
export default class execute extends Component {

    state = {
        // 表单参数
        formText: {
            header: {
                planNumber: '巡检编号',
                name: '巡检名称',
                area: '巡检区域',
                inspector: '巡检人',
                nature: '巡检路线'
            },
            data:{}
        },

        inspectionDev:[],   // 本检巡设备列表
        classDevS:[],       // 所有设备设备类型

        fileUrl:'http://47.97.117.248:8352/ossManagement/ossUpload',
        fileList_1: [], // 设备照片
        newFileList:[],
        noMsgJuder_1:false,// 加载按钮
    };
    componentDidMount = async () => {

        var formText = this.state.formText;
        formText=JSON.parse(JSON.stringify(this.props.location.state.rowData??{}));
        this.getAllClassAndTemp();// 查询设备类型
        this.getInspectionDevInPatrolId(this.props.location.state.rowData.id);  // 根据巡检计划ID查询巡检设备



    };

    // 查询设备类型
    getAllClassAndTemp=()=>{
        // 查询树结构数据-设备页面
        getAllClassAndTemp().then(e=>{this.setState({classDevS:e?.data?.data??[]})});
    };

    // 根据巡检计划ID查询巡检设备
    getInspectionDevInPatrolId = (id)=>{
        let _this=this;
        getInspectionDevInPatrolId({patrolId:id}).then(
            response=>{
                if(response?.data?.data){
                    // inspectionDev.map(one=>{
                    //     // // 条件查询巡检内容；巡检内容、设备类型
                    //     _this.queryInspectionContent(null,one.devType,1,999,one);
                    //     console.log('==========1=============');
                    //     console.log(one);
                    //     console.log('------------------------');
                    // });
                    // // 根据巡检计划ID查询巡检结果
                    // _this.getInspectionResultInId(this.props.location.state.rowData.id,response?.data?.data);
                    _this.setState({inspectionDev:response?.data?.data});
                    _this.queryInspectionContents(response?.data?.data);
                }else{
                    _this.setState({inspectionDev:[]});
                }
            }
        )
    };

    // 条件查询巡检内容；巡检内容、设备类型
    queryInspectionContent = async (content,devType,page,size,data) => {
        var param = {
            "content": content??null,   // 巡检内容
            "devType": devType??null,   // 设备类型
            "page": page??1,            // 页数
            "size": size??10            // 条数
        };
        await queryInspectionContent(param).then(
            response=>{
                if(!response?.data?.data){message.info('查询失败');}
                data= Object.assign(data,{voInspectionResults:response?.data?.data});
            }
        );
    };
    // 条件查询巡检内容；巡检内容、设备类型
    queryInspectionContents = (inspectionDev=[]) => {
        inspectionDev.map(one=>{
            var param = {
                "content": null,              // 巡检内容
                "devType": one.devType??null, // 设备类型
                "page": 1,  // 页数
                "size": 999 // 条数
            };
            queryInspectionContent(param).then(
                response=>{
                    if(!response?.data?.data){message.info('查询失败');}
                    one= Object.assign(one,{voInspectionResults:response?.data?.data});
                }
            );
        });
        this.setState({inspectionDev:inspectionDev});
        // 根据巡检计划ID查询巡检结果
        this.getInspectionResultInId(this.props.location.state.rowData.id,inspectionDev);
    };
    // 弹窗设置
    handlePreview = (file) => {
        this.setState({
            previewImage: file.url || file.thumbUrl,    // 路径：网络路径或本地路径
            previewVisible: true,// 弹窗开关
        });
    };

    onFileChange =  (file,data) => {
        if (file.file.status !== 'uploading') {
        }
        if (file.file.status === 'done') {
            message.success(`${file.file.name} 上传成功`);
            // 痕迹
            // funTraceInfo("巡检内容管理", "上传", null,null)
        } else if (file.file.status === 'error') {
            message.error(`${file.file.name} 上传异常.`);
        }
        data = Object.assign(data,{fileList:file.fileList});
    };

    // 编辑巡检结果
    saveOrUpdateBatchInspectionResult=(inspectionDev,state)=>{

        let voInspectionDevs = [];
        inspectionDev.map(one=>{
            let param_1 ={
                'id':one?.id ?? null,
                "patrolSchemeId": this.props.location.state.rowData.id, // 巡检计划Id
                "remark": "",               // 备注
                "result": inspectionDev?.result??false,     // 正常(否)/异常(是)
                "state": state,             // 状态(0-正常/1-草稿)
                "voInspectionResults": []   // 小类
            };

            if(one?.voInspectionResults){
                one?.voInspectionResults.map(two=>{
                    let param_2 ={
                        "id":two.patrolId,
                        "content": two.content,     // 巡检内容
                        "inspectionDevId": one.id,  // 巡检设备ID
                        "picGroup": [],             // fileList response
                        "result": two.result
                    };
                    if(two?.fileList){
                        two?.fileList.map(three=>{
                            param_2.picGroup.push(three.response)
                        });
                    }
                    param_1.voInspectionResults.push(param_2);
                });
            }
            voInspectionDevs.push(param_1);
        });

        Modal.confirm({
            title: `是否确认?`,
            onOk: () => {
                this.setState({noMsgJuder_1:true});
                saveOrUpdateBatchInspectionResult(voInspectionDevs).then(
                    response=>{
                        this.setState({noMsgJuder_1:false});
                        if(response?.data?.data){
                            this.props.history.go(-1);
                            message.info('操作成功');
                        } else {
                            message.info('操作失败:'+response?.data?.responseInfo?.description);
                        }
                    }
                )
            }
        });
    };

    // 根据巡检计划ID查询巡检结果
    getInspectionResultInId=(patrolId,inspectionDev)=>{
        if(!inspectionDev){return false;}

        getInspectionResultInId({patrolId}).then(
            response=>{
                if(response?.data?.data){
                    let data1 = response.data.data??[];
                    // 原设备
                    inspectionDev.map(primaryOne=>{
                        // 获取保留的记录
                        data1.map(nowOne=>{
                            // 大类id匹配
                            if(primaryOne.id===nowOne.id){
                                // 判断大类里有没有小类，以及获取数据又没小类结果
                                if(primaryOne?.voInspectionResults && nowOne?.voInspectionResults){
                                    // 循环小类数据做匹配
                                    primaryOne.voInspectionResults.map(primaryTwo=>{
                                        // 循环获取数据的小类做数据匹配
                                        nowOne?.voInspectionResults.map(nowTwo=>{
                                            if(primaryTwo.content.indexOf(nowTwo.content)!==-1){
                                                var param = {
                                                    result:nowTwo?.result??null,
                                                    patrolId:nowTwo.id,
                                                    fileList:[]
                                                };
                                                if(nowTwo.picGroup){
                                                    nowTwo.picGroup.map(one=>{
                                                        param.fileList.push({
                                                            "response":one,
                                                            "thumbUrl":one,
                                                            "type": "image/png",
                                                        });
                                                    });
                                                }
                                                primaryTwo = Object.assign(primaryTwo,param);
                                            }
                                        });
                                    })
                                }
                            }
                        });
                    });
                    console.log('inspectionDev');
                    console.log(inspectionDev);
                    this.setState({inspectionDev:inspectionDev})
                }

            }
        )
    };

    render() {
        //本检巡设备列表=inspectionDev
        const {formText,inspectionDev,classDevS,noMsgJuder_1} = this.state;
        const _this=this;
        const uploadButton = (
            <div>
                <div className="ant-upload-text">上传图片</div>
            </div>
        );

        return (
            <Layout className={'item_body_number_back tables'} style={{backgroundColor: '#ffffff'}}>
                <div style={{width:'100%',height:'100%',margin:'0.5%'}}>
                    {/*等待页面---------------------------------------------------------------------------------------*/}
                    <div>
                        <div style={{
                            display:noMsgJuder_1? null:'none', position: 'absolute', top:'0',
                            paddingTop:'10%', width:'96%', height:'100%', fontSize:'30px', textAlign:'center',
                            overflow:'auto', color:'#797979', zIndex:'2', backgroundColor: 'rgba(245,245,245,0.5)'
                        }}>
                            <Spin />等待中
                        </div>
                    </div>

{/*巡检计划基础信息----------------------------------------------------------------------------------------------------*/}
                    <div style={{width:'100%',height:'180px',margin:'0.5%'}}>
                        {/*标题*/}
                        <div style={{width:'100%',height:'20%'}}>

                            <div style={{width:'77%',height:'100%',float:'left'}}>
                                <span style={{fontWeight:'bold',fontSize:'16px'}}>巡检计划基础信息</span>
                            </div>
                            <div style={{width:'23%',height:'100%',float:'left'}}>
                                <Button style={{margin:'0 0 0 20px'}} onClick={() => this.props.history.go(-1)}>返回</Button>
                                &nbsp;&nbsp;
                                <Button type={'primary'}
                                        onClick={()=>{
                                            this.saveOrUpdateBatchInspectionResult(inspectionDev,1);
                                        }}
                                >保存草稿</Button>
                                &nbsp;&nbsp;
                                <Button type={'primary'}
                                        onClick={()=>{
                                            this.saveOrUpdateBatchInspectionResult(inspectionDev,0);
                                        }}
                                >完成提交</Button>


                            </div>
                            <Divider style={{ margin: '8px 0' }} />
                        </div>
                        {/*基本信息*/}
                        <div style={{width:'100%',height:'78%',margin:'0.5%',}}>
                            <div style={{
                                width:'95%',height:'90%',border:'1px solid black',borderColor:'#ccc',
                                padding:'10px 0 0 50px'
                            }}>
                                <FormsText
                                    record={this.props.location.state.rowData??{}}
                                    header={formText.header}
                                    rowLen={4}
                                    span={6}
                                />
                            </div>
                        </div>
                    </div>
{/*巡检设备-----------------------------------------------------------------------------------------------------------*/}
                    <div style={{width:'100%',height:'74%',margin:'0.5%'}}>
                        {/*标题*/}
                        <div style={{width:'100%',height:'40px'}}>
                            <span style={{fontWeight:'bold',fontSize:'16px'}}>巡检计划基础信息</span>
                            <Divider style={{ margin: '8px 0' }} />
                        </div>
                        {/*框架*/}
                        <div style={{width:'95%',height:'85%',overflowY: 'auto',padding:'0 0 0 50px'}}>

                            {/*大类列表*/}
                            {inspectionDev.map(one=>{
                                var voInspectionResults= one.voInspectionResults??[];
                                var num = classDevS.findIndex(item=> Number(item?.devClassId)===Number(one.devType));
                                var devInfo= classDevS[num];
                                return(
                                    <div>
                                        {/*大类*/}
                                        <div style={{width:'100%',height:'50px'}}>
                                            <div style={{width:'45%',height:'100%'}}>
                                                <div style={{width:'80%',height:'100%',fontSize:'15px',fontWeight:'bold',color:'#5496d9',float:'left'}}>
                                                    <span>{devInfo?.devClassName}</span>&nbsp;&nbsp;-<span>{one?.devId}</span>:&nbsp;&nbsp;巡检结果
                                                </div>
                                                <div style={{width:'20%',height:'100%',float:'left'}}>
                                                    <Radio.Group
                                                        value={one?.result}
                                                        onChange={(e)=>{one=Object.assign(one,{result:e.target.value})
                                                    }}>
                                                        <Radio value={false}>否</Radio>
                                                        <Radio value={true}>是</Radio>
                                                    </Radio.Group>
                                                </div>
                                            </div>
                                        </div>
                                        {/*巡检内容--*/}

                                        {/*整体*/}
                                        <div style={{width:'100%',height:'100%'}}>
                                            {/*大类-单条数据*/}
                                            {voInspectionResults.map(two=>{
                                                two.inspectionDevId=two.id;
                                                return<div style={{width:'100%',height:'115px'}}>

                                                    <div style={{width:'45%',height:'100%',float:'left'}}>
                                                        {/*文字-内容标题*/}
                                                        <div style={{
                                                            paddingLeft:'50px',width:'80%',height:'100%',
                                                            fontSize:'15px',color:'#000000',float:'left',
                                                            paddingTop:'60px'
                                                        }}>
                                                            <span>{two?.content}</span>
                                                            <Divider style={{ margin: '8px 0' }} />
                                                        </div>
                                                        {/*文字-选择*/}
                                                        <div style={{width:'20%',height:'100%',float:'left',paddingTop:'60px'}}>
                                                            <Radio.Group
                                                                value={two?.result}
                                                                onChange={(e)=>{
                                                                two=Object.assign(two,{result:e.target.value})
                                                            }}>
                                                                <Radio value={false}>否</Radio>
                                                                <Radio value={true}>是</Radio>
                                                            </Radio.Group>
                                                        </div>
                                                    </div>
                                                    {/*图片*/}
                                                    <div style={{width:'45%',height:'100%',float:'left'}}>
                                                        <Upload
                                                            action={this.state.fileUrl}
                                                            listType="picture-card"
                                                            name={"file"}
                                                            fileList={two.fileList}
                                                            onPreview={(file) => {}}
                                                            onChange={ (file) => {
                                                                this.onFileChange(file,two);
                                                            }}
                                                        >
                                                            {this.state.fileList_1.length >= 1 ? null : uploadButton}
                                                        </Upload>
                                                    </div>
                                                </div>
                                            })}
                                        </div>
                                        <div style={{width:'100%',height:'10px',borderTop:'1px solid black',borderColor:'#ccc'}}/>
                                    </div>
                                )
                            })}
                        </div>
                    </div>


{/*功能按钮-----------------------------------------------------------------------------------------------------------*/}
{/*                    <div style={{width:'100%',height:'7%',margin:'1%',textAlign: 'center'}}>*/}

{/*                        <Button type={'primary'}*/}
{/*                                onClick={()=>{*/}
{/*                                    this.saveOrUpdateBatchInspectionResult(inspectionDev,1);*/}
{/*                                }}*/}
{/*                        >保存草稿</Button>*/}
{/*                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;*/}
{/*                        <Button type={'primary'}*/}
{/*                                onClick={()=>{*/}
{/*                                    this.saveOrUpdateBatchInspectionResult(inspectionDev,0);*/}
{/*                                }}*/}
{/*                        >完成提交</Button>*/}
{/*                    </div>*/}

                </div>
            </Layout>
        )
    }
}
