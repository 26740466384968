import React, { Component } from 'react'
import { NavLink } from "react-router-dom"
import {
    Layout,
    Modal,
    Radio,
    Form,
    DatePicker,
    Button,
    Select,
    Input,
    Table,
    message,
    Spin,
    Divider,
    Space,
    Popconfirm
} from 'antd'
import {TYPE_NAME} from '../../../components/Const'
import TableComponents from '../../../components/common/tables/TableComponents'
import {
    addInspectionDev,
    addPoPatrolScheme,
    getInspectionDevInPatrolId,
    queryList,
    removeInspectionDev,
    removePatrolSchemeInId,
    updatePoPatrolScheme
} from "../../../services/meter";
import moment from "moment";
import {getAllClassAndTemp} from "../../../services/equipment";
import { StepBackwardOutlined ,StepForwardOutlined,PlusOutlined} from '@ant-design/icons';
import {pageValveInfo} from "../../../services/qrCode";
const { RangePicker } = DatePicker;
const { TextArea } = Input;
const { Option } = Select;
// 巡检计划-配置页
export default class inspection extends Component {

    state = {

        InputData:'',


        isModalOpen_1:false,// 弹窗-1
        isModalOpen_2:false,// 弹窗-2
        isModalName_1:'新增',
        isModalName_2:'添加',

        noMsgJuder_1:false,// 加载控制

        initialValues_1:{id:1},// 弹窗-1-表单数据
        initialValues_2:{},// 弹窗-2-表单数据
        initialValues_3:{},// 弹窗-2-表单内选取设备
        // 设备列表-1-设备类型
        optionDataS_1:[
            // { value: '10196', label: '报警控制器' },
            // { value: '10168', label: '报警探测器' },
            // { value: '10200', label: '阀井' },
            // { value: '10202', label: '家用报警' },
            // { value: '10210', label: '管钉' },
            // { value: '10171', label: '压力表' },
            // { value: '10209', label: '监控' },
            // { value: '10211', label: '调压柜' },
        ],
        // 设备列表-2-设备编号
        optionDataS_2:[],
        // 设备列表-2-查询参数
        optionPage: {
            devType:null,
            page: 1,
            size:5,
            AllPage:0,
        },
        // 弹窗-设备表格
        tableData_1_head:[
            {title: '设备编号', dataIndex: 'devId', key: 'devId'},
            {title: '设备类型', dataIndex: 'devType', key: 'devType',
                render: (text, rowData,index) => {
                var name = null;
                if(text){
                    this.state.optionDataS_1.map(one=>{if(text===one.value){name=one.label.toString();}});}
                    return<><div style={{width:'100%'}}>{name}</div></>
                }
            },
            {title: '操作', dataIndex: 'edit',width:'200px', align:'center',
                render: (text, rowData,index) => {
                    return<>
                        <div style={{width:'100%'}}>
                            <div  title={'删除'} style={{cursor: 'pointer',width:'60px',float:'left'}}>
                                <u style={{color:'red'}} onClick={()=>{this.deleteDev(rowData.id);}}>删除</u>
                            </div>
                        </div>
                    </>
                }

            }
        ],
        tableData_1_data:[],
        tableData_1_loading:false,



        // 表格参数
        tableParamenter: {
            manageName: "巡检计划",
            // 是否绑定左侧区域
            bindArea: true,
            // 表格行是否可选
            rowSelection: false,
            // 获取表格参数数据
            localStorageName: 'inspection',
            //下拉框长度
            selectWidth: '7.5vw',
            // 表头：变量和变量解释
            fileName: '居民安全自检管理',
            header: {
                planNumber: '计划编号',
                name: '计划名称',
                startTimeOfPlan: '开始时间',
                endTimeOfPlan: '结束时间',
                state: '计划状态',
                area: '巡检区域',
                inspectorDev: '巡检设备数量',
                abnormalDev: '异常设备',
                edit: '操作',
            },

            // 条件选择器参数
            conditionsParamenter: {
                // 条件
                parameter: [
                    {
                        type: TYPE_NAME.RANGE_PICKER, variableName: 'time', str: '计划时间',
                        initData: JSON.parse(localStorage.getItem('inspection'))?.name
                    },
                    {
                        type: TYPE_NAME.NAME, variableName: 'planNumber', str: '计划编号',
                        initData: JSON.parse(localStorage.getItem('inspection'))?.addressInfo
                    },
                    {
                        type: TYPE_NAME.NAME, variableName: 'area', str: '巡查区域',
                        initData: JSON.parse(localStorage.getItem('inspection'))?.addressInfo
                    },
                ],
                onchange:()=>{console.log('111111111')}
            },
            params: {

                page: 1,
                size:10,
            }
        },




    };
    componentWillMount() {
        // 查询设备类型
        getAllClassAndTemp().then(e=>{
            var datas=[];
            e?.data?.data.map(one=>{datas.push({key:one.devClassId,value:one.devClassId,label:one.devClassName})});
            this.setState({optionDataS_1:datas})
        });


    }

    setTableValue = (value,text,rowData) => {
        switch (value) {
            case 'state':
                return (
                    <div>
                        <span>{text===0?'未开始':text===2?'进行中':text===3?'已完成':null}</span>
                    </div>
                );

            case 'edit':
                return (
                    <div>
                        <div style={{float: 'left',width:'50px'}}>
                            <div className={'table_overflow'} title={text}>
                                <a
                                    onClick={()=>{
                                        this.props.history.push({
                                            pathname:"/inspectManagement/configuration",
                                            state:{
                                                rowData:rowData
                                            }
                                        })

                                    }}
                                >编辑
                                </a>
                            </div>
                        </div>
                        <div style={{float: 'left',width:'50px'}}>
                            <div className={'table_overflow'} title={text}>
                                <a
                                    onClick={()=>{
                                        this.props.history.push({
                                            pathname:"/inspectManagement/execute",
                                            state:{rowData:rowData}
                                        })
                                    }}
                                >执行
                                </a>
                            </div>
                        </div>
                        <div style={{float: 'left',width:'50px'}}>
                            <div className={'table_overflow'} title={text}>
                                <Popconfirm
                                    title="是否删除"
                                    description="是否删除"
                                    onConfirm={()=>{this.removePatrolSchemeInId(rowData?.id)}}
                                    onCancel={null}
                                    okText="是"
                                    cancelText="否"
                                >
                                    <a>删除</a>
                                </Popconfirm>
                            </div>
                        </div>
                    </div>
                );
            default:
                return text ? <div className={'table_overflow'} title={text}>{text}</div> : ''
        }
    };

    // 根据巡检计划ID删除巡检计划
    removePatrolSchemeInId=(id)=>{
        removePatrolSchemeInId({patrolId:id}).then(
            response=>{
                if(!response?.data?.data){return message.info('删除失败');}
                this.table.refreshTable();
                this.setState({tableData_1_loading: false});
            }
        )

    };



    render() {
        return (
            <Layout className={'item_body_number_back tables'} style={{backgroundColor: '#ffffff'}}>
                <TableComponents
                    id={'customerId'}
                    paramenter={this.state.tableParamenter}
                    setData={queryList}
                    setTableValue={this.setTableValue}

                    ref={e => this.table = e}
                >
                    <Radio.Group defaultValue="addNew" buttonStyle="solid">
                        <NavLink
                            to={{
                                pathname:"/inspectManagement/configuration",
                                state:{
                                }
                            }}
                        >
                            <Radio.Button  value="addNew">新增</Radio.Button>
                        </NavLink>

                    </Radio.Group>
                </TableComponents>

            </Layout>
        )
    }
}
