import React, { Component } from 'react'
import {
    Tabs,
    Layout,
    Select,
    message,
    Form,
    Radio,
    Button,
    Modal,
    Input,
    Upload,
    DatePicker,
    Popconfirm,
    Table,
    Switch
} from 'antd'
import Tables from '../../../components/common/tables/Tables'
import Conditions from '../../../components/common/conditions/Conditions'
import {
    configType,
    getPageFuzzyQuery,
    processAlarmInfo,
    getPageFuzzyQuerys,
    getAllAlarmTypes
} from '../../../services/alarm'
import {funTraceInfo} from '../../../services/common'
import moment from "moment";
import {TYPE_NAME} from '../../../components/Const'
import FormsTwo from '../../../components/common/forms/FormsTwo'
import * as echarts from 'echarts/lib/echarts';
// 引入柱状图（这里放你需要使用的echarts类型 很重要）
import  'echarts/lib/chart/bar';
import  'echarts/lib/chart/pie';
import  'echarts/lib/chart/line';
import { TitleComponent,TooltipComponent,LegendComponent,ToolboxComponent,GridComponent } from 'echarts/components';
import {NavLink} from "react-router-dom";
import TableComponents from "../../../components/common/tables/TableComponentsNew";
import {
    getConfigsByAlarmSourceId,
    getPageAlarmSourceQuery,
    pageDeviceInfo,
    PageQueryLifecycle
} from "../../../services/qrCode";
echarts.use([TitleComponent,TooltipComponent,LegendComponent,ToolboxComponent,GridComponent]);

const { Option } = Select;
const { TabPane } = Tabs;
const FormItem = Form.Item;
const { TextArea } = Input;
export default class AlarmInfo extends Component{
    state = {
        // 聚合模式
        typeSwitch:false,

        // 聚合模式-父数据
        tableParamenter_1_value:[
            {title: '信息来源', dataIndex: 'systemName', key: 'systemName'},
            {title: '报警类型', dataIndex: 'alarmTypeName', key: 'alarmTypeName'},
            {title: '报警等级', dataIndex: 'level', key: 'level', render: (text, record) => <div>{text===0?'高':text===1?'中':text===2?'低':'未知'}</div>},
            {title: '报警消息', dataIndex: 'pushContent', key: 'pushContent'},
            {title: '更新时间', dataIndex: 'updateTime', key: 'updateTime', render:(text,record)=>{return<><span>{text?moment(text).format("yyyy-MM-DD HH:mm:ss"):'-'}</span></>}},
            {title: '创建时间', dataIndex: 'createTime', key: 'createTime', render:(text,record)=>{return<><span>{text?moment(text).format("yyyy-MM-DD HH:mm:ss"):'-'}</span></>}},
            {title: '创建人', dataIndex: 'createUserName', key: 'createUserName'},
            {title: '信息来源', dataIndex: 'systemName', key: 'systemName'},
        ],
        // 单选框
        tableParamenter_1_selectOne:this.props?.location?.state?.selectParams?.selectOne?this.props?.location?.state?.selectParams?.selectOne:[],
        // 单选数据
        tableParamenter_1_selectData:this.props?.location?.state?.selectParams?.selectData?this.props?.location?.state?.selectParams?.selectData:[],
        tableParamenter_1_data:[],
        tableParamenter_1_param:{
            page:1,
            size:10,
        },
        tableParamenter_1_loading:false,
        // 聚合模式-子表
        tableParamenter_2_value:[
            {title: '来源名称', dataIndex: 'sourceName', key: 'sourceName'},
            {title: '报警描述', dataIndex: 'alarmDescription', key: 'alarmDescription'},
            {title: '报警时间', dataIndex: 'alarmTime', key: 'alarmTime', render:(text,record)=>{return<><span>{text?moment(text).format("yyyy-MM-DD HH:mm:ss"):'-'}</span></>}},
            {title: '报警对象', dataIndex: 'associationName', key: 'associationName'},
            {title: '报警类型', dataIndex: 'alarmType', key: 'alarmType'},
            {title: '报警等级', dataIndex: 'alarmLevel', key: 'alarmLevel'},
        ],
        tableParamenter_2_data:[],
        tableParamenter_2_param:{

            total:0,
            baseAlarmInfoAlarmTimeStart: null,
            baseAlarmInfoAlarmTimeEnd: null,
            page: {

                current: 1,
                size: 10
            },
            userId:JSON.parse(sessionStorage.getItem("user")).id,

            sourceName:null,  //信息来源
            alarmTypeId:null, //报警类型
            sourceId:null,    //通知配置ID
            code:null,//报警对象
        },
        tableParamenter_2_loading:false,


        // 控制器表格参数
        tableParamenter_1: {
            manageName: "SIM卡申请管理",
            // 表格行是否可选
            rowSelection: false,
            //下拉框长度
            selectWidth: '6.5vw',
            // 表头：变量和变量解释
            header: {
                hdstno: '控制器编号',
                communicateno: '控制器通信号',
                devModelName:'设备型号',
                devNumber:'下辖设备数',
                manufactureTime: '生产日期',
                manufacture: '供应商',
                state: '状态',
                comStatus: '通信状态',
                comTime: '通信时间',
                dataTime: '最后数据时间',
                edit: '操作',
            },

            data:[],
            params: {
                // deviceType:10196,
                page:1,
                size:10,

            },
            // 是否绑定左侧区域
            bindArea: true,
        },


        modalVisible:false,// 弹窗开关
        fileUrl:'http://47.97.117.248:8352/ossManagement/ossUpload',// 上传路径、
        fileList_1:[],// 上传图片
        disposalTime:moment().startOf("day").format("YYYY-MM-DD HH:mm:ss"),   // 处置时间

        type:'现场', // 处置类型
        disposer:'',        // 处置人
        tel:'',
        disposalRemark:'',// 处置说明
        alarmMessageId:'',// 报警信息外键

        statusText_1:'',
        statusText_2:'',


		// 表格参数
		tableParamenter: {
            rowSelection: true,
            // title: '痕迹记录查询',
			// 表头：变量和变量解释
			header: {
				sourceName : '来源名称',
				alarmDescription : '报警描述',
				alarmTime : '报警时间',
                associationName : '报警对象',
				alarmType : '报警类型',
                alarmLevel : '报警等级',
                state:'状态',
                edit:'操作'
			},
			// 下拉框：变量和变量解释
			headers: {
				sourceName : '来源名称',
				alarmDescription : '报警描述',
				alarmTime : '报警时间',
                associationName : '报警对象',
				alarmType : '报警类型',
                alarmLevel : '报警等级',
			},
			// 表格数据
			data: [],
		},
        // 条件选择器参数
        conditionsParamenter: {
            // 是否显示条件标题
            isLable: true,
            // 是否显示清空按钮
            clearButton: true,
            parameter: [
                {
                    type: 'input',
                    variableName: 'sourceName',
                    str: '信息来源',
                    time:true
                },
                {
                    type: 'input',
                    variableName: 'code',
                    str: '报警对象',
                    time:true
                },


                {
                    type: 'select',
                    variableName: 'alarmTypeId',
                    str: '报警类型',
                    time:true,
                    data: [
                        {key: '1', value: '断电报警'},
                        {key: '2', value: '掉线报警'},
                        {key: '3', value: '零流量报警'},
                        {key: '4', value: '低流量报警'},
                        {key: '5', value: '超流量报警'},
                    ]
                },
                {
                    type: 'rangePicker',
                    variableName: 'alarmTime',
                    str: '报警时间',
                    time:true
                },
            ]
        },

          formParamenter: {
            modal: true,
            number: 1,
            labelCol: 4,
            align: 'center',
            layout: "horizontal",
            parameter: [
                {
                    variableName: 'alarmType',
                    str: '报警类型',
                    rules: TYPE_NAME.INPUT,

                },

            ]
        },

		// 总条数
		totalCount: 0,
        pageinfo:{
            baseAlarmInfoAlarmTimeStart: null,
            baseAlarmInfoAlarmTimeEnd: null,
            page: {

                current: 1,
                size: 13
            },
            userId:JSON.parse(sessionStorage.getItem("user")).id,
            code:null,//报警对象
            sourceName:null,  //信息来源
            alarmTypeId:null, //报警类型
            sourceId:null     //通知配置ID
        },
         // 参数-后端
        params: {
            sourceInfoSourceName: null,
            relevanceId :null,
            alarmTypeAlarmType:null,
            alarmLevel:null,
            baseAlarmInfoAlarmTimeStart:null,
            baseAlarmInfoAlarmTimeEnd:null,
            alarmTypeId:null
        },
         // 表格加载动画
         loading: false,
         // 刷新动画
         refreshTable: false,
        keyes:[],
        listDatas:[],
        rowData: [],
        manufactureValue: [],
		meterModelValue: [],
        bos:null,
        visible: false,
        operatorNames:null,
        operationNamees:null,
        traceValue:null,
        formdatas:[]
	};

    componentDidMount() {
        this.getData();
        // this.getAllAlarmTypes();
    }

    getAllAlarmTypes=()=>{
        getAllAlarmTypes().then(
            response=>{
                if(response?.data?.data){

                    var conditionsParamenter = this.state.conditionsParamenter;

                    response.data.data.map(one=>{
                        conditionsParamenter.parameter[2].data.push({
                            key: one?.id??null, value: one?.alarmType??null
                        })

                    });
                    this.setState({conditionsParamenter:conditionsParamenter})
                }
            }
        )
    };



    // 条件选择：获取输入的条件
    getConditionsValue = value => {
        console.log("value的值",value);
        console.log("条件查询之前",this.state.params);
        let {params} = this.state;     // 获取两个state的数据管理
        // 判断有没有时间参数
        if(value.alarmTime){
            params.baseAlarmInfoAlarmTimeStart = moment(value.alarmTime[0]._d).format('YYYY-MM-DD HH:mm:ss');
            params.baseAlarmInfoAlarmTimeEnd   = moment(value.alarmTime[1]._d).format('YYYY-MM-DD HH:mm:ss');
        }
        params.alarmTypeId=value?.alarmTypeId || null;          //报警类型
        params.sourceName=value?.sourceName || null;            //信息来源
        params.code=value?.code || null;  //报警对象
        // params.alarmLevel=value.alarmLevel;
        // params.relevanceId=value.relevanceId;
        this.setState({params});   // 确定修改
        console.log("条件查询之后",this.state.params);
        let param = this.state.pageinfo;
        param.baseAlarmInfoAlarmTimeStart=this.state.params.baseAlarmInfoAlarmTimeStart;
        param.baseAlarmInfoAlarmTimeEnd=this.state.params.baseAlarmInfoAlarmTimeEnd;
        param.relevanceId=this.state.params.relevanceId || null;
        param.alarmLevel=this.state.params.alarmLevel || null;
        param.alarmTypeId=this.state.params.alarmTypeId || null;
        param.page.current=1;

        param.sourceName=this.state.params.sourceName || null;
        param.code=this.state.params.code || null;
        // code:"",        //表钢号
        //     sourceName:"",  //信息来源
        //     alarmTypeId:"", //报警类型
        //     sourceId:""     //通知配置ID
        getPageFuzzyQuerys(param).then(
            response => {
              const {data} = response.data;
              console.log('展示的data', data);
              let {tableParamenter} = this.state;
              tableParamenter.data = data;
              const {pageInfo} = response.data;
              let total= pageInfo.total;
            //   let {pageinfo} = this.state
            //   pageinfo.current = pageInfo.pageIndex
            //   pageinfo.size = pageInfo.pageLine
            //   console.log('展示的pageinfo', pageinfo)
            this.setState({
                loading: false,
                refreshTable: false,
                tableParamenter:tableParamenter,
                totalCount:total,
                // pageinfo
            });
            message.success('查询成功')
        }
    ).catch(
        () => {
            this.setState({
                loading: false,
                refreshTable: false,
            });
            message.error('查询失败')
        }
    )

};





// 清空条件
clearCondition = () => {
    let {params,pageinfo,tableParamenter_2_param} = this.state;
    params.baseAlarmInfoAlarmTimeStart=null;
    params.baseAlarmInfoAlarmTimeEnd=null;
    params.alarmTypeId=null;
    pageinfo.baseAlarmInfoAlarmTimeStart=null;
    pageinfo.baseAlarmInfoAlarmTimeEnd=null;
    pageinfo.alarmTypeId=null;
    pageinfo.code=null;
    pageinfo.page.current=1;

    // 清空聚合模式参数
    tableParamenter_2_param.baseAlarmInfoAlarmTimeStart = null;
    tableParamenter_2_param.baseAlarmInfoAlarmTimeEnd   = null;
    tableParamenter_2_param.alarmTypeId= null;          //报警类型
    tableParamenter_2_param.sourceName= null;            //信息来源
    tableParamenter_2_param.code= null;  //报警对象


    // alarmTypeId
    // params.relevanceInfoAssociationName=null,
    // params.alarmLevel=null,

   this.setState({params:params,pageinfo:pageinfo,tableParamenter_2_param:tableParamenter_2_param});
   this.getData();
};




     // 查询表格数据
     getData =()=>{
        this.setState({loading: true});
        let param = this.state.pageinfo;
         getPageFuzzyQuerys(param).then(
            response=>{
                let {data} = response.data;
                let {tableParamenter} = this.state;
                tableParamenter.data = data;
                const {pageInfo} = response.data;   // 获取分页数据
                console.log('获取分页数据');
                console.log(pageInfo);
                let total= pageInfo.total;
                this.setState({
                    loading: false,
                    refreshTable: false,
                    tableParamenter:tableParamenter,
                    totalCount:total
                });
                message.success('查询成功')
            }
        ).catch(
            () => {
                this.setState({
                    loading: false,
                    refreshTable: false,
                });

                message.error('查询失败')
            }
        )
    };
    // 查询表格数据
    getData_polymerization =(record)=>{
        console.log('查询表格数据-聚合模式');
        let tableParamenter_2_param = this.state.tableParamenter_2_param;
        console.log(this.state.tableParamenter_1_selectData[0]?.id );
        tableParamenter_2_param.sourceId=record?.id || this.state.tableParamenter_1_selectData[0]?.id;
        this.setState({tableParamenter_2_loading:true});
        getPageFuzzyQuerys(tableParamenter_2_param).then(
            response=>{
                let {data} = response.data;
                let {tableParamenter_2_data,tableParamenter_2_param} = this.state;
                tableParamenter_2_data = data;
                const {pageInfo} = response.data;   // 获取分页数据
                console.log('获取分页数据');
                console.log(pageInfo);
                let total= pageInfo.total;
                tableParamenter_2_param.total=total;
                this.setState({
                    tableParamenter_2_loading:false,
                    // loading: false,
                    refreshTable: false,
                    tableParamenter_2_data:tableParamenter_2_data,
                    tableParamenter_2_param:tableParamenter_2_param,
                    totalCount:total
                });
                message.success('查询成功')
            }
        ).catch(
            () => {
                this.setState({
                    tableParamenter_2_loading:false,
                    loading: false,
                    refreshTable: false,
                });

                message.error('查询失败')
                this.setState({
                    tableParamenter_2_loading:false,
                });
            }
        )
    };
    setTableValue = (value,text,rowData) => {

        switch (value) {


            // case 'alarmLevel':
            //     text = text * 1
            //     return text === 2 ? '一般' : text === 1 ? '紧急' : text === 0 ? '重要' : '-';

            case 'state':
                return(
                    // <div className={'table_overflow'} title={text}>
                    //     <u>{text}</u>
                    // </div>
                    <NavLink to={{
                        pathname: "/alarm/details/AlarmHandleInfo",
                        state: {
                            rowData:rowData
                        }}}>
                        <div className={'table_overflow'} title={text}>
                            {text==="未处理"?<u style={{color:'#f5222d'}}>{text}</u>:<u>{text}</u>}
                        </div>
                    </NavLink>
                );
            case 'alarmTime':
                text =moment(text).format('YYYY-MM-DD HH:mm:ss');
               return(
                   <span>{text}</span>
               );
            case 'edit':
                return <>
                    {rowData.state==='已处理'
                        ?<div className={'tableLink'}>
                            <span style={{color:'#b8b8b8'}}>{'处理'}</span>
                        </div>
                        :<div className={'tableLink'} title={'处理'} onClick={()=>{this.setState({modalVisible:true,alarmMessageId:rowData.id})}}>
                            <u>{'处理'}</u>
                        </div>
                    }
                    {rowData.state==='已处理'
                        ?<div className={'tableLink'} title={'忽略'}>
                            <span style={{color:'#b8b8b8'}}>{'忽略'}</span>
                        </div>
                        :<div className={'tableLink'} title={'忽略'}>
                            <Popconfirm
                                title="确认框"
                                description="确定是否忽略"
                                onConfirm={()=>{
                                    this.processAlarmInfo(rowData.id,'忽略');
                                    // this.processAlarmInfo('忽略');
                                }}
                                // onCancel={cancel}
                                okText="是"
                                cancelText="否"
                            >
                                <u>{'忽略'}</u>
                            </Popconfirm>
                        </div>
                    }
                </>;
               default:
                return text ? <div className={'table_overflow'} title={text}>{text}</div> : '-'
        }
    }

	// 菜单栏: 获取菜单栏选中的值
	getMenusValue = value => {
		console.log('菜单栏选中的值',value)
	}

	// 表格：刷新方法
	refreshTable = () => {
        this.getData();
		console.log('表单刷新了')
	}

	// 表格：导出
	exportTable = () => {
		console.log('数据导出')
	}

     // 表格：获取勾选中的值
     onSelectChange = data => {
        this.setState({ rowData: data})
    }

	// 获取当前页数和条数
	getPagination = (page, pageSize) => {
        this.setState({loading:true});
        let {pageinfo,params} = this.state; // 分页，和后端参数
	    pageinfo.page.current = page;       // 页数
		pageinfo.page.size = pageSize;      // 每页数量
		this.setState({pageinfo:pageinfo});
        pageinfo.baseAlarmInfoAlarmTimeEnd=params.baseAlarmInfoAlarmTimeEnd;
        pageinfo.baseAlarmInfoAlarmTimeStart=params.baseAlarmInfoAlarmTimeStart;
        pageinfo.alarmTypeId=params.alarmTypeId;
        getPageFuzzyQuerys(pageinfo).then(
            response=>{
                let {data} = response.data;
                let {tableParamenter} = this.state;
                tableParamenter.data = data;
                const {pageInfo} = response.data;
                let total= pageInfo.total;
                this.setState({
                    loading: false,
                    refreshTable: false,
                    tableParamenter:tableParamenter,
                    totalCount:total
                });
                message.success('查询成功')
            }
        ).catch(
            () => {
                this.setState({
                    loading: false,
                    refreshTable: false,
                });

                message.error('查询失败')
            }
        )
	};



    improtTable = () => {
        console.log('数据导入')
    };



    configInfo = () => {
        if(this.state.rowData.length < 1) return message.warning('未选择数据',[0.5])
        let datas = {

            alarmType: this.state.rowData[0].alarmType,
          }

          console.log("报警类型",datas)
        this.setState({
            visible: true,
            formdatas: datas
        });
        console.log("ooooooo",this.state.datas);
        setTimeout(() => this.forms.onModalClose(), 0)
    };

    handleOk = async () => {
        this.setState({ loading: true });
        let value = await this.forms.onFinish();
        let params = {
            id: this.state.rowData[0].id,
            sourceId: this.state.rowData[0].sourceId,
            alarmTypeId: this.state.rowData[0].alarmTypeId,
            alarmId: this.state.rowData[0].alarmId,
            alarmLevel: this.state.rowData[0].alarmLevel,
            sourceName: this.state.rowData[0].sourceName,
            alarmDescription: this.state.rowData[0].alarmDescription,
            alarmTime: this.state.rowData[0].alarmTime,
            associationName: this.state.rowData[0].associationName,
            alarmType: value.alarmType,

        }
        console.log("查询参数",params);
        // 新增数据权限
        configType(params).then(
            response => {
                response.data.data ? message.success('配置成功', [0.5]) : message.error('配置失败', [0.5])
                this.getData();
                funTraceInfo("报警管理", "信息配置", null,params)
            }
        )
        setTimeout(() => {
            this.setState({ loading: false, visible: false });
        }, 0);

    };

    handleCancel = () => {
        this.setState({ visible: false });
    };

    onChange_img =  file => {
        if (file.file.status !== 'uploading') {
            console.log(file.file, file.fileList);
        }
        if (file.file.status === 'done') {
            message.success(`${file.file.name} 上传成功`);
            funTraceInfo("报警管理", "上传", null,null)
        } else if (file.file.status === 'error') {
            message.error(`${file.file.name} 上传异常.`);
        }
        this.setState({fileList_1:file.fileList})
    };

    // 获取设备异常信息列表s
    processAlarmInfo=(id,text=String)=>{
        var params={};
        if(text!=='忽略'){
            // 判断参数是否为空 处置人 联系电话
            if(this.state.disposer===''||this.tel===''){
                this.setState({
                    statusText_1:this.state.disposer===''?"error":'',
                    statusText_2:this.state.tel===''?"error":'',
                });
                return message.info("内容不能为空")
            }

            params={
                alarmMessageId: this.state.alarmMessageId   !==''?this.state.alarmMessageId: null,  // 报警信息外键
                disposalRemark: this.state.disposalRemark   !==''?this.state.disposalRemark:null,   // 处置说明
                disposalTime:   this.state.disposalTime     !==''?this.state.disposalTime:null,     // 处置时间
                disposer:       this.state.disposer         !==''?this.state.disposer:null,         // 处置人
                tel:            this.state.tel              !==''?this.state.tel:null,              // 联系电话
                type:           this.state.type             !==''?this.state.type:null,             // 联系电话
                path: [
                ]
            };
            this.state.fileList_1.map(one=>{
                params.path.push(one.response)
            });
        }else{
            params={
                alarmMessageId: id,  // 报警信息外键
                type:'忽略'
            }
        }
        console.log(params);
        this.setState({modalVisible:false});
        processAlarmInfo(params).then(
            response=>{
                if(response?.data?.data){
                    console.log(response);
                    this.getData();
                    funTraceInfo("报警管理", "信息处理", null,params)
                    return message.info("处理成功")
                }else{
                    return message.info("处理失败")
                }

            }
        ).catch(e=>{return message.info("异常问题，请联系管理员")})

    };
    // 初始化
    componentWillMount() {
        console.log('初始化');
        this.getPageAlarmSourceQuery();
        if(this.state.tableParamenter_1_selectData.length!==0){
            console.log(this.props?.location?.state?.selectParams);
            this.getConfigsByAlarmSourceId(this.state.tableParamenter_1_selectData[0]);
        }

    }
    getPageAlarmSourceQuery=()=>{
        this.setState({tableParamenter_1_loading:true});
        getPageAlarmSourceQuery(this.state.tableParamenter_1_param).then(
            response=>{
                if(response?.data?.data){
                    var tableParamenter = this.state.tableParamenter_1_data;
                    tableParamenter=response.data.data;
                    this.setState({tableParamenter_1_data:tableParamenter})
                }else{
                    message.info('查询数据失败')
                }
                this.setState({tableParamenter_1_loading:false});
            }

        )
    };
    getConfigsByAlarmSourceId=(data)=>{
        var params = {
            alarmSourceId: data.id,
            page:1,
            size:10
        };
        // console.log(params);
        getConfigsByAlarmSourceId(params).then(
            response=>{
                if(response.data.data){
                    console.log(response);
                    var tableParamenter = [...this.state.tableParamenter_2_data];
                    var param =this.state.tableParamenter_2_param;
                    tableParamenter=response.data.data;
                    param.total=response.data.data.length;

                    this.setState({tableParamenter_2_data:tableParamenter,tableParamenter_2_param:param});
                    console.log(tableParamenter);
                }
            }
        )
    };

    getPagination_polymerization=(page,size)=>{
        let tableParamenter_2_param = this.state.tableParamenter_2_param;
        tableParamenter_2_param.page.current=page;
        tableParamenter_2_param.page.size=size;
        this.setState({tableParamenter_2_param:tableParamenter_2_param});
        this.getData_polymerization()
    };


    // 条件选择：获取输入的条件
    getConditionsValue_polymerization = value => {
        console.log("value的值",value);
        console.log("条件查询之前",this.state.params);
        let {params} = this.state;     // 获取两个state的数据管理
        let tableParamenter_2_param = this.state.tableParamenter_2_param;
        // 判断有没有时间参数
        if(value.alarmTime){
            tableParamenter_2_param.baseAlarmInfoAlarmTimeStart = moment(value.alarmTime[0]._d).format('YYYY-MM-DD HH:mm:ss');
            tableParamenter_2_param.baseAlarmInfoAlarmTimeEnd   = moment(value.alarmTime[1]._d).format('YYYY-MM-DD HH:mm:ss');
        }
        tableParamenter_2_param.alarmTypeId=value?.alarmTypeId || null;          //报警类型
        tableParamenter_2_param.sourceName=value?.sourceName || null;            //信息来源
        tableParamenter_2_param.code=value?.code || null;  //报警对象

        this.setState({tableParamenter_2_param:tableParamenter_2_param});   // 确定修改

        this.getData_polymerization();
        // // code:"",        //表钢号
        // //     sourceName:"",  //信息来源
        // //     alarmTypeId:"", //报警类型
        // //     sourceId:""     //通知配置ID
        // getPageFuzzyQuerys(param).then(
        //     response => {
        //         const {data} = response.data;
        //         console.log('展示的data', data);
        //         let {tableParamenter} = this.state;
        //         tableParamenter.data = data;
        //         const {pageInfo} = response.data;
        //         let total= pageInfo.total;
        //         //   let {pageinfo} = this.state
        //         //   pageinfo.current = pageInfo.pageIndex
        //         //   pageinfo.size = pageInfo.pageLine
        //         //   console.log('展示的pageinfo', pageinfo)
        //         this.setState({
        //             loading: false,
        //             refreshTable: false,
        //             tableParamenter:tableParamenter,
        //             totalCount:total,
        //             // pageinfo
        //         });
        //         message.success('查询成功')
        //     }
        // ).catch(
        //     () => {
        //         this.setState({
        //             loading: false,
        //             refreshTable: false,
        //         });
        //         message.error('查询失败')
        //     }
        // )

    };

    render() {
        const { loading, visible } = this.state;
        const uploadButton = (
            <div>
                {/*<Icon type="plus" />*/}
                <div className="ant-upload-text">上传图片</div>
            </div>
        );
        return (

            <Layout className={'item_body_number_back tables'} style={{ backgroundColor: '#ffffff' }}>
                <div style={{display:this.state.typeSwitch?'none':null}}>
                    <Tables
                        tableParamenter = {this.state.tableParamenter}  // 表格表头、数据
                        exportTable={this.exportTable}                  // 导出时触发的方法
                        refreshTable={this.refreshTable}                // 刷新时触发的方法
                        onSelectChange={this.onSelectChange}            // 选中多选框时触发的方法
                        getPagination={this.getPagination}              // 分页器变化时触发的方法
                        improtTable={this.improtTable}                  // 导入方法
                        setTableValue={this.setTableValue}
                        loading={{
                            loading: this.state.loading,
                            refreshTable: this.state.refreshTable
                        }}
                        pagination={{
                            totalCount: this.state.totalCount, // 总条数
                            page: this.state.pageinfo.page.current,// 当前页数
                            size: this.state.pageinfo.page.size // 页面条数
                        }}
                        // loading={{
                        //     loading: this.state.loading,
                        //     refreshTable: this.state.refreshTable
                        // }}
                    >

                        <Conditions
                            conditionsParamenter={this.state.conditionsParamenter} // 条件框输入框设置
                            getConditionsValue={this.getConditionsValue} // 获取条件框输入的值
                            clearCondition={this.clearCondition} // 清除条件
                        >
                        </Conditions>
                        <div style={{marginTop:'6px'}}>
                            <span>分类聚合模式：</span>
                            <Switch checkedChildren="开启" unCheckedChildren="关闭" checked={this.state.typeSwitch}
                                    onChange={(juder)=>{this.setState({typeSwitch:juder})}}/>
                        </div>
                    </Tables>
                </div>
                {/*--------------------------------------------聚合模式--------------------------------*/}
                <div style={{display:this.state.typeSwitch?null:'none'}}>
                    <div style={{padding:'-8px 0 0 8px',background:'#fff',width: '99.5%'}}>
                        <div style={{float:'left'}}>
                            <Conditions
                                conditionsParamenter={this.state.conditionsParamenter} // 条件框输入框设置
                                getConditionsValue={this.getConditionsValue_polymerization} // 获取条件框输入的值
                                clearCondition={this.clearCondition} // 清除条件
                            />
                        </div>
                        <div style={{marginTop:'6px',marginLeft:'20px',float:'left'}}>
                            <span>分类聚合模式：</span>
                            <Switch checkedChildren="开启" unCheckedChildren="关闭" checked={this.state.typeSwitch}  onChange={(juder)=>{this.setState({typeSwitch:juder})}}/>
                        </div>
                    </div>
                    <div style={{padding:'10px',background:'#fff',width: '99.5%'}}>
                        <Table
                            bordered
                            rowKey={'id'}
                            columns={this.state.tableParamenter_1_value}
                            loading={this.state.tableParamenter_1_loading}
                            // 单选框事件
                            rowSelection={{
                                selectedRowKeys:this.state.tableParamenter_1_selectOne,// 选中值
                                onChange:(a,b)=>{
                                    this.setState({tableParamenter_1_selectOne:a,tableParamenter_1_selectData:b});
                                },
                                type:"radio",
                                //"checkbox" "radio"
                            }}
                            // 展开点击事件
                            onExpand={(juder,data)=>{
                                if(juder){
                                    //单选框
                                    var selectOne = this.state.tableParamenter_1_selectOne;
                                    selectOne=[];// 初始化
                                    selectOne.push(data.id);
                                    // 选择值
                                    var selectData = this.state.tableParamenter_1_selectData;
                                    selectData=[];
                                    selectData.push(data);
                                    this.setState({tableParamenter_1_selectOne:selectOne,tableParamenter_1_selectData:selectData});
                                    this.getData_polymerization(data);
                                }
                            }}

                            // 父子框
                            expandable={{
                                defaultExpandedRowKeys:this.state.tableParamenter_1_selectOne,
                                onExpandedRowsChange:(rows)=>{
                                },
                                // 展开样式
                                expandedRowRender:(record)=>{
                                    console.log(record);
                                    return<>
                                        <Table
                                            columns={this.state.tableParamenter_2_value}
                                            dataSource={this.state.tableParamenter_2_data}
                                            loading={this.state.tableParamenter_2_loading}
                                            pagination = {{
                                                showSizeChanger:true,
                                                showQuickJumper: true,
                                                onChange: this.getPagination_polymerization,       // 获得翻页数值
                                                total:this.state.tableParamenter_2_param.total,
                                                pageSize:this.state.tableParamenter_2_param.page.size,
                                                showTotal: (count = this.state.tableParamenter_2_param.total)=>{
                                                    return '共'+count+'条数据'
                                                },
                                                defaultCurrent: this.state.tableParamenter_2_param.page.current,
                                                pageSizeOptions: ['10']
                                            }}
                                        />
                                    </>
                                },
                                rowExpandable: (record) => record.id !== 'Not Expandable',
                            }}
                            dataSource={this.state.tableParamenter_1_data}
                        />
                    </div>
                </div>
                <div>
                    <Modal
                        title={'报警处理'}
                        style={{ top: 0 }}
                        visible={this.state.modalVisible}
                        okText="确定"
                        onOk={()=>{this.processAlarmInfo()}}           // 点击确定
                        onCancel={() =>     this.setState({modalVisible:false})}       // 点击取消
                        width={550}
                        centered
                    >
                        <div style={{height:'420px'}}>

                            <div style={{height: '40px'}}>
                                <div style={{ width: '15.5%',   float: 'left',"textAlign": "right"}}>
                                    <span style={{fontWeight: 'bolder'}}>处置类型：</span>
                                </div>
                                <div style={{ width: '60%',  float: 'left' }}>
                                    <Select style={{width: '100%'}}
                                            value={this.state.type}
                                            onChange={(text,data)=>{this.setState({type:text})}}
                                    >
                                        <Option key={'现场'}>现场</Option>
                                        <Option key={'电话'}>电话</Option>
                                        <Option key={'不处理'}>不处理</Option>
                                    </Select>
                                </div>
                            </div>
                            <div style={{  height: '140px'}}>
                                <div>
                                    <span style={{fontWeight: 'bolder'}}>处置说明</span>
                                </div>
                                <div>
                                    <TextArea style={{width: '100%'}} autoSize={{ minRows: 4, maxRows: 8 }}
                                              onChange={(text)=>{this.setState({disposalRemark:text.target.value})}}
                                    />
                                </div>
                            </div>
                            <div style={{  height: '140px'}}>
                                <div>
                                    <span style={{fontWeight: 'bolder'}}><span style={{color:'red'}}>*</span>处置照片</span>
                                </div>
                                <div>
                                    <Upload
                                        action={this.state.fileUrl}
                                        name={"file"}
                                        listType="picture-card"
                                        fileList={this.state.fileList_1}
                                        // onPreview={this.handlePreview}
                                        onChange={this.onChange_img}
                                    >
                                        {this.state.fileList_1.length >= 4 ? null : uploadButton}
                                    </Upload>

                                </div>
                            </div>

                            <div style={{width:'100%',height: '110px',float: 'left'}}>
                                <div style={{width:'100%',backgroundColor:'#ff5500'}}>

                                    <div style={{ width: '15.5%',   float: 'left',"textAlign": "right"}}>
                                        <span style={{fontWeight: 'bolder'}}><span style={{color:'red'}}>*</span>处置人：</span>
                                    </div>
                                    <div style={{ width: '60%',  float: 'left' }}>
                                        <Input style={{ width: '100%', }}
                                               status={this.state.statusText_1}
                                               placeholder={"请输入处置人"}
                                               value={this.state.disposer}
                                               onChange={(text)=> {
                                                   this.setState({disposer:text.target.value,statusText_1:''})
                                               }}
                                        />
                                    </div>
                                </div>
                                <div style={{width:'100%',float: 'left',marginTop:'5px'}}>
                                    <div style={{ width: '15.5%',   float: 'left',"textAlign": "right"}}>
                                        <span style={{fontWeight: 'bolder'}}><span style={{color:'red'}}>*</span>联系电话：</span>
                                    </div>
                                    <div style={{ width: '60%',  float: 'left' }}>
                                        <Input style={{ width: '100%', }}
                                               status={this.state.statusText_2}
                                               placeholder={"请输入联系电话"}
                                               value={this.state.tel}
                                               onChange={(text)=> {
                                                   this.setState({tel:text.target.value,statusText_2:''})
                                               }}
                                        />
                                    </div>
                                </div>
                                <div style={{width:'100%',float: 'left',marginTop:'5px'}}>
                                    <div style={{ width: '15.5%',   float: 'left',"textAlign": "right"}}>
                                        <span style={{fontWeight: 'bolder'}}>处置时间：</span>
                                    </div>
                                    <div style={{ width: '60%',  float: 'left' }}>
                                        <DatePicker
                                            style={{ width: '100%' }}
                                            // size="small"
                                            defaultValue={moment(this.state.disposalTime, 'YYYY-MM-DD HH:mm:ss')}
                                            onChange={(text)=>{
                                                text =moment(text).format('YYYY-MM-DD HH:mm:ss');
                                                this.setState({disposalTime:text})
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal>
                </div>
            </Layout>
        )
    }
}
