import React, { Component } from 'react'
import {Button, Form, Checkbox, Modal, Tag, Input, Select, Divider, Space, DatePicker, message} from "antd";
import './workOrder.less'
import FormsTwo from "../../components/common/forms/FormsTwo";

import { SearchOutlined, RollbackOutlined,EyeOutlined,PlusOutlined,BellOutlined,UserAddOutlined,StepBackwardOutlined,StepForwardOutlined } from '@ant-design/icons';
import {TYPE_NAME} from "../../components/Const";
import Table from "antd/es/table";
import {
    pageHiddenDanger,
    queryHandler,
    queryPageHandler,
    queryPageWorkOrder,
    saveHandler, urging,
    workAllocation
} from "../../services/meter";
import TableComponents from "../../components/common/tables/TableComponentsNew";
import {getDataIgnoreLevel} from "../../services/steamReport";
import moment from "moment";

const {TextArea} =Input;
const { Option } = Select;
export default class workOrder extends Component {
    state={
        // 查询=表单参数
        formParam: {
            modal: true,
            number: 3,
            labelCol: 8,
            layout: "vertical",
            parameter: [
                {variableName: 'date', str: '时间范围', rules: TYPE_NAME.RANGE_PICKER},
                {variableName: 'areaIds', str: '区域', rules: TYPE_NAME.SELECT, data:[]},
                {variableName: 'emergencyLevel', str: '紧急程度', rules: TYPE_NAME.SELECT,
                    data:[
                        {key:'all',value:'全部'},
                        {key:'high',value:'高'},
                        {key:'medium',value:'中'},
                        {key:'low',value:'低'},
                    ]
                },
                {variableName: 'state', str: '处理状态', rules: TYPE_NAME.SELECT,
                    data:[
                        {key:'待处理',value:'待处理'},
                        {key:'处理中',value:'处理中'},
                        {key:'已完成',value:'已完成'},
                        {key:'已超时',value:'已超时'}
                    ]
                },
                {variableName: 'assigneeIds ', str: '处理人', rules: TYPE_NAME.SELECT,
                    data:[
                        {key:'0',value:'未分配'},
                    ]
                },
            ]
        },
        // 查询=表单参数
        formParam_1: {
            modal: true,
            number: 1,
            labelCol: 7,
            layout: "vertical",
            parameter: [
                {variableName: 'id', str: '工单编号',disabled:true,rules: TYPE_NAME.NOT_BLANK,},
                {variableName: 'complaintContent', str: '工单内容',disabled:true, rules: TYPE_NAME.NODESCRIPTION,autoSize:{ minRows: 3, maxRows: 5 }},
                {variableName: 'assignee', str: '选择处理人', rules: TYPE_NAME.REQ_SELECT,
                    data:[{key:'0',value:'未分配'}]
                },
                {variableName: 'emergencyLevel', str: '处理优先级', rules: TYPE_NAME.REQ_SELECT,
                    data:[
                        {key:'high',value:'高'},
                        {key:'medium',value:'中'},
                        {key:'low',value:'低'},
                        ]
                },
                {variableName: 'expectedProcessTime', str: '预计完成时间', rules: TYPE_NAME.DATE_TIME_FORMAT},
                {variableName: 'processNote', str: '分配说明', rules: TYPE_NAME.NODESCRIPTION,autoSize:{ minRows: 3, maxRows: 5 }},
            ]
        },

        // 弹窗-新建联系人- 表单参数
        formParam_2: {
            modal: true,
            number: 1,
            labelCol: 7,
            layout: "horizontal",
            parameter: [
                {variableName: 'name', str: '处理人姓名', rules: TYPE_NAME.NOT_BLANK},
                {variableName: 'phone', str: '处理人电话', rules: TYPE_NAME.PHONE_NUM_NOT_NULL},
                {variableName: 'mail', str: '邮箱地址', rules: TYPE_NAME.EMAIL_NOT_NULL,},
            ]
        },

        // 表格参数
        tableParamenter: {
            manageName: "SIM卡申请管理",
            // 表格行是否可选
            rowSelection: false,
            //下拉框长度
            selectWidth: '6.5vw',
            bordered:false,
            isNotInitData:false,// 初始化禁止访问后端
            // 表头：变量和变量解释
            header: {
                id: '工单编号',
                processNote: '投诉内容',
                complainantName: '投诉人',
                area: '区域',
                state: '状态',
                emergencyLevel: '紧急程度',
                assignee: '处理人',
                createdTime: '创建时间',
                edit: '操作',
            },
            params:{
                page:1,
                size:5
            },
        },

        optionDataS:[
            {
                value: 'jack',
                label: 'Jack',
            },
            {
                value: 'lucy',
                label: 'Lucy',
            },
        ],     // 分配处理人-弹窗-处理人列表
        promptContent:'1',  // 新建处理人-弹窗-提示文字

        windowSwitch_1:false,   // 分配工单  -弹窗
        windowSwitch_2:false,   // 创建处理人-弹窗
        loading_1:false,        // 创建处理人-弹窗-加载状态
        loading_2:false,        // 分配工单-弹窗-加载状态

        initialValues_1:{workOrderId:'123'}    // 分配工单  -弹窗- 表单默认数据
    };
    setTableValue = (value,text,rowData) => {
        switch (value) {
            case 'emergencyLevel':
                return text === 'high' ? <Tag color={'#ff4d4f'}>高</Tag> :
                    text === 'medium' ? <Tag color={'#faad14'}>中</Tag> :
                        text === 'low' ? <Tag color={'#52c41a'}>低</Tag>:'';

            case 'state':
                return text === '待处理' ? <Tag color={'#1890ff'}>待处理</Tag> :
                    text === '处理中' ? <Tag color={'#faad14'}>处理中</Tag> :
                        text === '已超时' ? <Tag color={'#ff4d4f'}>已超时</Tag>:
                            text === '已完成' ? <Tag color={'#52c41a'}>已完成</Tag>:'';
            case 'createdTime':
                return text?moment(text).format('yyyy-MM-DD HH:mm:ss'):null;
            case 'edit':
                return (
                  <div>
                      <Button
                              icon={<EyeOutlined />}
                              onClick={()=>{
                                  this.props.history.push({
                                      pathname: '/workOrder/workOrderDetails',
                                      state: {rowData:rowData}
                                  })
                              }}
                      >
                          查看
                      </Button>
                      &nbsp;&nbsp;
                      {
                          rowData?.assignee ?
                              null:
                              <Button icon={<UserAddOutlined />}
                                      onClick={async ()=>{
                                          rowData.expectedProcessTime=rowData?.expectedProcessTime?moment(rowData?.expectedProcessTime):null;
                                          await this.setState({
                                              windowSwitch_1:true,
                                              initialValues_1:rowData
                                          });
                                          this.forms1.onModalClose();
                                      }}
                              >分配</Button>
                      }

                      &nbsp;&nbsp;
                      <Button type="primary" danger icon={<BellOutlined />}
                              onClick={()=>{
                                  this.urging(rowData?.id??null);    // 催办
                              }}


                      >催办</Button>
                  </div>
                );
            default:
                return text ? <div className={'table_overflow'} title={text}>{text}</div> : ''
        }
    };
    // 初始化
    componentDidMount(): void {
        this.getDataIgnoreLevel();  // 获取所有区域
        this.queryPageHandler();    // 查询所有的处理人
    }
    // 获取所有区域
    getDataIgnoreLevel=()=>{
        let user = JSON.parse(sessionStorage.getItem("user"));
        getDataIgnoreLevel({userId:user.id}).then(
            response => {
                if(response?.data?.data){
                    const {data} = response.data;
                    var formParam = this.state.formParam;
                    let selectArry=[];
                    if(data){
                        this.setState({areaInfos:data});
                        for(var i=0;i<data.length;i++){
                            selectArry.push({key:data[i].id,value:data[i].name})
                        }
                    }
                    formParam.parameter[1].data=selectArry;
                    this.setState({formParam:formParam})
                }

            }
        );
    };

    // 查询所有的处理人
    queryPageHandler=()=>{
        var param = {
            page:1,
            size:999
        };
        queryPageHandler(param).then(
            response=>{
                console.log(response);
                if(response?.data?.data){
                    var formParam = this.state.formParam;
                    var formParam1 = this.state.formParam_1;
                    formParam1.parameter[2].data=[];
                    formParam.parameter[4].data=[{key:'0',value:'未分配'}];
                    response?.data?.data.map(one=>{
                        formParam.parameter[4].data.push({key:one?.id??null, value:one.name??null,label:one.name??null});
                        formParam1.parameter[2].data.push(
                            Object.assign(one,{key:one?.id??null, value:one.name??null,label:one.name??null}));

                    });
                    this.setState({
                        formParam_1:formParam1,
                        formParam:formParam
                    })
                }
            }
        )
    };
    // 新增处理人
    saveHandler =async ()=>{
        let voHandler = await this.forms2.onFinishNotReset();

        saveHandler(voHandler).then(
            response=>{
                console.log(response);
                if(response?.data?.data){

                }else{
                    this.setState({
                        promptContent:response?.data?.responseInfo?.description ??'',
                        loading_1:false
                    })
                }
            }
        )
    };


    queryPageWorkOrder = async ()=>{
        let forms = await this.forms.onFinishNotReset();
        console.log(forms);
        var param = {
            "areaIds": forms?.areaIds?[forms?.areaIds]:null,            // 投诉人所在的区域
            "assigneeIds": forms?.assigneeIds?forms?.assigneeIds:null,  // 指派处理该工单的人员 ID
            "emergencyLevel": forms?.emergencyLevel?forms?.emergencyLevel:null,   // 投诉的紧急程度
            "page": 1,                              // 页数
            "size": 5,                             // 条数
            "endTime": null,                        // 工单的结束时间
            "startTime": null,                      // 工单的开始时间
            "state": forms?.state?forms?.state:null // 	处理状态
        };
        if(forms?.date){
            param.startTime = forms?.date ?moment(forms.date[0]._d).format('yyyy-MM-DD HH:mm:ss'):null;   // 计划开始时间
            param.endTime = forms?.date ?moment(forms.date[1]._d).format('yyyy-MM-DD HH:mm:ss'):null;     // 计划结束时间
        }
        this.table.refreshTable(param);
    };
    // 工单分配
    workAllocation = async ()=> {
        let value = await this.forms1.onFinishNotReset();
        var formParam1 = this.state.formParam_1;

        var param = {
            "workOrderId": value?.id??null,                  // 被分配的工单编号，外键关联 work_order 表
            "assignTime": new Date(),                        // 工单分配时间，默认值为当前时间
            "assigneeId": value?.assignee??null,             // 工单处理者的用户 ID，外键关联 user 表
            "priority": value?.emergencyLevel??null,         // 优先级
            "operationId": JSON.parse(sessionStorage.getItem("user")).id??null, // 操作人ID

            "expectedProcessTime": value?.expectedProcessTime??null, // 预计处理该工单的时间
            "assignmentNote": value?.processNote??null,              // 工单分配说明
            "sms": this.state?.sms??false,            // 短信通知
            "system": this.state?.system??false,      // 系统通知

            "id": null,                     // 分配记录的唯一标识，自增主键

            "phone": false,                 // 电话通知
            "transferOrder": false,         // 是否转单

        };
        workAllocation(param).then(response=>{
            this.setState({loading_2:false});
            if(response?.data?.data){
                message.info('提交成功');
                this.forms1.onModalClose();
                this.setState({windowSwitch_1:false});
            }else{
                message.info('提交失败');
            }
        });
    };

    // 催办
    urging =(workOrderIds)=>{
        if(!workOrderIds){return false;}
        var param =  new URLSearchParams();
        param.append('operationId',JSON.parse(sessionStorage.getItem("user")).id??null);
        param.append('workOrderIds',workOrderIds?[workOrderIds]:null);
        urging(param).then(response=>{
            if(response?.data?.data){
                return message.info('催办成功');
            }else{
                return message.info('催办失败');
            }
        });
    };
    render(){
        const { loading_1,loading_2 }=this.state;


        return(
            <div id={'workOrder'}>
                {/*标题*/}
                <div className={'workOrder_topSearchBox'}>
                    <h3>工单管理中心</h3>
                </div>
                {/* 搜索框 */}
                <div className={'workOrder_css_div_head_1'}>
                    <div className={'workOrder_css_div_head_1_div_frame'}>
                        <FormsTwo
                            ref={e => this.forms = e}
                            formParamenter={this.state.formParam}
                            data={this.state.essentialData}
                        />
                        <Button type="primary" icon={<SearchOutlined />} onClick={() =>{this.queryPageWorkOrder();}}>查询</Button>
                        &nbsp;&nbsp;&nbsp;
                        <Button icon={<RollbackOutlined />}  onClick={()=>{
                            this.forms.onModalClose();
                            this.table.refreshTable();
                        }}>重置</Button>

                    </div>
                </div>
                {/* 表格 */}
                <div className={'workOrder_css_div_head_2'}>
                    {/*内框*/}
                    <div className={'workOrder_css_div_head_2_div_frame'}>
                        {/*内标题*/}
                        <div className="workOrder_css_div_head_2_card-header">
                            <h3 className="workOrder_css_div_head_2_card-title">工单列表</h3>
                            <Button type="primary" icon={<PlusOutlined />} onClick={() =>{
                                this.props.history.push({
                                    pathname: '/workOrder/createWorkOrder',

                                })

                            }}>新建工单</Button>

                        </div>
                        {/*表格*/}
                        <div style={{padding:'10px'}}>
                            <TableComponents
                                paramenter={this.state.tableParamenter}
                                setData={queryPageWorkOrder}
                                setTableValue={this.setTableValue}
                                ref={e => this.table = e}
                            />
                        </div>
                    </div>
                </div>


{/*工单分配-弹窗-------*/}
                <Modal
                    title='工单分配'
                    style={{ top: 0 }}
                    visible={this.state.windowSwitch_1}
                    okText="确定"
                    // 点击确定
                    onOk={ () => {this.workAllocation();}}
                    // 点击取消
                    onCancel={() => {this.setState({windowSwitch_1:false});}}
                    width={500}
                    centered
                    footer={[
                        <Button key="back" onClick={()=>{
                            this.forms1.onModalClose();
                            this.setState({windowSwitch_1:false});
                        }}
                        >返回</Button>,
                        <Button key="submit" type="primary"
                                loading={loading_2}
                                onClick={()=>{
                                    this.setState({loading_2:true});
                                    this.workAllocation();
                                }}
                        >提交</Button>
                    ]}

                >
                    <div style={{width:'100%',height:'100%'}}>
                        <FormsTwo
                            ref={e => this.forms1 = e}
                            formParamenter={this.state.formParam_1}
                            data={this.state.initialValues_1}
                        />
                        <Checkbox onChange={(v)=>{this.setState({system:v.target.checked});}}>系统通知</Checkbox>
                        <Checkbox onChange={(v)=>{this.setState({sms:v.target.checked});}}>短信通知</Checkbox>
                    </div>
                </Modal>
{/*新建处理人--------*/}
                <Modal
                    title='新建处理人'
                    style={{ top: 0 }}
                    visible={this.state.windowSwitch_2}
                    okText="确定"
                    onOk={() => this.setState({windowSwitch_2:false})}           // 点击确定
                    onCancel={() => this.setState({windowSwitch_2:false})}       // 点击取消
                    width={480}
                    centered
                    footer={[
                        <Button key="back" onClick={()=>{this.setState({windowSwitch_2:false});}}
                        >返回</Button>,
                        <Button key="submit" type="primary"
                            loading={loading_1}
                            onClick={()=>{
                                this.setState({loading_1:true});
                                this.saveHandler();
                            }}
                        >提交</Button>
                    ]}
                >
                    <FormsTwo
                        ref={e => this.forms2 = e}
                        formParamenter={this.state.formParam_2}
                        data={this.state.essentialData}
                    />

                    <div style={{ textAlign: 'center'}}>
                        <span style={{color:'red'}}>{this.state.promptContent}</span>
                    </div>
                </Modal>



            </div>
        )
    }
}
