import ajax from './ajax'

// 查询数据推送记录
export const queryPushRecord = data => ajax('/pushData/pushRecord/queryPushRecord', data, 'POST');
// 查询永州数据推送记录
export const getDtoDataPage = data => ajax('/yongzhou/dto-data/page', data, 'GET');
// 查询永州数据推送记录
export const getDtoDataPage1 = data => ajax('/yongzhou/add-meters', data, 'GET');


// 查询数据推送详情
export const queryPushDetails = data => ajax('/pushData/pushRecord/queryPushDetails', data, 'POST');

// 查询数据推送配置
export const queryPushConfigs = data => ajax('/pushData/pushConfig/queryPushConfigs', data, 'POST');

// 查询数据推送配置详情
export const queryDevs = data => ajax('/pushData/pushConfig/queryDevs', data, 'POST');


// 创建数据推送配置
export const savePushConfig = data => ajax('/pushData/pushConfig/savePushConfig', data, 'POST');
// 修改数据推送配置
export const updatePushConfig = data => ajax('/pushData/pushConfig/updatePushConfig', data, 'POST');
// 删除数据推送配置
export const delPushConfig = data => ajax('/pushData/pushConfig/delPushConfig', data, 'POST');


// 查询区域下未安装的表
export const queryInstallMeters = data => ajax('/web/meterCtrl/queryInstallMeters', data, 'POST');

// 创建数据推送配置下的设备
export const savePushDevs = data => ajax('/pushData/pushConfig/savePushDevs', data, 'POST');
// 修改数据推送配置下的设备
export const updatePushDevs = data => ajax('/pushData/pushConfig/updatePushDevs', data, 'POST');
// 删除数据推送配置下的设备
export const delPushDevs = data => ajax('/pushData/pushConfig/delPushDevs', data, 'POST');






























