import React, { Component } from 'react'
import { NavLink } from "react-router-dom"
import {
    Layout,
    DatePicker,
    Select,
    Input,
    Radio,
    Space, Button, Modal, Pagination, Table, Divider, message, Form, Checkbox
} from 'antd'
import {
    getExceptionByDevType,
    queryInspectionContent, removeInspectionContent, saveBatchInspectionContent
} from "../../../services/meter";
import moment from "moment";
import {getAllClassAndTemp} from "../../../services/equipment";
import { SearchOutlined ,CloseOutlined,PlusOutlined} from '@ant-design/icons';
import {pageValveInfo} from "../../../services/qrCode";
import './inspection.less'
const { RangePicker } = DatePicker;
const { TextArea } = Input;
const { Option } = Select;
// 巡检内容配置
export default class contentConfiguration extends Component {

    state = {

        optionDataS_1:[],   // 设备列表-1-设备编号列表
        optionDataS_2:[],   // 弹窗-内容类型-设列表
        isModalOpen_1:false,// 弹窗-开关-1
        isModalOpen_2:false,// 弹窗-开关-2

        plainOptions:[],    // 复选框默认数据


        initialValues_1:{}, // 弹窗-表单-巡检内容

        optionDataS_2_data:{},// 问题所属类型下拉框，选择数据



        radioData:null,     // 左侧单选框-所选数据
        input_value_1:null, // 输入框-1
        input_value_2:null, // 输入框-2
        // 巡检内容-标题
        tableData_1_head:[
            {title: '', dataIndex: 'content', key: 'content'},
            {title: '', dataIndex: 'edit',width:'200px', align:'center',}
        ],
        // 巡检内容-内容
        tableData_1_data:[],
        tableData_1_param:{
            "content": null,  // 巡检内容
            "devType": null,  // 设备类型
            "total":0,        // 总数
            "page": 1,        // 页数
            "size": 10        // 条数
        },
        tableData_1_loading:false,// 巡检内容-加载

        // 巡检内容-标题
        tableData_2_head:[
            {title: '', dataIndex: 'content', key: 'content'},
            {title: '', dataIndex: 'edit',width:'200px', align:'center',}
        ],
        select_tableData_2_data:[],// 选择的巡检内容
        tableData_2_data:[],
        tableData_2_param:{
            "content": null,  // 巡检内容
            "devType": null,  // 设备类型
            "total":0,        // 总数
            "page": 1,        // 页数
            "size": 10        // 条数
        },
    };

    componentWillMount() {
        this.getAllClassAndTemp(); // 查询设备类型
    };






    // 查询设备类型
    getAllClassAndTemp=()=>{
        // 查询设备类型
        getAllClassAndTemp().then(e=>{
            var radioData = this.state.radioData;
            var tableData1Param = this.state.tableData_1_param;
            var datas=[];
            e?.data?.data.map(one=>{datas.push({key:one.devClassId,value:one.devClassId,label:one.devClassName})});
            // 初始化操作
            if(datas.length!==0){
                // 默认首选第一个
                radioData=datas[0].value;
                // 默认查询的参数
                tableData1Param.devType=datas[0].value;
                this.queryInspectionContent(null,datas[0].value);// 右侧表条件查询巡检内容；巡检内容、设备类型
                this.getExceptionByDevType(datas[0].value);              // 弹窗-根据设备类型查询异常信息

            }
            this.setState({optionDataS_1:datas,radioData:radioData})
        });
    };

    // 条件查询巡检内容；巡检内容、设备类型
    queryInspectionContent=(content,devType,page,size)=>{
        var param = {
            "content": content??null,   // 巡检内容
            "devType": devType??null,   // 设备类型
            "page": page??1,            // 页数
            "size": size??10            // 条数
        };
        queryInspectionContent(param).then(
            response=>{

                var tableData1Param = this.state.tableData_1_param;
                tableData1Param.total = response?.data.pageInfo?.total;
                tableData1Param.page = response?.data.pageInfo?.pageLine;
                this.setState({
                    tableData_1_data:response?.data?.data??[],
                    tableData_1_param:tableData1Param
                });
            }
        )
    };

    // 条件查询巡检内容；巡检内容、设备类型
    queryInspectionContent_1=(content,devType,page,size)=>{
        var param = {
            "content": content??null,   // 巡检内容
            "devType": devType??null,   // 设备类型
            "page": page??1,            // 页数
            "size": size??10            // 条数
        };
        queryInspectionContent(param).then(
            response=>{
                if(!response?.data?.data){message.info('查询失败');}
                var tableData2Param = this.state.tableData_2_param;
                tableData2Param.total = response?.data.pageInfo?.total;
                tableData2Param.page = response?.data.pageInfo?.pageLine;
                this.setState({
                    tableData_2_data:response?.data?.data??[],
                    tableData_2_param:tableData2Param
                });
                // 弹窗所有内容数据的挑选当前类型选的值
                this.plainOptions();
            }
        )
    };

    // 弹窗所有内容数据的挑选当前类型选的值
    plainOptions=()=>{
        var tableData1Data = this.state.tableData_1_data;// 所选类型获取的数据
        var tableData2Data = this.state.tableData_2_data;// 查询所有的表格
        var plainOptions1 =[];     // 复选框

        tableData1Data.map(one=>{
            plainOptions1.push(one.id);
            tableData2Data.map(two=>{
                if(two?.devType){
                    if(one.devType.toString().indexOf(two?.devType.toString())!==-1){two.disabled=true;}
                }
            })
        });
        this.setState({plainOptions:plainOptions1});
    };

    // 根据设备类型查询异常信息
    getExceptionByDevType=(devType)=>{
        getExceptionByDevType({devType:devType}).then(
            response=>{
                this.setState({optionDataS_2:response?.data?.data??[]})
            }
        )
    };

    // 批量添加巡检内容
    saveBatchInspectionContent=(poInspectionContents=[])=>{
        if(poInspectionContents.length===0)return false;

        saveBatchInspectionContent(poInspectionContents).then(
            response=>{
                if(!response?.data?.data){
                    message.info('添加失败');
                    return false;
                }
                // 查询右侧数据
                this.queryInspectionContent(
                    null,
                    this.state.tableData_1_param.devType,
                    this.state.tableData_1_param.page,
                    null
                );
                // 条件查询巡检内容
                this.queryInspectionContent_1(null,null,null,null);
            }
        )
    };


    select_tableData_2_data=(plainOptions=[])=>{
        var plainOptions1 = this.state.plainOptions;                    // 已选择的值
        var tableData2Data = this.state.tableData_2_data;               // 弹窗表格内容
        var selectTableData2Data = this.state.select_tableData_2_data;  // 获取格外的选择的详情数据
        // 已选择的值小于或等于，视为新增
        console.log(plainOptions.length);
        console.log(plainOptions);
        if(plainOptions1.length<plainOptions.length) {
            tableData2Data.map(one=>{
                if(plainOptions[plainOptions.length-1]===one?.id){
                    var param = JSON.parse(JSON.stringify(one));
                    param.devType=this.state.tableData_1_param.devType;
                    selectTableData2Data.push(param);
                }
            });
            // 已选择的值大于或等于，视为减
        } else if(plainOptions1.length>=plainOptions.length){
            var param = [];
            // 已选择的值,和目前选择的值做对比，把排除的值取出
            plainOptions1.map(one=>{
                if(!plainOptions.find(two=>{if(two===one){return true}})){
                    param.push(one);
                }
            });
            // 删除
            for(var i=0;i<selectTableData2Data.length;i++){
                if(selectTableData2Data[i].id===param[0]){
                    selectTableData2Data.splice(i,1);
                    break;
                }
            }
        }
        this.setState({
            plainOptions:plainOptions,
            select_tableData_2_data:selectTableData2Data
        });
    };

    // 弹窗-删除巡检内容
    removeInspectionContent=(id)=>{
        if(!id){message.info('当前不能操作');return false;}
        removeInspectionContent({id}).then(
            response=>{
                if(response?.data?.data){

                    // 查询右侧数据
                    this.queryInspectionContent(
                        null,
                        this.state.tableData_1_param.devType,
                        this.state.tableData_1_param.page,
                        null
                    );
                    // 刷新弹窗数据
                    this.queryInspectionContent_1(null,null,1,null);
                }
            }
        )

    };
    // 删除巡检内容
    removeInspectionContent_1=(id)=>{
        if(!id){message.info('当前不能操作');return false;}
        removeInspectionContent({id}).then(
            response=>{
                if(response?.data?.data){

                    // 查询右侧数据
                    this.queryInspectionContent(
                        null,
                        this.state.tableData_1_param.devType,
                        this.state.tableData_1_param.page,
                        null
                    );
                }
            }
        )

    };
    render() {
        const { isModalOpen_1, isModalOpen_2, optionDataS_1,optionDataS_2,optionDataS_2_data,
            radioData,input_value_1,input_value_2,initialValues_1,
            tableData_1_param,tableData_1_head,tableData_1_data,tableData_1_loading,
            tableData_2_data,tableData_2_param,select_tableData_2_data
        } = this.state;

        return (
            <Layout className={'item_body_number_back tables'} style={{backgroundColor: '#ffffff'}}>
                <div id={'contentConfiguration'}  style={{width:'100%',height:'100%',margin:'0.5% 1% 0.5% 1%'}}>
{/*设备类型--左侧--------*/}
                    <div style={{width:'30%',height:'98%',margin:'1% 2%',float:'left'}}>
                        <div style={{width:'100%',height:'50px'}}>
                            <span style={{fontWeight:'bold',fontSize:'18px'}}>设备类型</span>
                        </div>
                        {/*表格*/}
                        <div style={{width:'100%',height:'90%',overflowY: 'auto',
                            border: '1px solid black', borderColor: '#ccc',
                            padding:'5% 5% 5% 10%'
                        }}>
                            <Radio.Group onChange={(a)=>{
                                tableData_1_param.devType=a.target.value;
                                tableData_1_param.page=1;
                                this.setState({radioData:a.target.value,tableData_1_param:tableData_1_param});
                                // 查询右侧数据
                                this.queryInspectionContent(null,tableData_1_param.devType,tableData_1_param.page,null);
                                // 查询弹窗问题所属类型列表
                                this.getExceptionByDevType(tableData_1_param.devType);
                            }} value={radioData}>
                                <Space direction="vertical">
                                    {optionDataS_1.map(one=>{
                                        return <Radio value={one.value}>{one.label}</Radio>
                                    })}
                                </Space>
                            </Radio.Group>
                        </div>
                    </div>
{/*巡检内容--右侧--------*/}
                    <div style={{width:'60%',height:'98%',margin:'1%',float:'left'}}>
                        <div style={{width:'100%',height:'50px'}}>
                            <span style={{fontWeight:'bold',fontSize:'18px',float:'left'}}>巡检内容</span>
                            <Button type="primary" style={{float:'right'}}
                                    onClick={()=>{
                                        this.queryInspectionContent_1(null,null,null,null); // 条件查询巡检内容
                                        this.setState({isModalOpen_1:true});
                                    }}
                            >添加</Button>
                        </div>
                        {/*表格*/}
                        <div style={{width:'100%',height:'90%',overflowY: 'auto',
                            border: '1px solid black', borderColor: '#ccc',
                            padding:'5px 10px 10px 30px'
                        }}>


                            <div  style={{width:'100%',height:'92%',padding:'1%'}}>
                                {/*标题*/}
                                <div  style={{width:'100%',height:'40px',backgroundColor:'#f7f7f7',padding:'0.5%'}}>
                                    <div style={{width:'89%',height:'100%',borderRight: '#cccccc 1px ridge',
                                        fontSize: '18px',
                                        float: 'left'
                                    }}>
                                        <span>问题所属类型</span>
                                    </div>
                                </div>
                                {/*内容*/}
                                <div  style={{width:'100%',height:'92%',padding:'1%',overflowY: 'auto'}}>
                                    {
                                        tableData_1_data.map(one=>{
                                            let devName='';
                                            // 获取中文名
                                            optionDataS_1.map(two=>{if(two.value===one.devType) {devName=two.label;}});
                                            return<div style={{width:'100%',height:'50px'}}>
                                                {/*文字*/}
                                                <div style={{width:'90%',height:'100%',float:'left',paddingTop:'2%'}}>
                                                    <span style={{fontSize:'16px'}}>{one[tableData_1_head[0].dataIndex]}</span>
                                                </div>
                                                {/*<div style={{width:'30%',height:'100%',float:'left',paddingTop:'2%',paddingLeft:'1%'}}>*/}
                                                {/*    <span style={{fontSize:'16px'}}>{one.exception}</span>*/}
                                                {/*</div>*/}
                                                {/*按钮*/}
                                                <div style={{width:'10%',height:'100%',float:'left',paddingTop:'2.2%'}}>
                                                    <CloseOutlined style={{ cursor:'pointer', fontSize: '16px'}}
                                                                   onClick={()=>{this.removeInspectionContent_1(one.id);}}
                                                    />
                                                </div>
                                                <Divider style={{ margin: '8px 0' }} />
                                            </div>
                                        })
                                    }
                                </div>
                            </div>
                            {/*分页*/}
                            <div style={{width:'100%',height:'8%'}}>
                                <Pagination
                                    position={'right'}
                                    style={{marginTop: '10px',float: 'right',fontSize: '15px'}}
                                    total={tableData_1_param.total}         // 总条数
                                    showTotal={(total, range) => `当前第${range[0]}-${range[1]}条 / 共${tableData_1_param.total??0}条` }
                                    current={tableData_1_param.page}        // 当前页数
                                    defaultsize={tableData_1_param.size}    // 默认每页条数
                                    defaultPageSize={tableData_1_param.size}
                                    showQuickJumper
                                    pageSizeOptions={[10]}
                                    showSizeChanger
                                    onChange={(a,b)=>{
                                        tableData_1_param.page=a;
                                        this.queryInspectionContent(null,tableData_1_param.devType,a,null); // 条件查询巡检内容
                                    }} // 状态改变的回调
                                />
                            </div>

                        </div>
                    </div>
{/*弹窗-1-关系绑定------------*/}
                    <Modal
                        title={'添加'}
                        okText="保存"
                        open={isModalOpen_1}
                        onOk={async ()=>{
                            this.setState({isModalOpen_1:false,plainOptions:[]});

                            Modal.confirm({
                                title: `是否添加并绑定?`,
                                onOk: () => {
                                    // 批量添加巡检内容
                                    this.saveBatchInspectionContent(select_tableData_2_data);
                                }
                            });
                        }}
                        onCancel={()=>{this.setState({isModalOpen_1:false,plainOptions:[]});}}
                        width={700}
                        centered
                    >
                        <div style={{width:'100%',height:'400px'}}>
                            <div style={{width:'100%',height:'10%'}}>
                                <Input placeholder=''
                                       value={input_value_1}
                                       style={{width:'220px'}}
                                       prefix={<SearchOutlined style={{color: 'rgba(0,0,0,.25)'}}/>}
                                       onChange={(title)=>{
                                           this.setState({input_value_1:title?.target.value})
                                       }}
                                /> &nbsp;
                                <Button type="primary"
                                        onClick={()=>{
                                            this.queryInspectionContent_1(input_value_1,null,null,null); // 条件查询巡检内容
                                        }}
                                >查询</Button>
                                <Button type="primary"
                                        style={{float:'right'}}
                                        onClick={()=>{
                                            this.setState({isModalOpen_2:true});
                                        }}
                                >添加</Button>
                            </div>
                            <div style={{width:'100%',height:'90%'}}>

                                {/*内容*/}
                                <div  style={{width:'100%',height:'88%',padding:'1%',overflowY: 'auto'}}>
                                    {/*标题*/}
                                    <div  style={{width:'100%',height:'12%',backgroundColor:'#f7f7f7',padding:'1%',overflowY: 'auto'}}>
                                        <div style={{width:'4%',height:'100%',borderRight: '#cccccc 1px ridge',float: 'left'}}
                                             className={'table_font_css'}
                                        />
                                        <div style={{fontSize: '15px',width:'50%',height:'100%',borderRight: '#cccccc 1px ridge',paddingLeft:'1%',float: 'left'}} className={'table_font_css'}>
                                            <span>问题所属类型</span>
                                        </div>
                                        <div style={{fontSize: '15px',width:'20%',height:'100%',paddingLeft:'1%',float: 'left'}} className={'table_font_css'}>
                                            <span>设备类型</span>
                                        </div>
                                    </div>
                                    <div  style={{width:'100%',height:'88%',padding:'1%',overflowY: 'auto'}}>
                                        <Checkbox.Group
                                            key={this.state.plainOptions}
                                            defaultValue={this.state.plainOptions} // 多选框的值
                                            style={{ width: '100%' }}
                                            //多选框点击事件
                                            onChange={(value,a)=>{
                                                this.select_tableData_2_data(value);// 获取选取的值详情
                                            }}
                                        >
                                            {
                                                tableData_2_data.map(one=>{
                                                    let devName='';
                                                    // 获取中文名
                                                    optionDataS_1.map(two=>{if(two.value===one.devType) {devName=two.label;}});
                                                    return <div style={{width:'100%',height:'50px'}}>
                                                        {/*复选框*/}
                                                        <div style={{width:'5%',height:'100%',float:'left',paddingTop:'3%'}}>
                                                            <Checkbox disabled={one.disabled} key={one.id} value={one.id}/>
                                                        </div>
                                                        {/*内容*/}
                                                        <div style={{width:'50%',height:'100%',float:'left',paddingTop:'2.5%', textOverflow: 'ellipsis'}}>
                                                            <span style={{fontSize:'16px'}}>{one[tableData_1_head[0].dataIndex]}</span>
                                                        </div>
                                                        {/*类型*/}
                                                        <div style={{width:'30%',height:'100%',float:'left',paddingTop:'2.5%'}}>
                                                            <span style={{fontSize:'16px'}}>{devName}</span>
                                                        </div>
                                                        {/*功能-删除*/}
                                                        <div style={{width:'10%',height:'100%',float:'right',paddingTop:'2.2%'}}>
                                                            <CloseOutlined style={{ cursor:'pointer', fontSize: '16px' }}
                                                                           onClick={()=>{
                                                                               this.removeInspectionContent(one.id);
                                                                           }}
                                                            />
                                                            {/*{one.disabled?*/}
                                                            {/*    <CloseOutlined style={{ cursor:'pointer', fontSize: '16px' }}*/}
                                                            {/*                   onClick={()=>{*/}
                                                            {/*                       this.removeInspectionContent(one.id);*/}
                                                            {/*                   }}*/}
                                                            {/*    />*/}
                                                            {/*    :*/}
                                                            {/*    null*/}
                                                            {/*}*/}

                                                        </div>
                                                        {/*虚线*/}
                                                        <Divider style={{ margin: '8px 0' }} />
                                                    </div>
                                                })
                                            }
                                        </Checkbox.Group>
                                    </div>
                                </div>
                                {/*分页*/}
                                <div style={{width:'100%',height:'8%'}}>
                                    <Pagination
                                        position={'right'}
                                        style={{marginTop: '10px',float: 'right',fontSize: '15px'}}
                                        total={tableData_2_param.total} // 总条数
                                        showTotal={(total, range) => `当前第${range[0]}-${range[1]}条 / 共${tableData_2_param.total??0}条` }
                                        current={tableData_2_param.page} // 当前页数
                                        defaultsize={tableData_2_param.size} // 默认每页条数
                                        defaultPageSize={tableData_2_param.size}
                                        showQuickJumper
                                        pageSizeOptions={[10]}
                                        showSizeChanger
                                        onChange={(a,b)=>{
                                            tableData_2_param.page=a;
                                            this.queryInspectionContent_1(null,null,a,null); // 条件查询巡检内容
                                        }} // 状态改变的回调
                                    />
                                </div>
                            </div>
                        </div>
{/*弹窗-2添加巡检内容----------------*/}
                        <Modal
                            title={'添加巡检内容'}
                            okText="保存"
                            open={isModalOpen_2}
                            onOk={ async ()=>{
                                var data = await this.form2.validateFields();
                                if(!data)return false;
                                console.log(data);
                                optionDataS_2.map(one=>{
                                    if(one.id===data.typeName){
                                        data.typeName=one.typeName;
                                    }
                                });
                                var param = {
                                    "content": data?.typeName??null,        // 巡检内容
                                    "devType": tableData_1_param?.devType,  // 设备类型
                                    "exception": data?.exception??null,     // 异常信息
                                    "id": null,         // id
                                    "createTime": null, // 创建时间
                                    "updateTime": null  // 修改时间
                                };
                                Modal.confirm({
                                    title: `是否添加并绑定?`,
                                    onOk: () => {
                                        // 批量添加巡检内容
                                        this.saveBatchInspectionContent([param]);

                                    }
                                });


                                this.setState({isModalOpen_2:false});
                            }}
                            onCancel={()=>{this.setState({isModalOpen_2:false});}}
                            width={550}
                            centered
                        >
                            <div style={{width:'100%'}}>
                                <div style={{paddingLeft:'30px'}}>
                                    <Form
                                        ref={e => this.form2 = e}
                                        labelCol={{ span: 8 }}
                                        wrapperCol={{ span: 14 }}
                                        layout="horizontal"
                                        initialValues={initialValues_1}
                                        style={{ maxWidth: 550 }}
                                        onFinish={(value)=>{}}
                                        onFinishFailed={(value)=>{}}

                                    >
                                        {/*巡检内容*/}
                                        {/*<Form.Item name="exception" label="设备类型" rules={[{ required: true }]}>*/}
                                        {/*    <Input placeholder={'手输编号'}/>*/}
                                        {/*</Form.Item>*/}
                                        {/*问题所属类型*/}
                                        <Form.Item label="问题所属类型" name={'typeName'} rules={[{ required: true }]}>
                                            <Select
                                                style={{ width: 250 }}
                                                fieldNames={{ label: 'typeName', value: 'id', key:'id' }}
                                                dropdownRender={(menu) => (
                                                    <>
                                                        {menu}
                                                        <Divider style={{ margin: '8px 0' }} />
                                                        <Space style={{ padding: '0 8px 4px' }}>
                                                            <Input
                                                                placeholder="添加本次新类型"
                                                                value={this.state.InputName}
                                                                onChange={(a)=>{

                                                                    if(a?.target.value){
                                                                        this.setState({
                                                                            InputName:a?.target.value,
                                                                            buttonDisabled:false
                                                                        });
                                                                    }else{
                                                                        this.setState({
                                                                            InputName:a?.target.value,
                                                                            buttonDisabled:true
                                                                        });
                                                                    }
                                                                }}
                                                                onKeyDown={(e) => e.stopPropagation()}
                                                            />
                                                            <Button icon={<PlusOutlined />}
                                                                    disabled={this.state.buttonDisabled??true}
                                                                    type="primary"
                                                                    onClick={()=>{
                                                                        this.form2.setFieldsValue({['typeName']:this.state.InputName});
                                                                    }}>添加</Button>
                                                        </Space>
                                                    </>
                                                )}
                                                options={optionDataS_2}
                                            />
                                        </Form.Item>
                                    </Form>
                                </div>
                            </div>

                        </Modal>
                    </Modal>
                </div>
            </Layout>
        )
    }
}
