import React, { Component } from 'react'
import { NavLink } from "react-router-dom"
import { Layout, Modal, Radio, message, Tag } from 'antd'
import {FORM_ITEM, TYPE_NAME} from '../../components/Const'

import TableComponents from '../../components/common/tables/TableComponents'
import {getDtoDataPage, queryPushRecord} from '../../services/pushData'
import moment from "moment";
export default class YongZhouPushRecord extends Component {

    state = {
		// 表格参数
		tableParamenter: {
			manageName: "永州数据推送管理系统",
			// 是否绑定左侧区域
			bindArea: false,
			// 表格行是否可选
            rowSelection: false,
			arr:[12],
			isNotInitData:true,// 是否停止默认获取数据
			// 获取表格参数数据
			localStorageName: 'YongZhouPushRecord',
			//下拉框长度
			selectWidth: '7.5vw',
			// 表头：变量和变量解释
			fileName: '数据推送记录',
			header: {
				// id: '推送编号',
				// pushName: '推送名',
				// pushObject: '推送对象',
				// pushInterval: '推送间隔',
				// url: '推送地址',
				// createTime: '创建时间',
				// lastPushTime: '最后一推送时间',
				// lastSuccess: '最后一次成功数',
				// lastFails: '最后一次失败数',
				// pushSums: '推送总数'
			//	----------------------
				meterNo: '表具条码',
				pressure: '压力',
				price: '价格',
				remainderGasAmount: '余额',
				signalIntensity: '信号强度',
				state: '状态',
				date:'创建日期',
				exectime:'推送时间',
				sumUsed: '标况累计用气量',
				sumUsed2: '工况累计用气量',
				temperature: '温度',
				isBatteryBad: '电池是否低电',	 // 1-低电，0-正常
				isFlowException: '是否异常流量',	 // 1-异常，0-正常
				isRevealGas: '是否有燃气泄漏故障', // 1-泄漏，0-正常
				isValveClose: '阀门状态',		 // 1-阀关，0-阀开
			},

			// 条件选择器参数
			conditionsParamenter: {
				// 条件
				parameter: [
					{
						type: TYPE_NAME.NAME,
						variableName: 'meterNo',
						str: '表具条码',
						initData: JSON.parse(localStorage.getItem('YongZhouPushRecord'))?.pushName
					},
					{
						type: FORM_ITEM.RANGE_PICKER,
						variableName: 'dateTime',
						str: '推送时间',
						initData: JSON.parse(localStorage.getItem('YongZhouPushRecord'))?.businessCooperationNum
					},
				],

				// 条件查询
				onchange:(params)=>{
					this.onchangeMethod(params);
				},

				// 分页查询
				pageChange:(params)=>{
					this.onchangeMethod(params);
				}

			},
			params:{
				page: 1,
				size: 12
			}
		},
	};

    componentDidMount(): void {
    	this.table.clearConditionOne();
		this.onchangeMethod({page:1,size:12});
	}


	onchangeMethod= (params)=>{
		console.log(params);
		var param =  new URLSearchParams();

		param.append('page',params?.page??1);
		param.append('size',params?.size??12);
		if(params?.meterNo){
			param.append('meterNo',params.meterNo??null);
		}
		console.log(params);
		console.log(params);
		if(params?.dateTime){
			// param.append('startTime',params?.dateTime[0]?._d);
			// param.append('endTime',params?.dateTime[1]?._d);
			param.append('startTime',moment(params?.dateTime[0]?._d).format('YYYY-MM-DD'));
			param.append('endTime',moment(params?.dateTime[1]?._d).format('YYYY-MM-DD'));
		}
		getDtoDataPage(param).then(
			response=>{
				if(response?.data?.data){
					this.table.setTableData('data',response?.data?.data.records);
					this.table.setTableData('totalCount',response?.data?.data.total);
				}

			}
		)
	};



	setTableValue = (value,text,rowData) => {
		// isBatteryBad: '电池是否低电',	 // 1-低电，0-正常
		// isFlowException: '是否异常流量',	 // 1-异常，0-正常
		// isRevealGas: '是否有燃气泄漏故障', // 1-泄漏，0-正常
		// isValveClose: '阀门状态',		 // 1-阀关，0-阀开

		switch (value) {
			case 'isBatteryBad':
				var title1=text?text.toString().indexOf('0')!==-1?'正常':'低电':"-";
				return <>{<div className={'table_overflow'} title={title1}>{title1}</div>}</>;
			case 'isFlowException':
				var title2=text?text.toString().indexOf('0')!==-1?'正常':'异常':"-";
				return <>{<div className={'table_overflow'} title={title2}>{title2}</div>}</>;
			case 'isRevealGas':
				var title3=text?text.toString().indexOf('0')!==-1?'正常':'泄漏':"-";
				return <>{<div className={'table_overflow'} title={title3}>{title3}</div>}</>;
			case 'isValveClose':
				var title4=text?text.toString().indexOf('0')!==-1?'阀开':'阀关':"-";
				return <>{<div className={'table_overflow'} title={title4}>{title4}</div>}</>;



			case 'pushName':
				return (
					<NavLink to={{pathname: "/pushData/PushRecordDetails",state: {
						id: rowData.id,
						pushName: rowData.pushName,
						pushObject: rowData.pushObject,
						pushSums: rowData.pushSums,
						url: rowData.url,
						pushInterval: rowData.pushInterval,
						lastPushTime: rowData.lastPushTime,
						createTime: rowData.createTime,
					}}}>
						<div className={'table_overflow'} title={text}>
							<u>{text}</u>
						</div>
					</NavLink>
				);
			default:
				return text ? <div className={'table_overflow'} title={text}>{text}</div> : ''
		}
	};

    render() {

        return (
            <Layout className={'item_body_number_back tables'} style={{backgroundColor: '#ffffff'}}>
				<TableComponents
					paramenter={this.state.tableParamenter}
					setData={getDtoDataPage}
					setTableValue={this.setTableValue}
					ref={e => this.table = e}
				>
				</TableComponents>
            </Layout>
        )
    }
}
