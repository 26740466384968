import React, { Component } from 'react'
import {Layout, message} from "antd";
import './dataCenter_v.01.less'
import { FireOutlined, ClockCircleOutlined, CheckCircleOutlined,ExclamationCircleOutlined} from '@ant-design/icons';
import Table from "antd/es/table";
import {getInstallationLedgerInfoInCondition} from "../../services/account";
import TableComponents from "../../components/common/tables/TableComponentsNew";
import * as echarts from "echarts";
import {getInfoByAreaIds} from "../../services/customer";
import {getDateByUserId} from "../../services/admin";
import {queryPageWorkOrder} from "../../services/meter";
export default class dataCenter extends Component {
    state={


        // 表格参数
        tableParamenter: {
            manageName: "SIM卡申请管理",
            // 表格行是否可选
            rowSelection: false,
            //下拉框长度
            selectWidth: '6.5vw',
            bordered:false,
            isNotInitData:false,// 初始化禁止访问后端
            // 表头：变量和变量解释
            header: {
                id: '工单编号',
                processNote: '投诉内容',
                complainantName: '投诉人',
                area: '区域',
                state: '状态',
                emergencyLevel: '紧急程度',
                assignee: '处理人',
                createdTime: '创建时间',
                edit: '操作',
            },
            params:{
                page:1,
                size:5
            },
        },
    };
    componentDidMount(): void {
        this.initialization_valueMap();
    };
    // 初始化配置地图
    initialization_valueMap(){
        var _this = this;
        if (echarts.getInstanceByDom(document.getElementById('deviceModelMapId'))) {
            echarts.dispose(document.getElementById('deviceModelMapId'));
        }
        var chartDom = echarts.init(document.getElementById('deviceModelMapId'));
        // var xy=[111.63101871462548,26.214717170445535];    // 默认坐标
        chartDom.setOption(
            {
                tooltip: {
                    show:true,
                    triggerOn: 'click',
                    trigger: 'item',
                    borderColor: 'rgba(0,0,0,0)',
                    backgroundColor: 'rgba(0,0,0,0)',
                    padding:[0,0],
                    borderWidth: 0,
                    formatter: function (params) {
                        // 自定义格式化内容

                        return `
                <div style="width: 500px; height: 290px;background-color: #00b6ec; border: 1px #cccccc solid;">
                    <div style="width: 500px; height: 120px;background-color: #629d3b;">
                        <div style="width: 500px; height: 40px;">
                            <div style="color: #fff;font-size: 16px;font-weight: bold; padding: 10px 0 0 50px">阀门井智慧终端</div>
                        </div>
                        <div style="width: 500px; height: 100px;">
                            <div style="color: #fff;font-size: 14px;padding-left: 100px">设备编号: `+ params.data.devId +`</div>
                            <div style="color: #fff;font-size: 14px;padding-left: 100px">设备名称: `+ params.data.name +`</div>
                            <div style="color: #fff;font-size: 14px;padding-left: 100px">安装位置: `+ params.data.installPosition +`</div>
                        </div>
                    </div>
                    <div style="width: 500px; height: 170px;background-color: #fff;">
                        <div style="width: 60%; height: 100%; padding: 10px 0 0 60px;float: left">
                            <div style="height: 40px;font-size: 14px;color:#000">通讯时间: `+ params.data.systemTime +`</div>
                            <div style="height: 40px;font-size: 14px;color:#000">甲烷浓度: `+ params.data.data_1 +`</div>
                            <div style="height: 40px;font-size: 14px;color:#000">阀井压力: `+ params.data.data_2 +`</div>
                            <div style="height: 40px;font-size: 14px;color:#000">阀井温度: `+ params.data.data_3 +`</div>
                        </div>
                        <div style="width: 40%; height: 100%; padding: 10px;float: left">
                            <div style="height: 40px;"/>
                            <div style="height: 40px;font-size: 14px;color:#000">异动: `+ params.data.data_4 +`</div>
                            <div style="height: 40px;font-size: 14px;color:#000">液位: `+ params.data.data_5 +`</div>
                            <div style="height: 40px;font-size: 14px;color:#000">电池: `+ params.data.data_6 +`</div>
                        </div>
                    </div>
                </div>
            `;
                    }
                },
                animation: false,

                bmap: {
                    // center: xy,
                    zoom: 16,
                    roam: true,
                    animation: true,
                    // 地图样式
                    // mapStyle: {
                    //     styleJson: [
                    //         {
                    //             featureType: 'water',// 水
                    //             elementType: 'all',
                    //             stylers: {color: '#1f2f4e'}
                    //         },
                    //         {
                    //             featureType: 'land',// 土地
                    //             elementType: 'all',
                    //             stylers: {color: '#35312b'}
                    //         },
                    //         {
                    //             featureType: 'railway',// 铁路
                    //             elementType: 'all',
                    //             stylers: {visibility: 'off'}
                    //         },
                    //         {
                    //             featureType: 'highway',// 公路
                    //             elementType: 'all',
                    //             stylers: {color: '#23221d'}
                    //         },
                    //         {
                    //             featureType: 'highway',// 公路
                    //             elementType: 'labels',
                    //             stylers: {visibility: 'off'}
                    //         },
                    //         {
                    //             featureType: 'arterial',// 山脉
                    //             elementType: 'geometry',
                    //             stylers: {color: '#fefefe'}
                    //         },
                    //         {
                    //             featureType: 'arterial',// 山脉
                    //             elementType: 'geometry.fill',
                    //             stylers: {color: '#fefefe'}
                    //         },
                    //         {
                    //             featureType: 'poi',
                    //             elementType: 'all',
                    //             stylers: {visibility: 'off', color: '#fefefe'}
                    //         },
                    //         {
                    //             featureType: 'green',
                    //             elementType: 'all',
                    //             stylers: {visibility: 'off'}
                    //         },
                    //         {
                    //             featureType: 'subway',// 地铁
                    //             elementType: 'all',
                    //             stylers: {visibility: 'off'}
                    //         },
                    //         {
                    //             featureType: 'manmade',
                    //             elementType: 'all',
                    //             stylers: {color: '#d1d1d1'}
                    //         },
                    //         // {
                    //         //     featureType: 'local',
                    //         //     elementType: 'all',
                    //         //     stylers: {
                    //         //         color: '#d1d1d1'
                    //         //     }
                    //         // },
                    //         // {
                    //         //     featureType: 'arterial',
                    //         //     elementType: 'labels',
                    //         //     stylers: {
                    //         //         visibility: 'off'
                    //         //     }
                    //         // },
                    //         {
                    //             featureType: 'boundary',
                    //             elementType: 'all',
                    //             stylers: {color: '#fefefe'}
                    //         },
                    //         {
                    //             featureType: 'building',
                    //             elementType: 'all',
                    //             stylers: {color: '#d1d1d1'}
                    //         },
                    //         // {
                    //         //     featureType: 'label',
                    //         //     elementType: 'labels.text.fill',
                    //         //     stylers: {
                    //         //         color: '#999999'
                    //         //     }
                    //         // }
                    //     ]
                    // },
                },
                series: []
            }


        );
        // 点击地图触发
        chartDom.getZr().on('click', function (params,e,b) {
            if(_this.state.choiceDataId){
                var pixelPoint = [params.offsetX, params.offsetY];
                var dataPoint = chartDom.convertFromPixel({ geoIndex: 0 }, pixelPoint);
                _this.setState({longitude:dataPoint[0],latitude:dataPoint[1]});
                var data = _this.state.valueMapData;
                if(data.find(one=>{if(!one.id)return true})){
                    data.splice(data.length-1,1,{name:'标点',"value": [dataPoint[0], dataPoint[1], 200],color:'red'})
                }else{
                    data.push({name:'标点',"value": [dataPoint[0], dataPoint[1], 200],color:'red'});
                }
                chartDom.setOption({
                    series: [
                        {
                            name: '标点',
                            type: 'effectScatter',
                            coordinateSystem: 'bmap',
                            data: data.map(function (pipe) {
                                return {
                                    id: pipe.id??null,
                                    devId:pipe.devId??null,
                                    name: pipe?.name??null,
                                    data:pipe?.data,
                                    installPosition:pipe.installPosition??null,
                                    value:pipe?.value??null,
                                    itemStyle: {color: pipe?.color??null,}
                                };
                            }),
                            encode: {value: 2},
                            symbolSize: function (val) {return val[2] / 15;},// 标点大小
                            showEffectOn: 'emphasis',// 标点波动方式
                            label: {
                                formatter: '{b}',
                                position: 'bottom',
                                show: true,
                            },
                            rippleEffect:{number:3,},// 波动样式
                        },
                    ]
                })
            }
        });
        // 窗口大小变化时，ECharts地图自适应
        window.onresize = function() {chartDom.resize();};
    };


    render(){

        const {columns, data} = this.state;

        return(
            <div id={'dataCenter'}>
                {/*标题*/}
                <div className={'dataCenter_topSearchBox'}>
                    <h3>数据统计中心</h3>
                </div>
{/*数据栏 ---------------------------------------------------*/}
                <div className={'dataCenter_css_div_head_1'}>
                    {/* 今日投诉总量*/}
                    <div className={'dataCenter_css_div_head_1_div_frame'}>
                        <div className={'dataCenter_css_div_head_1_div_frame_statistic-card'}>
                            <div
                                className={'dataCenter_css_div_head_1_div_frame_statistic-title'}>
                                <FireOutlined style={{color:'#1890ff', fontSize: '24px',paddingRight: '10px'}}/>
                                今日投诉总量
                            </div>
                            <div className="dataCenter_css_div_head_1_div_frame_statistic-value">
                                128
                            </div>
                        </div>
                    </div>
                    {/* 待处理工单*/}
                    <div className={'dataCenter_css_div_head_1_div_frame'}>
                        <div className={'dataCenter_css_div_head_1_div_frame_statistic-card'}>
                            <div
                                className={'dataCenter_css_div_head_1_div_frame_statistic-title'}>
                                <ClockCircleOutlined style={{color:'#faad14', fontSize: '24px',paddingRight: '10px'}}/>
                                待处理工单
                            </div>
                            <div className="dataCenter_css_div_head_1_div_frame_statistic-value">
                                45
                            </div>
                        </div>
                    </div>
                    {/* 已完成工单*/}
                    <div className={'dataCenter_css_div_head_1_div_frame'}>
                        <div className={'dataCenter_css_div_head_1_div_frame_statistic-card'}>
                            <div
                                className={'dataCenter_css_div_head_1_div_frame_statistic-title'}>
                                <CheckCircleOutlined style={{color:'#52c41a', fontSize: '24px',paddingRight: '10px'}}/>
                                已完成工单
                            </div>
                            <div className="dataCenter_css_div_head_1_div_frame_statistic-value">
                                83
                            </div>
                        </div>
                    </div>
                    {/*  超时工单*/}
                    <div className={'dataCenter_css_div_head_1_div_frame'}>
                        <div className={'dataCenter_css_div_head_1_div_frame_statistic-card'}>
                            <div
                                className={'dataCenter_css_div_head_1_div_frame_statistic-title'}>
                                <ExclamationCircleOutlined style={{color:'#ff4d4f', fontSize: '24px',paddingRight: '10px'}}/>
                                超时工单
                            </div>
                            <div className="dataCenter_css_div_head_1_div_frame_statistic-value">
                                12
                            </div>
                        </div>
                    </div>
                </div>
{/*地图 ---------------------------------------------------*/}
                <div className={'dataCenter_css_div_head_2'}>
                    <div className={'dataCenter_css_div_head_2_statistic-card'}>
                        <div className="dataCenter_css_div_head_2_card-header">
                            <h3 className="dataCenter_css_div_head_2_card-title">投诉热力分布图</h3>
                        </div>
                        <div style={{
                            width: '100%',
                            height:'345px',
                            zIndex:'9',
                            padding:'10px'}}>
                            <div id={'deviceModelMapId'}
                                 style={{width: '100%', height:'100%',}}/>
                        </div>
                    </div>

                </div>
                <div className={'dataCenter_css_div_head_3'}>
                    <div className={'dataCenter_css_div_head_3_statistic-card'}>
                        <div className="dataCenter_css_div_head_3_card-header">
                            <h3 className="dataCenter_css_div_head_3_card-title">我的工单列表</h3>
                        </div>
                        {/*表格*/}
                        <div style={{padding:'10px'}}>
                            <TableComponents
                                paramenter={this.state.tableParamenter}
                                setData={queryPageWorkOrder}
                                setTableValue={this.setTableValue}
                                ref={e => this.table = e}
                            />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
