// 委托单-创建委托单页面
import React, { Component } from 'react'
import {
	Layout,
	Button,
	Tabs,
	message,
	Modal,
	Input,
	Select,
	TreeSelect,
	InputNumber,
	AutoComplete,
	Spin,
	Cascader, DatePicker
} from 'antd'
// import { ArrowLeftOutlined } from '@ant-design/icons';
// import { NavLink } from "react-router-dom"
import TableComponents from '../../../components/common/tables/TableComponentsNew'
import FormsTwo from '../../../components/common/forms/FormsTwo'

import {TYPE_NAME} from '../../../components/Const'
import {insertEntrust,getTemporaryList,delTemporaryAlarm,allDelTemporaryAlarm,addAlarmInfo,getCustomer,getCustomerOne,getEquipmentName,getModelByAlaramId,insertCustomer} from '../../../services/verifvication'
import {PlusSquareOutlined,} from '@ant-design/icons';
import $ from 'jquery'
import {funTraceInfo} from '../../../services/common'
import areaData from "../standby/addreData";
import {Option} from "antd/es/mentions";

const { TabPane } = Tabs;

export default class AddPoint extends Component {

    state = {

    	selectLoading_1:true,	// 委托单位输入框加载
		variableVisible_1:false,	// 新增委托单位框
		variableVisible_2:false,	// 新增报警器

		// 客户信息
		customerData:[
			{data:null,selectInput:[],type:null},// 委托单位-0
			{data:null,selectInput:null},// 联系人-1
			{data:null,selectInput:null,type:null},// 联系电话-2
			{data:'湖南省',selectInput:[]},// 省-3
			{data:'长沙市',selectInput:[]},// 市-4
			{data:'岳麓区',selectInput:[]},// 区-5
			{data:null,selectInput:null},// 详情地址-6
			{data:null,selectInput:null},// 检定仪器数量-7
			{data:null,selectInput:null,type:null},// 业务员-8
			{data:[],selectInput:null,type:null},// 检定员-9
		],
		// 添加客户信息
		addCustomerData:{
    		id:null,		//  id
			customerUnit: null,	// 客户单位
			juder_companyType:null,
			principal: null,		// 客户名
			principalPhone: null,	// 客户电话号
			province: '湖南省',		// 省
			city: '长沙市',			// 市
			district: '岳麓区',		// 区
			details: null,		// 详细地址
		},

		equipmentData:[
			[],
		],

		// 添加设备信息
		addEquipment:{
			equipmentName:null, // 设备名称
			model:null,			// 型号
			startNumber:null,	// 出厂编号
			manufacturers:null,	// 厂家
			// location:'用户使用现场',	// 安装位置
			province:'湖南省',		// 省
			city:'长沙市',			// 市
			district: '岳麓区',		// 区
			details:null,			// 详情
			verifierDate:null,		// 检定时间
		},
		select_equipmentName:[],
		select_model:[],

		selectOnecustomerData:null,



				// 表格参数
				tableParamenter: {
					prompt:false,
					manageName: "数据项管理",
					// 表格行是否可选
					// rowSelection: true,
					//下拉框长度
					selectWidth: '6.5vw',
					// 表头：变量和变量解释
					header: {
						equipmentName: '设备名称',
						model: '型号规格',
						manufacturers: '制作厂家',
						startNumber: '出厂编号',
						createTime: '添加时间',
						edit:'删除'
					},
					// 弹出框
					headers: {
						equipmentName: '设备名称',
						model: '型号规格',
						manufacturers: '制作厂家',
						startNumber: '出厂编号',
						createTime: '添加时间'
					},
					// 是否绑定左侧区域
					bindArea: false
				},
				// 总条数
				totalCount: 0,

				params: {
						page:1,
						size:10,
						  username: null,
					  // organizationId :this.state.treeId
							// areaId: localStorage.getItem('menus')
				},
        // 委托单的表单参数
		formParamenter: {
			modal: true,
			number: 4,
            labelCol: 7,
			layout: "horizontal",
			// itemSize: 'large',
			parameter: [
				{
					variableName: 'customerId',
					str: '委托单位',
					rules: TYPE_NAME.SELECTINPUT,
					data:[],
					onChange: (k,v) => this.onChangeSelect(k,v)
				},
				// {
				// 	variableName: 'entrustUnit',
				// 	str: '委托单位',
				// 	rules: TYPE_NAME.INPUT,
				// },
				{
					variableName: 'principal',
					str: '联系人',
					rules: TYPE_NAME.INPUT,

				},
				{
					variableName: 'phone',
					str: '联系电话',
					rules: TYPE_NAME.PHONE_NUM
				},
				{
					variableName: 'province',
					str: '省',
					disabled: true,
					rules: TYPE_NAME.INPUT
				},
				{
					variableName: 'city',
					str: '市',
					disabled: true,
					rules: TYPE_NAME.INPUT
				},
				{
					variableName: 'district',
					str: '区',
					disabled: true,
					rules: TYPE_NAME.INPUT
				},
				{
					variableName: 'details',
					str: '详细地址',
					disabled: true,
					rules: TYPE_NAME.INPUT
				},
				{
					variableName: 'verificationQuantity',
					str: '检定仪器数量',
					rules: TYPE_NAME.INPUT
				},
			]
		},
		entrustData:{},// 委托单表单的数据
        // 弹框表单参数
		ModalFormParamenter: {
			modal: true,
			number: 3,
            labelCol: 8,
			layout: "horizontal",
			// itemSize: 'large',
			parameter: [
				{
					variableName: 'startNumber',
					str: '出厂编号',
					rules: TYPE_NAME.LABLE_NOT_NULL_50
				},
				{
					variableName: 'equipmentName',
					str: '设备名称',
					rules: TYPE_NAME.SELECTINPUT,
					data:[],
					onChange: (k,v) => {

						this.equipmentIdSelect(k,v)
					}
				},
				{
					variableName: 'model',
					str: '型号规格',
					rules: TYPE_NAME.AutoComplete,
					data:[],
					// onChange: (k,v) => this.onChangeSelect(k,v)

				},
				{
					variableName: 'manufacturers',
					str: '制造厂家',
					rules: TYPE_NAME.LABLE_NOT_NULL_50
				},
				// {
				// 	variableName: 'location',
				// 	str: '安装位置',
				//
				// 	rules: TYPE_NAME.INPUT
				// },
				{
					variableName: 'province',
					str: '省',

					rules: TYPE_NAME.INPUT
				},
				{
					variableName: 'city',
					str: '市',

					rules: TYPE_NAME.INPUT
				},
				{
					variableName: 'district',
					str: '区',

					rules: TYPE_NAME.INPUT
				},
				{
					variableName: 'details',
					str: '详细地址',
					rules: TYPE_NAME.INPUT
				},
			]
		},
		ModalVariableFormParamenter: {
			modal: true,
			number: 3,
            labelCol: 8,
			layout: "horizontal",
			// itemSize: 'large',
			parameter: [
				{
					variableName: 'id',
					str: '客户号',
					rules: TYPE_NAME.NAME,
					disabled: true
				},
				{
					variableName: 'province',
					str: '省',

					rules: TYPE_NAME.INPUT
				},
				{
					variableName: 'city',
					str: '市',

					rules: TYPE_NAME.INPUT
				},
				{
					variableName: 'district',
					str: '区',

					rules: TYPE_NAME.INPUT
				},
				{
					variableName: 'details',
					str: '详细地址',
					rules: TYPE_NAME.INPUT
				},
				{
					variableName: 'companyType',
					str: '客户类型',
					rules: TYPE_NAME.REQ_SELECT,
					disabled: this.props.location.state ? true : false,
					data: [
						{
							key: '1',
							value: '居民客户'
						},
						{
							key: '2',
							value: '工商客户'
						}
					]
				},
				// {
				// 	variableName: 'nature',
				// 	str: '客户性质',
				// 	rules: TYPE_NAME.NAME
				// },
				{
					variableName: 'customerUnit',
					str: '客户单位',
					rules: TYPE_NAME.LABLE_NOT_NULL_50
				},
				// {
				// 	variableName: 'organizationCode',
				// 	str: '组织机构代码',
				// 	rules: TYPE_NAME.LABLE_18
				// },
				{
					variableName: 'legalPerson',
					str: '法人',
					rules: TYPE_NAME.LABLE
				},
				{
					variableName: 'principal',
					str: '联系人',
					rules: TYPE_NAME.LABLE_NOT_NULL_20
				},
				{
					variableName: 'principalPhone',
					str: '联系人电话',
					rules: TYPE_NAME.PHONE_NUM_NOT_NULL
				}
			]
		},


		data: {
			customerId: this.props.location.state?.customerId,// 获取前页带入的数据-客户
		},
		dataKey: this.props.location.state?.dataKey ?? '1',
		devVisible:false, // 小窗口开关器-1
		variableVisible:false, // 委托单位小窗口开关器-1
		equipmentName:''
    }

	// 初始默认执行
	componentDidMount(){
    	this.findArea();
		this.clear();//清空
		// const {name, id} = this.props.location?.state ?? {};
		// if(id) {
		// 	this.getData(id)
		// }
		this.getCustomer();
		getEquipmentName().then(
			response => {

				let selectArry=[];
				if(response?.data?.data){
					const {data} = response.data;
					for(var i=0;i<data.length;i++){
						selectArry.push({key:data[i].id,value:data[i].equipmentName})
					}
				}
				this.state.select_equipmentName=selectArry;
				this.state.ModalFormParamenter.parameter[1].data=selectArry;
			}
		)

	}

	// 获取客户列表
	getCustomer=()=>{
		getCustomer().then(
			response => {
				const {data} = response.data;

				let selectArry=[];
				if(data){
					for(var i=0;i<data.length;i++){
						selectArry.push({key:data[i].id,value:data[i].customerUnit})
					}
				}
				this.state.customerData[0].selectInput=data;// 客户列表
				// this.state.customerData_1[0].selectInput=data;// 客户列表
				this.state.formParamenter.parameter[0].data=selectArry;
				this.setState({selectLoading_1:false})
			}
		);
	};

	// 删除指定报警器
	deleteDev = (id) =>{
		console.log("删除指定的报警器");// 2022-5-9

		delTemporaryAlarm(id={id}).then(response=>{

			if(response.data.data===true){
				// funTraceInfo("报警器检定管理", "删除指定的报警器", null,id)
				message.info("删除成功");
				this.table.refreshTable();// 刷新
			}else{
				message.info("删除失败");
				this.table.refreshTable();// 刷新
			}
		});


	};
	equipmentIdSelect = (k,v) => {
		this.setState({model_juder:true});
		getModelByAlaramId({alaramId: k}).then(
			response => {
				const {data} = response.data;
				let selectArry=[];
				if(data){
					for(var i=0;i<data.length;i++){
						selectArry.push({key:data[i].devModelName,value:data[i].devModelName})
					}
				}
				// data.model=null
				this.state.equipmentName=v.children;
				message.success('查询成功');
				this.state.ModalFormParamenter.parameter[2].data=selectArry;
				this.state.select_model=data;
				this.setState({model_juder:false});
			}
		)
	};
	onChangeSelect = (k,v) => {
		getCustomerOne({id: k}).then(
			response => {
				const {data} = response.data
				let selectData=this.state
				selectData.customerId=data.id
				selectData.entrustUnit=data.customerUnit
				selectData.principal=data.principal
				selectData.phone=data.principalPhone
				selectData.province=data.province
				selectData.city=data.city
				selectData.district=data.district
				selectData.details=data.details
				this.setState({data: selectData})
				message.success('查询成功')
				this.forms.onModalClose()
			}
		)
	}
    // 表格格式
	setTableValue = (value,text,rowData) => {
		if(text === 'null') return '-';
		switch (value) {
			case 'edit':
				return <>
				<div className={'tableLink'} title={'删除'}>
						<u onClick={()=>{this.deleteDev(rowData.id)}}>{'删除'}</u>
				</div>
				{/*<div className={'tableLink'} title={'添加'}>*/}
				{/*		<u onClick={()=>{*/}
				{/*			this.setState({variableVisible_2L:true});*/}
				{/*			var addEquipment = this.state.addEquipment;*/}
				{/*			*/}
				{/*			*/}
				{/*			*/}
				{/*		}}>{'添加'}</u>*/}
				{/*</div>*/}
				</>;

			default:
				return text ? <div className={'table_overflow'} title={text}>{text}</div> : ''
		}
	};

	getData = id => {

	};

	//创建委托单
    onSave = async () => {

		let user = JSON.parse(sessionStorage.getItem("user"));

		let customerData=this.state.customerData;
		if(!customerData[0].data){
			message.info('请选择委托单位');
			return this.setState({juder_1:'error'});
		}
		if(!customerData[1].data){
			message.info('请填写联系人');
			customerData[1].type='error';
			return this.setState({customerData:customerData});
		}
		if(!customerData[2].data){
			message.info('请填写联系电话');
			customerData[2].type='error';
			return this.setState({customerData:customerData});
		}

		let menus = JSON.parse(localStorage.getItem('menus')) ?? [];
		// let areaName = localStorage.getItem('areaName') ?? [];

		var areaId = null;
		if(menus.size!==0)areaId=menus[0];

		var values={
			otherAreaId:areaId,
			areaId:areaId,
			creator: user.realName,		// 用户名
			customerId: this.state.selectOnecustomerData?.id || null, // 客户id
			entrustUnit: customerData[0].data,			// 客户单位
			principal: customerData[1].data,			// 联系人
			phone: customerData[2].data,				// 电话
			province: customerData[3].data|| '湖南省',  // 省
			city: customerData[4].data || '长沙市',		// 市
			district: customerData[5].data,				// 区
			details: customerData[6].data,				// 详情
			verificationQuantity: customerData[7].data,	// 检定数量
			salesman:customerData[8].data,	// 业务
			verifier:customerData[9].data,	// 检定
		};

		// let values = await this.forms.onFinishNotReset();
		// if(!values) return null;
		// values.entrustUnit=this.state.entrustUnit;
		//
		// values.creator=user.realName;


    	console.log("创建委托单");
		console.log(values);
		$('.loadBox').show();
		insertEntrust(values).then(data=>{
			$('.loadBox').hide();
			if(data.data.data){
				// funTraceInfo("报警器检定管理", "创建委托单", null,values);
				message.success("创建成功");
				this.props.history.go(-1)
			}else {
				message.success("创建失败"+data.data.responseInfo.description);
				this.table.refreshTable();// 刷新
			}

		}).catch(e=>{
			$('.loadBox').hide();
			return message.success("访问异常，请重新尝试");
		})

    };
	//添加设备窗口展示开关
	addDevStatus = async (devVisible,data)=>{
        this.setState({devVisible,data});
		// this.setState({variableVisible_2:true})
    };

	// 添加报警器-未完成-获取不到表单数据
	addDevData= async()=>{
		let values = await this.forms2.onFinishNotReset();
		values.equipmentName=this.state.equipmentName;
		let menus = JSON.parse(localStorage.getItem('menus')) ?? [];
		if(menus.size!==0)values = Object.assign({},values,{otherAreaId:menus[0]});

		addAlarmInfo(values).then(response=>{

				if(response.data.data===true){
					// funTraceInfo("报警器检定管理", "添加报警器", null,values)
					message.success("添加成功");
					this.addDevStatus(false);
					this.table.refreshTable();// 刷新
				}else{
					this.addDevStatus(false);
					message.success(response.data.responseInfo.description);
					this.table.refreshTable();// 刷新
				}
			});
	};
//新增委托单位
addVariableStatus= async (variableVisible)=>{
		await this.setState({variableVisible,data:{province: '湖南省',city: '长沙市'},modTitle:'添加客户',cusId:''})
		await this.forms.onModalClose()
	};
	addVariableData = async ()=>{
		console.log("添加客户");
		let devData = await this.forms.onFinishNotReset();
		if(!devData) return null
		if(this.state.cusId){
			devData.id=this.state.cusId
		}
		console.log(devData)
		insertCustomer(devData).then(data=>{

			if(data.data?.data){
				// funTraceInfo("报警器检定管理", "新增委托单位", null,devData)
				message.config("添加成功");
				getCustomer().then(
					response => {
						const {data} = response.data
						let selectArry=[]
						if(data){
							for(var i=0;i<data.length;i++){
								selectArry.push({key:data[i].id,value:data[i].customerUnit})
							}
						}
						this.state.formParamenter.parameter[0].data=selectArry;
					}
				)
				this.setState({variableVisible:false,cusId:''})
			}else{
				this.setState({variableVisible:false,cusId:''})
				message.config('测试失败');
			}
			this.table.refreshTable();// 刷新表格
		});
	};
	editVariableStatus=(variableVisible,id)=>{
        this.setState({variableVisible,cusId:id,modTitle:'修改客户'});
		// this.state.cusId=id;
		getCustomerOne({id: id}).then(
			response => {
				const {data} = response.data
				this.setState({data: data})
				message.success('查询成功')
				this.forms.onModalClose()
			}
		)
    };

	//清空按钮
    clear = async(e, info) => {
		if(this.props.location.state?.id){
			message.warn("修改时不允许清空")
		}else{
			let selectData=this.state
			selectData.customerId=null
			selectData.entrustUnit=null
			selectData.principal=null
			selectData.phone=null
			selectData.details=null
			selectData.verificationQuantity=null
			await this.setState({data: selectData})
			// await this.forms.onModalClose()
			allDelTemporaryAlarm();// 删除所有报警器
			this.table.refreshTable();// 刷新表格
		}
    };

    findArea=()=>{
		// this.state.customerData[4].selectInput
		var customerData = this.state.customerData;
		areaData.map(one=>{
			// 市级赋值
			if(one.name===customerData[3]?.data){
				customerData[4].selectInput=one.city;
				customerData[4].selectInput.map(two=>{
					if(two.name===customerData[4]?.data){
						customerData[5].selectInput=two.area;
					}
				})

			}
		});
		this.setState({customerData:customerData})

	};

    render() {

		const {dataKey} = this.state;
        return (

            <Layout className={'item_body_number_back tables'}>
				<div className={'loadBox'} style={{zIndex:'9999'}}>
					<div className={'loadInner'}></div>
					<div className={'loadIcon'}>
						<Spin></Spin>
						<p>...</p>
					</div>
				</div>
				<div style={{width: '100%',height: '100%',backgroundColor: '#ffffff',padding: '30px 1%',whiteSpace: 'nowrap'}}>


					<div className={'topTitleBox'}>
						<h3>创建委托单</h3>
						<div style={{float:'right'}}>
						<Button style={{margin:'0 0 0 20px'}} type="primary" onClick={()=>{this.onSave()}}>保存</Button>
						{/*<Button style={{margin:'0 0 0 20px'}} type="primary" onClick={() => this.addVariableStatus(true)}>新增委托单位</Button>*/}
						<Button style={{margin:'0 0 0 20px'}} onClick={() => this.clear(dataKey, "clear")}>清空</Button>
						<Button style={{margin:'0 0 0 20px'}} onClick={() => this.props.history.go(-1)}>返回</Button>
						</div>
					</div>
					<div style={{clear:'both',padding:'0 10px',height:'140px'}}>

						{/*第一层*/}
						<div style={{width:'100%',height:'30px'}}>
							<div style={{width:'30%',float:'left',}}>
								<div style={{width:'13.5%',float:'left'}}>
									<span style={{fontWeight: 'bold'}}><span style={{color:'red'}}>*</span>委托单位:</span>
								</div>
								<div style={{width:'70%',float:'left'}}>
									<div style={{width:'65%',float:'left'}}>
										<AutoComplete
											showSearch
											status={this.state?.juder_1}
											loading={this.state.selectLoading_1}
											style={{width:'100%'}}
											size={'small'}
											value={this.state.customerData[0].data}
											placeholder={'请输入委托单位'}
											fieldNames={ {label: 'customerUnit', value: 'customerUnit', children: 'children'} }
											options={this.state.customerData[0].selectInput}
											filterOption={
												(inputValue, option) =>
													option?.customerUnit?.toUpperCase().indexOf(inputValue?.toUpperCase())
													!== -1}
											onChange={(title,valueData)=>{

												// 正则表达 控制内容只能是中文和字母及数字
												var required = /^[\u4e00-\u9fa5_a-zA-Z0-9]+$/;
												// if(title.length===0){
												// 	return false;
												// 	// if(!required.test(title)){return false}
												// }

												var customerData = this.state.customerData;
												customerData[0].data=title;
												if(valueData?.principal){
													customerData[1].data=valueData?.principal;
													customerData[2].data=valueData?.principalPhone;
													customerData[3].data=valueData?.province;
													customerData[4].data=valueData?.city;
													customerData[5].data=valueData?.district;
													customerData[6].data=valueData?.details;
												}

												this.setState({customerData:customerData,juder_1:null,selectOnecustomerData:valueData});
												this.findArea();
											}}

										/>
									</div>
									{/*<div style={{width:'30%',float:'left'}}>*/}
									{/*	<Button type="primary" size={'small'} onClick={() => this.setState({variableVisible_1:true})}>新增委托单位</Button>*/}
									{/*</div>*/}
									<div style={{display:this.state?.juder_1==='error'?'':'none'}}>
										<span style={{color:'red'}}>请选择委托单位</span>
									</div>
								</div>
							</div>
						</div>

						{/*第二层*/}
						<div style={{width:'100%',height:'30px'}}>
							<div style={{width:'20%',float:'left',}}>
								<div style={{width:'20%',float:'left'}}><span style={{fontWeight: 'bold'}}><span style={{color:'red'}}>*</span>联系人:</span></div>
								<div style={{width:'69%',float:'left'}}>
									<Input size={'small'}
										   value={this.state.customerData[1].data}
										   status={this.state.customerData[1].type}
										   placeholder={'请输入联系人'}
										   onChange={(title)=>{

											   // 正则表达 控制内容只能是中文和字母及数字
											   var required = /^[\u4e00-\u9fa5_a-zA-Z0-9]+$/;
											   // if(title.target.value.length===0){
												//    return false;
												//    // if(!required.test(title.target.value)){return false}
											   // }
											   var customerData = this.state.customerData;
											   customerData[1].data=title.target.value;
											   customerData[1].type=null;
											   this.setState({customerData:customerData});
										   }}
									/>
								</div>

							</div>
							<div style={{width:'20%',float:'left',}}>
								<div style={{width:'20%',float:'left'}}>
									<span style={{fontWeight: 'bold'}}><span style={{color:'red'}}>*</span>联系电话:</span>
								</div>
								<div style={{width:'69%',float:'left'}}>
									<Input size={'small'}
										   value={this.state.customerData[2].data}
										   placeholder={'请输入联系人电话'}
										   status={this.state.customerData[2].type}
										   onChange={(title)=>{
										   	if(!/^[1]([3-9])[0-9]{9}$/g.test(title.target.value)) {
										   		this.state.customerData[2].type='error';
										   	}else{
												this.state.customerData[2].type=null;
											}
										   	var customerData = this.state.customerData;
											customerData[2].data=title.target.value;

											this.setState({customerData:customerData});
										   }}
									/>
								</div>
							</div>
						</div>
						{/*第三层*/}
						<div style={{width:'100%',height:'30px'}}>
							<div style={{width:'15%',float:'left',}}>
								<div style={{width:'27%',float:'left'}}><span style={{fontWeight: 'bold'}}>省:</span></div>
								<div style={{width:'70%',float:'left'}}>
									<Select size={'small'}
											showSearch
											style={{width:'100%'}}
											value={this.state.customerData[3].data}
											placeholder={'请选择省'}
											options={areaData}
											fieldNames={ {label: 'name', value: 'name', children: 'children'} }
											onChange={(title,valueData)=>{
												var customerData = this.state.customerData;
												customerData[3].data=title;
												customerData[4].selectInput=valueData.city;
												customerData[5].selectInput=[];
												this.state.customerData[4].data=null;
												this.state.customerData[5].data=null;
												this.setState({customerData:customerData});
										   }}
									/>
								</div>
							</div>
							<div style={{width:'12%',float:'left',}}>
								<div style={{width:'12%',float:'left'}}><span style={{fontWeight: 'bold'}}>市:</span></div>
								<div style={{width:'83%',float:'left'}}>
									<Select size={'small'}
											showSearch
											style={{width:'100%'}}
											value={this.state.customerData[4].data}
											placeholder={'请选择市'}
											options={
												this.state.customerData[4].selectInput
											}
											fieldNames={ {label: 'name', value: 'name', children: 'children'} }
											onChange={(title,valueData)=>{
												var customerData = this.state.customerData;
												customerData[4].data=title;
												valueData.area?.map((name)=>{
													customerData[5].selectInput.push({name:name.toString()})
												});
												customerData[5].selectInput=valueData.area;
												this.state.customerData[5].data=null;
												this.setState({customerData:customerData});
											}}
									/>
								</div>
							</div>
							<div style={{width:'12%',float:'left',}}>
								<div style={{width:'12%',float:'left'}}><span style={{fontWeight: 'bold'}}>区:</span></div>
								<div style={{width:'83%',float:'left'}}>
									<Select size={'small'}
											showSearch
											style={{width:'100%'}}
											value={this.state.customerData[5].data}
											placeholder={'请选择区'}
											// options={this.state.customerData[5].selectInput}
											// fieldNames={ {label: 'name', value: 'name', children: 'children'} }
											onChange={(title,valueData)=>{
												var customerData = this.state.customerData;
												customerData[5].data=title;
												this.setState({customerData:customerData});
											}}
									>
										{(this.state.customerData[5].selectInput).map((name)=>
											<Option key={name} label={name}>{name}</Option>
										)}
									</Select>
								</div>
							</div>
							<div style={{width:'25%',float:'left',}}>
								<div style={{width:'16%',float:'left'}}><span style={{fontWeight: 'bold'}}>详情地址:</span></div>
								<div style={{width:'80%',float:'left'}}>
									<Input size={'small'}
										   value={this.state.customerData[6].data}
										   placeholder={'请输入详情地址'}
										   onChange={(title)=>{
											   var customerData = this.state.customerData;
											   customerData[6].data=title.target.value;
											   this.setState({customerData:customerData});
										   }}
									/>
								</div>
							</div>
						</div>
						<div style={{width:'100%',height:'30px'}}>
							<div style={{width:'20%',float:'left',}}>
								<div style={{width:'20%',float:'left'}}>
									<span style={{fontWeight: 'bold'}}>业务员:</span>
								</div>
								<div style={{width:'69%',float:'left'}}>
									<Select size={'small'}
												  style={{width:'100%'}}
											allowClear
												  value={this.state.customerData[8].data}
												  // status={this.state.customerData[8].type}// 关闭错误效果
												  placeholder={'请输入业务员'}
												  onChange={(title,data)=>{
												  	var customerData = this.state.customerData;
												  	customerData[8].data=title;
												  	// customerData[8].type=null;
												  	this.setState({customerData:customerData});
												  }}
									>
										{/*程立国 盛浩 李梦婷 陈佳伟 李进 江军雄 田辉 曾思红 聂立功 马琼 杨超*/}
										<Option key={'韦杰'} label={'韦杰'}>韦杰</Option>
										<Option key={'林迪启'} label={'林迪启'}>林迪启</Option>
										<Option key={'林启迪'} label={'蒋宁湘'}>蒋宁湘</Option>
										<Option key={'熊攻科'} label={'熊攻科'}>熊攻科</Option>
										<Option key={'杨能'} label={'杨能'}>杨能</Option>
										<Option key={'程立国'} label={'程立国'}>程立国</Option>
										<Option key={'盛浩'} label={'盛浩'}>盛浩</Option>
										<Option key={'李梦婷'} label={'李梦婷'}>李梦婷</Option>
										<Option key={'陈佳伟'} label={'陈佳伟'}>陈佳伟</Option>
										<Option key={'李进'} label={'李进'}>李进</Option>
										<Option key={'江军雄'} label={'江军雄'}>江军雄</Option>
										<Option key={'田辉'} label={'田辉'}>田辉</Option>
										<Option key={'曾思红'} label={'曾思红'}>曾思红</Option>
										<Option key={'聂立功'} label={'聂立功'}>聂立功</Option>
										<Option key={'马琼'} label={'马琼'}>马琼</Option>
										<Option key={'杨超'} label={'杨超'}>杨超</Option>
										<Option key={'其他'} label={'其他'}>其他</Option>
									</Select>
									{/*韦杰、林启迪、其他fieldNames={ {label: 'customerUnit', value: 'customerUnit', children: 'children'} }*/}
								</div>
							</div>
							<div style={{width:'30%',float:'left',}}>
								<div style={{width:'13%',float:'left'}}>
									<span style={{fontWeight: 'bold'}}>检定员:</span>
								</div>
								<div style={{width:'75%'}}>
									<Select size={'small'}
											style={{width:'100%'}}
											mode="multiple"
											allowClear
											value={this.state.customerData[9].data}
											// status={this.state.customerData[9].type}// 关闭错误效果
											// maxTagCount={2}
											placeholder={'请输入检定员'}
											onChange={(title,data)=>{
												var customerData = this.state.customerData;
												// if(title.length !== 0){
												// 	customerData[9].type=null
												// }else{
												// 	customerData[9].type='error';
												// }

												if(title?.length>3){
													return message.info('仅限三位');
												}
												customerData[9].data=title;

												this.setState({customerData:customerData});
											}}
									>
										<Option key={'韦杰'} label={'韦杰'}>韦杰</Option>
										<Option key={'陈佳伟'} label={'陈佳伟'}>陈佳伟</Option>
										<Option key={'杨超'} label={'杨超'}>杨超</Option>
										<Option key={'马琼'} label={'马琼'}>马琼</Option>
										<Option key={'聂立功'} label={'聂立功'}>聂立功</Option>
										<Option key={'曾思红'} label={'曾思红'}>曾思红</Option>
										<Option key={'田辉'} label={'田辉'}>田辉</Option>
										<Option key={'杨能'} label={'杨能'}>杨能</Option>
										<Option key={'程立国'} label={'程立国'}>程立国</Option>
										<Option key={'盛浩'} label={'盛浩'}>盛浩</Option>
										<Option key={'江军雄'} label={'江军雄'}>江军雄</Option>
										<Option key={'李进'} label={'李进'}>李进</Option>
									</Select>
								</div>

							</div>
							<div style={{width:'20%',float:'left',}}>
								<div style={{width:'20%',float:'left'}}><span style={{fontWeight: 'bold'}}>仪器数量:</span></div>
								<div style={{width:'60%',float:'left'}}>
									<InputNumber
										size={'small'}
										min={1}
										value={this.state.customerData[7].data}
										placeholder={'请输入仪器数量'}
										onChange={(title)=>{
											var customerData = this.state.customerData;
											customerData[7].data=title;
											this.setState({customerData:customerData});
										}}
									/>

								</div>
							</div>
						</div>

						{/*<FormsTwo*/}
						{/*	ref={e => this.forms = e}*/}
						{/*	formParamenter={this.state.formParamenter}*/}
						{/*	data={this.state.data}*/}
						{/*	/>*/}
					</div>
					{/*---------------------------------------------*/}
					<div className={'topTitleBox'}>
						<h3>检定报警器</h3>
						<span className={'addLink'} onClick={() =>
						{
							// var data = this.table.getDataTable();
							var {customerData,addEquipment} = this.state;
							addEquipment.province=customerData[3].data;
							addEquipment.city=customerData[4].data;
							addEquipment.district=customerData[5].data;
							addEquipment.details=customerData[6].data;
							if(this.state.customerData[4].selectInput){
								this.setState({Equipment_city:this.state.customerData[4].selectInput})
							}
							if(this.state.customerData[5].selectInput){
								this.setState({Equipment_district:this.state.customerData[5].selectInput})
							}


							this.setState({variableVisible_2:true,addEquipment})
							// this.addDevStatus(true)
						}}>
							<PlusSquareOutlined />添加检定设备</span>
					</div>
					<div style={{clear:'both',padding:'0 10px'}}>
						<TableComponents
						paramenter={this.state.tableParamenter}
						setData = {getTemporaryList}
						setTableValue={this.setTableValue}
						ref={e => this.table = e}
					>
						</TableComponents>
					</div>
					{/*---------------------------------------------------------------------------------------------*/}
					<Modal
                        title="添加检定设备"
						maskClosable={false}
                        style={{ top: 0 }}
                        visible={this.state.devVisible}
                        okText="确定"
                        onOk={() => this.addDevData(true)}           	// 点击确定
                        onCancel={() => this.addDevStatus(false)}	// 点击取消
                        width={900}
                        centered
                    >
                        <div>
							<FormsTwo
							ref={e => this.forms2 = e}
							formParamenter={this.state.ModalFormParamenter}
							data={this.state.data}
							/>
                        </div>
                    </Modal>

					<Modal
						title={'新增客户信息'}
						style={{ top: 0 }}
						visible={this.state.variableVisible}
						okText="确定"
						onOk={() => this.addVariableData(true)}           // 点击确定
						onCancel={() => this.addVariableStatus(false)}       // 点击取消
						width={900}
						centered
					>
						<div>
							<FormsTwo
								ref={e => this.forms = e}
								formParamenter={this.state.ModalVariableFormParamenter}
								data={this.state.data}
							/>
						</div>
					</Modal>

					{/*-----------------------------------------新---添加检定设备-------------------------------------*/}
					<Modal
						maskClosable={false}
						title="添加检定设备"
						style={{ top: 0 }}
						visible={this.state.variableVisible_2}
						okText="确定"
						onOk={() => {

							var addEquipment = this.state.addEquipment;
							console.log(addEquipment.equipmentName);
							if(!addEquipment?.equipmentName){
								this.setState({juder_3_1:'error'});
								return message.info('请选择设备');
							}
							if(!addEquipment?.model){
								this.setState({juder_3_2:'error'});
								return message.info('请选择型号规格');
							}
							if(!addEquipment?.startNumber){
								this.setState({juder_3_3:'error'});
								return message.info('请选择出厂编号');
							}
							var user = JSON.parse(sessionStorage.getItem("user"));

							addEquipment.creator=user.username;
							console.log(addEquipment);
							let menus = JSON.parse(localStorage.getItem('menus')) ?? [];
							if(menus.size!==0)addEquipment = Object.assign(addEquipment,{otherAreaId:menus[0]});



							addAlarmInfo(addEquipment).then(response=>{
								console.log(response);
								if(response.data.data===true){
									// funTraceInfo("报警器检定管理", "添加报警器", null,addEquipment);
									message.success("添加成功");
									this.setState({variableVisible_2:false});
									this.table.refreshTable();// 刷新

								}else{
									this.setState({variableVisible_2:false});
									message.success('测试失败');
									this.table.refreshTable();// 刷新
								}
							});
							// this.setState({variableVisible_2:false})
						}}           	// 点击确定
						onCancel={() => this.setState({variableVisible_2:false})}	// 点击取消
						width={900}
						centered
					>
						<div style={{clear:'both',height:'190px',padding:'0 10px',whiteSpace: 'nowrap'}}>

							<div style={{width:'100%',height:(this.state.juder_3_1||this.state.juder_3_2)?'50px':'40px'}}>

								<div style={{width:'40%',height:'100%',float:'left'}}>
									<div style={{width:'100%'}}>
										<div style={{width:'20%',height:'100%',paddingTop:'4px',float:'left'}}>
											<span style={{color:'red'}}>*</span>
											<span>设备名称:</span>
										</div>
										<div style={{width:'70%'}}>
											<Select
												showSearch
												status={this.state?.juder_3_1}
												style={{width:'100%'}}
												value={this.state.addEquipment.equipmentName}
												placeholder={'请选择设备名称'}
												options={this.state.select_equipmentName}
												fieldNames={ {label: 'value', value: 'key', children: 'children'} }
												onChange={(title,valueData)=>{

													var addEquipment = this.state.addEquipment;
													addEquipment.equipmentName=valueData.value;
													addEquipment.model=null;
													this.equipmentIdSelect(title,valueData);
													this.setState({addEquipment:addEquipment,juder_3_1:''})
												}}
											/>
										</div>
									</div>
									<div style={{width:'100%',
										margin:'-3px 0 0 20%',display:this.state?.juder_3_1?null:'none'
										// position:'absolute',top:100
									}}>
										<span style={{color:'red'}}>请选择设备名称</span>
									</div>
								</div>

								<div style={{width:'40%',float:'left'}}>
									<div style={{width:'100%',height:'100%'}}>
										<div style={{width:'20%',paddingTop:'4px',float:'left'}}>
											<span style={{color:'red'}}>*</span>
											<span>型号规格:</span>
										</div>
										<div style={{width:'70%'}}>
											<AutoComplete
												disabled={this.state.model_juder}
												loading={this.state.model_juder}
												showSearch
												style={{width:'100%'}}
												status={this.state?.juder_3_2}
												value={this.state.addEquipment.model}
												placeholder={'请选择型号规格'}
												options={this.state.select_model}
												filterOption={(inputValue, option) => option?.devModelName?.toUpperCase().indexOf(inputValue?.toUpperCase()) !== -1}
												fieldNames={ {label: 'devModelName', value: 'devModelId', children: 'children'} }
												onChange={(title,valueData,data)=>{

													var addEquipment = this.state.addEquipment;
													if(valueData?.devModelName){
														addEquipment.model=valueData.devModelName;
														addEquipment.manufacturers=valueData.manufacture;
													}else{
														addEquipment.model=title;
														// addEquipment.manufacturers=null;
													}


													this.setState({addEquipment:addEquipment,juder_3_2:''})
												}}
											/>
										</div>
									</div>
									<div style={{width:'100%',
										margin:'-3px 0 0 20%',display:this.state?.juder_3_2?null:'none'
										// position:'absolute',top:100
									}}>
										<span style={{color:'red'}}>请选择型号规格</span>
									</div>
								</div>
							</div>

							<div style={{width:'100%',height:(this.state.juder_3_3)?'50px':'40px'}}>
								<div style={{width:'40%',height:'100%',float:'left'}}>
									<div style={{width:'20%',paddingTop:'4px',float:'left'}}>
										<span style={{color:'red'}}>*</span>
										<span>出厂编号:</span>
									</div>
									<div style={{width:'70%'}}>
										<Input
											style={{width:'100%'}}
											status={this.state?.juder_3_3}
											value={this.state.addEquipment.startNumber}
											placeholder={'请输入出厂编号'}
											onChange={(title)=>{
												var addEquipment = this.state.addEquipment;
												addEquipment.startNumber=title.target.value;
												this.setState({addEquipment:addEquipment,juder_3_3:''});
											}}
										/>
									</div>
									<div style={{width:'100%',margin:'-3px 0 0 20%',
										display:this.state?.juder_3_3?null:'none'

									}}>
										<span style={{color:'red'}}>请选择出厂编号</span>
									</div>
								</div>

								<div style={{width:'40%',height:'40px',float:'left'}}>
									<div style={{width:'100%',height:'60%'}}>
										<div style={{width:'20%',height:'100%',paddingTop:'4px',float:'left'}}>
											<span>制造厂家:</span>
										</div>
										<div style={{width:'70%',height:'100%',float:'left'}}>
											<Input
												style={{width:'100%'}}
												// status={this.state?.juder_2_1}
												value={this.state.addEquipment.manufacturers}
												placeholder={'请输入公司名称'}
												onChange={(title)=>{
													var addEquipment = this.state.addEquipment;
													addEquipment.manufacturers=title.target.value;
													this.setState({addEquipment:addEquipment,juder_2_1:null});
												}}
											/>
										</div>
									</div>
								</div>
							</div>

							<div style={{width:'23%',height:'40px',float:'left'}}>
								<div style={{width:'100%',height:'60%'}}>
									<div style={{width:'35%',height:'100%',paddingTop:'4px',float:'left'}}>
										<span>省:</span>
									</div>
									<div style={{width:'60%',height:'100%',float:'left'}}>
										<Select showSearch
												style={{width:'100%'}}
												value={this.state.addEquipment.province}
												placeholder={'请选择省'}
												options={areaData}
												fieldNames={ {label: 'name', value: 'name', children: 'children'} }
												onChange={(title,valueData)=>{
													var addEquipment = this.state.addEquipment;
													addEquipment.province=title;
													addEquipment.city=null;
													addEquipment.district=null;

													this.setState({
														addEquipment:addEquipment,
														Equipment_city:valueData.city,
														Equipment_distric:[]
													});
												}}
										/>
									</div>
								</div>
							</div>
							<div style={{width:'18%',height:'40px',float:'left'}}>
								<div style={{width:'100%',height:'60%'}}>
									<div style={{width:'14%',height:'100%',paddingTop:'4px',float:'left'}}>
										<span>市:</span>
									</div>
									<div style={{width:'80%',height:'100%',float:'left'}}>
										<Select

												showSearch
												style={{width:'100%'}}
												value={this.state.addEquipment.city}
												placeholder={'请选择市'}
												options={this.state.Equipment_city || areaData[17].city}
												fieldNames={ {label: 'name', value: 'name', children: 'children'} }
												onChange={(title,valueData)=>{
													var addEquipment = this.state.addEquipment;
													addEquipment.city=title;
													addEquipment.district=null;

													this.state.Equipment_district=valueData.area;
													this.setState({addEquipment:addEquipment});
												}}
										/>
									</div>
								</div>
							</div>
							<div style={{width:'18%',height:'40px',float:'left'}}>
								<div style={{width:'100%',height:'60%'}}>
									<div style={{width:'14%',height:'100%',paddingTop:'4px',float:'left'}}>
										<span>区:</span>
									</div>
									<div style={{width:'80%',height:'100%',float:'left'}}>
										<Select
												showSearch
												style={{width:'100%'}}
												value={this.state.addEquipment.district}
												placeholder={'请选择市'}
												onChange={(title)=>{
													var addEquipment = this.state.addEquipment;
													addEquipment.district=title;
													this.setState({addEquipment:addEquipment});
												}}
										>
											{(this.state?.Equipment_district || areaData[17].city[0].area)?.map((name)=>
												<Option key={name} label={name}>{name}</Option>
											)}
										</Select>
									</div>
								</div>
							</div>
							<div style={{width:'40%',height:'40px',float:'left'}}>
								<div style={{width:'100%',height:'60%'}}>
									<div style={{width:'20%',height:'100%',paddingTop:'4px',float:'left'}}>
										<span>详细地址:</span>
									</div>
									<div style={{width:'80%',height:'100%',float:'left'}}>
										<Input
											style={{width:'100%'}}
											// status={this.state?.juder_2_1}
											value={this.state.addEquipment.details}
											placeholder={'请输入详细地址'}
											onChange={(title)=>{
												var addEquipment = this.state.addEquipment;
												addEquipment.details=title.target.value;
												this.setState({addEquipment:addEquipment,juder_2_1:null});
											}}
										/>
									</div>
								</div>
							</div>
							{/*<div style={{width:'100%',height:'40px',float:'left'}}>*/}
							{/*	<div style={{width:'100%',height:'60%'}}>*/}
							{/*		<div style={{width:'8%',height:'100%',paddingTop:'4px',float:'left'}}>*/}
							{/*			<span>检定日期:</span>*/}
							{/*		</div>*/}
							{/*		<div style={{width:'40%',height:'100%',float:'left'}}>*/}
							{/*			<DatePicker*/}
							{/*				style={{width:'60%'}}*/}
							{/*				showTime={{ format: 'HH:mm:ss' }}*/}
							{/*				format={'YYYY-MM-DD HH:mm:ss'}*/}
							{/*				// value={this.state.addEquipment.verifierDate}*/}
							{/*				placeholder={'请输入检定日期'}*/}
							{/*				onChange={(title,v)=>{*/}
							{/*					var addEquipment = this.state.addEquipment;*/}
							{/*					addEquipment.verifierDate=v;*/}
							{/*					this.setState({addEquipment:addEquipment});*/}
							{/*				}}*/}
							{/*			/>*/}
							{/*		</div>*/}
							{/*	</div>*/}
							{/*</div>*/}
						</div>
					</Modal>



					{/*-----------------------------------------新--添加委托单位---------------------------------------*/}
					<Modal
						title="添加委托单位"
						maskClosable={false}
                        style={{ top: 0 }}
                        visible={this.state.variableVisible_1}
                        okText="确定"
                        onOk={() =>{
							var addCustomerData =this.state.addCustomerData;

							if(addCustomerData?.customerUnit===null || addCustomerData?.customerUnit ===''){
								this.setState({juder_2_1:'error'});
								return message.info('委托单位不能为空');
							}
							// 循环已获取的用单位列表避免重复创建
							for(var i=0;i<this.state.customerData[0].selectInput.length;i++){
								if(this.state.customerData[0].selectInput[i].customerUnit===addCustomerData.customerUnit){
									this.setState({juder_2_1:'error'});
									return message.info('该委托单位已存在')
								}
							}
							if(addCustomerData?.companyType===null || addCustomerData?.companyType ===''){
								this.setState({juder_companyType:'error'});
								return message.info('客户类型不能为空');
							}

							if(addCustomerData?.principal===null || addCustomerData?.principal ===''){
								this.setState({juder_2_2:'error'});
								return message.info('联系人不能为空');
							}


							insertCustomer(addCustomerData).then(data=>{

								if(data?.data?.data){
									// funTraceInfo("报警器检定管理", "新增委托单位", null,addCustomerData)
									message.info("添加成功");
									this.getCustomer();

									var customerData = this.state.customerData;
									customerData[0].data=addCustomerData.customerUnit;
									customerData[1].data=addCustomerData.province;
									customerData[2].data=addCustomerData.principalPhone;
									customerData[3].data=addCustomerData.province;
									customerData[4].data=addCustomerData.city;
									customerData[5].data=addCustomerData.district;
									customerData[6].data=addCustomerData.details;

									this.setState({variableVisible_1:false,customerData:customerData});// 点击确定
									this.table.refreshTable();// 刷新表格

								}else{

									message.info('添加失败:'+data.data.responseInfo.description);
								}

							});

						}}
                        onCancel={() => {

							this.setState({variableVisible_1:false})
						}

                        }       // 点击取消
                        width={850}
                        centered
                    >
						<div style={{clear:'both',padding:'0 10px',whiteSpace: 'nowrap'}}>
							{/*第一层*/}
							<div style={{width:'100%',height:'30px'}}>
								<div style={{width:'35%',float:'left',}}>
									<div style={{width:'25%',float:'left'}}>
										<span style={{fontWeight: 'bold'}}><span style={{color:'red'}}>*</span>委托单位:</span>
									</div>
									<div style={{width:'68%',float:'left'}}>
										<Input
											style={{width:'100%'}}
											size={'small'}
											status={this.state?.juder_2_1}
											value={this.state.addCustomerData.customerUnit}
											placeholder={'请输入委托单位'}
											onChange={(title)=>{
												var addCustomerData = this.state.addCustomerData;
												addCustomerData.customerUnit=title.target.value;
												this.setState({addCustomerData:addCustomerData,juder_2_1:null});
											}}
										/>

									</div>
								</div>
								<div style={{width:'40%',float:'left',marginLeft:'30px'}}>
									<div style={{width:'22%',float:'left'}}>
										<span style={{fontWeight: 'bold'}}><span style={{color:'red'}}>*</span>客户类型:</span>
									</div>
									<div style={{width:'70%',float:'left'}}>
										<div style={{width:'85%',float:'left'}}>
											<Select
												style={{width:'100%'}}
												size={'small'}
												status={this.state?.juder_companyType}
												value={this.state.addCustomerData.companyTypeName}
												placeholder={'请输入委托单位'}
												onChange={(title,e)=>{

													var addCustomerData = this.state.addCustomerData;
													addCustomerData.companyType=e.key;
													this.setState({companyTypeName:title,addCustomerData:addCustomerData,juder_companyType:null});
												}}
											>
												<option key={1} value={'居民客户'}>居民客户</option>
												<option key={2} value={'工商客户'}>工商客户</option>
											</Select>
										</div>

									</div>
								</div>
							</div>
							{/*第二层*/}
							<div style={{width:'100%',height:'30px'}}>
								<div style={{width:'40%',float:'left',}}>
									<div style={{width:'22%',float:'left'}}><span style={{fontWeight: 'bold'}}><span style={{color:'red'}}>*</span>联系人:</span></div>
									<div style={{width:'60%',float:'left'}}>
										<Input size={'small'}
											   status={this.state?.juder_2_2}
											   value={this.state.addCustomerData.principal}
											   placeholder={'请输入联系人'}
											   onChange={(title)=>{
												   var addCustomerData = this.state.addCustomerData;
												   addCustomerData.principal=title.target.value;
												   this.setState({addCustomerData:addCustomerData,juder_2_2:null});
											   }}
										/>
									</div>
								</div>
								<div style={{width:'40%',float:'left',}}>
									<div style={{width:'20%',float:'left'}}>
										<span style={{fontWeight: 'bold'}}>联系电话:</span>
									</div>
									<div style={{width:'60%',float:'left'}}>
										<Input size={'small'}
											   value={this.state.addCustomerData.principalPhone}
											   placeholder={'请输入联系人'}
											   status={this.state.juder_2_3}
											   onChange={(title)=>{
												   if(!/^[1]([3-9])[0-9]{9}$/g.test(title.target.value)) {
													   this.state.juder_2_3='error';
												   }else{
													   this.state.juder_2_3=null;
												   }
												   var addCustomerData = this.state.addCustomerData;
												   addCustomerData.principalPhone=title.target.value;
												   this.setState({addCustomerData:addCustomerData});
											   }}
										/>
									</div>
								</div>
							</div>
							{/*第三层*/}
							<div style={{width:'100%',height:'30px'}}>
								<div style={{width:'22%',float:'left',}}>
									<div style={{width:'40%',float:'left'}}>
										<span style={{fontWeight: 'bold'}}>省:</span>
									</div>
									<div style={{width:'58%',float:'left'}}>
										<Select size={'small'}
												showSearch
												style={{width:'100%'}}
												value={this.state.addCustomerData.province}
												placeholder={'请选择省'}
												options={areaData}
												fieldNames={ {label: 'name', value: 'name', children: 'children'} }
												onChange={(title,valueData)=>{
													var customerData = this.state.customerData_1;
													var addCustomerData = this.state.addCustomerData;
													addCustomerData.province=title;
													addCustomerData.city=null;
													addCustomerData.district=null;
													this.state.add_city=valueData.city;
													this.state.add_district=[];
													this.setState({customerData_1:customerData,addCustomerData:addCustomerData});
												}}
										/>
									</div>
								</div>
								<div style={{width:'16%',float:'left',}}>
									<div style={{width:'15%',float:'left'}}>
										<span style={{fontWeight: 'bold'}}>市:</span>
									</div>
									<div style={{width:'80%',float:'left'}}>
										<Select size={'small'}
												showSearch
												style={{width:'100%'}}
												value={this.state.addCustomerData.city}
												placeholder={'请选择市'}
												options={this.state.add_city}
												fieldNames={ {label: 'name', value: 'name', children: 'children'} }
												onChange={(title,valueData)=>{
													var customerData = this.state.customerData_1;
													var addCustomerData = this.state.addCustomerData;
													addCustomerData.city=title;
													addCustomerData.district=null;

													this.state.add_district=valueData.area;
													this.setState({customerData_1:customerData,addCustomerData:addCustomerData});
												}}
										/>
									</div>
								</div>
								<div style={{width:'16%',float:'left',}}>
									<div style={{width:'15%',float:'left'}}><span style={{fontWeight: 'bold'}}>区:</span></div>
									<div style={{width:'80%',float:'left'}}>
										<Select size={'small'}
												showSearch
												style={{width:'100%'}}
												value={this.state.addCustomerData.district}
												placeholder={'请选择市'}
												onChange={(title)=>{
													var addCustomerData = this.state.addCustomerData;
													addCustomerData.district=title;
													this.setState({addCustomerData:addCustomerData});
												}}
										>
											{(this.state.add_district || areaData[17].city[0].area)?.map((name)=>
												<Option key={name} label={name}>{name}</Option>
											)}
										</Select>
									</div>
								</div>
								<div style={{width:'35%',float:'left',}}>
									<div style={{width:'25%',float:'left'}}>
										<span style={{fontWeight: 'bold'}}>详情地址:</span>
									</div>
									<div style={{width:'75%',float:'left'}}>
										<Input size={'small'}
											   value={this.state.addCustomerData.details}
											   placeholder={'请输入详情地址'}
											   onChange={(title)=>{
												   var addCustomerData = this.state.addCustomerData;
												   addCustomerData.details=title.target.value;
												   this.setState({addCustomerData:addCustomerData});
											   }}
										/>
									</div>
								</div>
							</div>
						</div>
                    </Modal>
				</div>


            </Layout>
        )
    }
}
