import React, { Component } from 'react'
import {
    Descriptions,
    Tag,
    Image,
    Timeline,
    Form,
    Input,
    TreeSelect,
    Upload,
    Button,
    Tooltip,
    Modal,
    Checkbox, message, Popconfirm
} from 'antd';

import './workOrderDetails.less'
import { PlusOutlined, BellOutlined, UserAddOutlined, SettingOutlined,ArrowLeftOutlined,FileImageOutlined,FileUnknownOutlined,DownloadOutlined,FileTextOutlined} from '@ant-design/icons';
import FormsTwo from "../../components/common/forms/FormsTwo";
import {FORM_ITEM, TYPE_NAME} from "../../components/Const";
import {
    queryPageComplaintTypes,
    queryPageHandler,
    queryPicsInfoByGroupId,
    queryProcessRecordByWorkId,
    urging,
    workAllocation
} from "../../services/meter";
import moment from "moment";
const { TextArea } = Input;
export default class workOrderDetails extends Component {
    state={

        fromData:this.props.location.state.rowData,

        fileUrl:'http://47.97.117.248:8352/ossManagement/ossUpload',
        // 图片
        imageDataS:[{n:null,u:null,t:null}],
        // 表单参数
        formParamenter: {
            modal: true,
            number: 1,
            labelCol: 8,
            layout: "vertical",
            parameter: [

                {variableName: 'grade', str: '紧急程度', rules: TYPE_NAME.REQ_SELECT,
                    data:[
                        {key:'0',value:'全部'},
                        {key:'1',value:'高'},
                        {key:'2',value:'中'},
                        {key:'3',value:'低'}
                    ]
                },
                {variableName: 'region1', str: '处理结果', rules: TYPE_NAME.INPUT,},
                {variableName: 'region2', str: '解决方案', rules: TYPE_NAME.INPUT,},
                {variableName: 'region3', str: '上传处理凭证', rules: TYPE_NAME.UPLOAD_IMAGE,},
                {variableName: 'region4', str: '备注说明', rules: TYPE_NAME.INPUT,},
            ]
        },
        // 弹窗-表单参数
        formParam_1: {
            modal: true,
            number: 1,
            labelCol: 7,
            layout: "vertical",
            parameter: [
                {variableName: 'id', str: '工单编号',disabled:true,rules: TYPE_NAME.NOT_BLANK,},
                {variableName: 'complaintContent', str: '工单内容',disabled:true, rules: TYPE_NAME.NODESCRIPTION,autoSize:{ minRows: 3, maxRows: 5 }},
                {variableName: 'assignee', str: '选择处理人', rules: TYPE_NAME.REQ_SELECT,
                    data:[{key:'0',value:'未分配'}]
                },
                {variableName: 'emergencyLevel', str: '处理优先级', rules: TYPE_NAME.REQ_SELECT,
                    data:[
                        {key:'high',value:'高'},
                        {key:'medium',value:'中'},
                        {key:'low',value:'低'},
                    ]
                },
                {variableName: 'expectedProcessTime', str: '预计完成时间', rules: TYPE_NAME.DATE_TIME_FORMAT},
                {variableName: 'processNote', str: '分配说明', rules: TYPE_NAME.NODESCRIPTION,autoSize:{ minRows: 3, maxRows: 5 }},
            ]
        },

        processRecords:[], // 处理记录

        windowSwitch_1:false,
        loading_1:false,
    };

    // 初始化
    componentDidMount(): void {
        this.queryPicsInfoByGroupId();      // 根据图片组ID查询图片
        this.queryPageHandler();            // 查询所有的处理人
        this.queryProcessRecordByWorkId();  // 根据工单ID查询工单记录
        this.queryPageComplaintTypes();     // 分页查询所有投诉类型
    }

    // 分页查询所有投诉类型
    queryPageComplaintTypes= () =>{
        var param = {
            page:1,
            size:999
        };
        queryPageComplaintTypes(param).then(
            response => {
                if(response?.data?.data){
                    const {data} = response.data;
                    
                }
            })
    };


    // 投诉类型-中文
    complaintTypeInCNE=()=>{

    };


    // 根据工单ID查询工单记录
    queryProcessRecordByWorkId =()=>{

        var workId = this.state.fromData?.id;
        if(!workId){return false;}
        queryProcessRecordByWorkId({workId}).then(
            response=>{
                if(response?.data?.data){
                    this.setState({processRecords:response?.data?.data??[]})
                }
            }
        );
    };




    // 查询所有的处理人
    queryPageHandler=()=>{
        var param = {
            page:1,
            size:999
        };
        queryPageHandler(param).then(
            response=>{
                console.log(response);
                if(response?.data?.data){
                    var formParam1 = this.state.formParam_1;
                    formParam1.parameter[2].data=[];
                    response?.data?.data.map(one=>{
                        formParam1.parameter[2].data.push(
                            Object.assign(one,{key:one?.id??null, value:one.name??null,label:one.name??null}));
                    });
                    this.setState({
                        formParam_1:formParam1,
                    })
                }
            }
        )
    };
    // 根据图片组ID查询图片
    queryPicsInfoByGroupId =()=>{
        queryPicsInfoByGroupId({picGroupId:this.props.location.state.rowData.picGroupsId}).then(response=>{
            if(response?.data?.data){
                var imageDataS = this.state.imageDataS;
                imageDataS=[];
                var data = response?.data?.data;
                data.map(one=>{
                    if(one?.imgUrl){
                        var imgUrl = one?.imgUrl;
                        var i=0;
                        //获取名字
                        while (i<10){
                            i++;
                            if(imgUrl.indexOf('/')!==-1){
                                imgUrl=imgUrl.substring(imgUrl.indexOf('/')+1,imgUrl.length);
                            }else{i=11;}
                        }
                        // 获取类型
                        var type=null;
                        if(imgUrl.indexOf('txt')!==-1){
                            type='txt';
                        }else if((imgUrl.indexOf('png')!==-1) && (imgUrl.indexOf('jpg')!==-1)){
                            type='jpg';
                        }
                        imageDataS.push({n:imgUrl,u:one?.imgUrl,t:type});
                    }
                });
                console.log(imageDataS);
                this.setState({imageDataS:imageDataS});
            }
        })
    };
    // 工单分配
    workAllocation = async ()=> {
        let value = await this.forms1.onFinish();
        if(!value){
            this.setState({loading_1:false});
            return false;
        }
        var param = {
            "workOrderId": value?.id??null,                  // 被分配的工单编号，外键关联 work_order 表
            "assignTime": new Date(),                        // 工单分配时间，默认值为当前时间
            "assigneeId": value?.assignee??null,             // 工单处理者的用户 ID，外键关联 user 表
            "priority": value?.emergencyLevel??null,         // 优先级
            "operationId": JSON.parse(sessionStorage.getItem("user")).id??null, // 操作人ID

            "expectedProcessTime": value?.expectedProcessTime??null, // 预计处理该工单的时间
            "assignmentNote": value?.processNote??null,              // 工单分配说明
            "sms": this.state?.sms??false,            // 短信通知
            "system": this.state?.system??false,      // 系统通知

            "id": null,                     // 分配记录的唯一标识，自增主键

            "phone": false,                 // 电话通知
            "transferOrder": false,         // 是否转单

        };

        workAllocation(param).then(response=>{
            this.setState({loading_2:false});
            if(response?.data?.data){
                message.info('提交成功');
                var fromData = this.state.fromData;
                var formParam1 = this.state.formParam_1;
                formParam1.parameter[2].data.map(one=>{
                    if(one.key===value?.assignee){
                        fromData.assignee = one?.value
                    }
                });

                fromData.expectedProcessTime =value?.expectedProcessTime??null;

                this.forms1.onModalClose();
                this.setState({windowSwitch_1:false,fromData:fromData,loading_1:false});
            }else{
                message.info('提交失败');
            }
        });
    };

    // 催办
    urging =(workOrderIds)=>{
        if(!workOrderIds){return false;}
        var param =  new URLSearchParams();
        param.append('operationId',JSON.parse(sessionStorage.getItem("user")).id??null);
        param.append('workOrderIds',workOrderIds?[workOrderIds]:null);
        urging(param).then(response=>{
            if(response?.data?.data){
                return message.info('催办成功');
            }else{
                return message.info('催办失败');
            }
        });
    };

    render(){
        const {fromData} = this.state;
        return(
            <div id={'workOrderDetails'}>
                {/*标题*/}
                <div className={'workOrderDetails_topSearchBox'}>
                    <div className={'workOrderDetails_topSearchBoxH3'}>
                        <h3 style={{float:'left'}}>处理工单 - GR20230615001</h3>
                    </div>
                    <div style={{float:'left'}}>
                        {fromData?.state === '待处理' ? <Tag color={'#1890ff'}>待处理</Tag> :
                            fromData?.state === '处理中' ? <Tag color={'#faad14'}>处理中</Tag> :
                                fromData?.state === '已超时' ? <Tag color={'#ff4d4f'}>已超时</Tag>:
                                    fromData?.state === '已完成' ? <Tag color={'#52c41a'}>已完成</Tag>:''}
                    </div>
                    {/*按钮*/}
                    <div style={{float:'right'}}>
                        <Button icon={<ArrowLeftOutlined />} onClick={()=>{this.props.history.go(-1)}}>返回</Button>
                        &nbsp;&nbsp;
                        <Button type="primary" icon={<SettingOutlined />} onClick={()=>{
                            console.log('========');
                            this.props.history.push({
                                pathname: '/workOrder/workOrdersProcess',
                                state: {rowData:fromData}
                            })
                        }}>处理工单</Button>
                        &nbsp;&nbsp;
                        <Button disabled={fromData?.assignee?'none':null} icon={<UserAddOutlined />}
                                onClick={async ()=>{

                                    await this.setState({windowSwitch_1:true,initialValues_1:fromData});
                                    this.forms1.onModalClose();
                                }}
                        >分配</Button>
                        &nbsp;&nbsp;
                        <Popconfirm
                            title="是否发出?"
                            onConfirm={()=>{this.urging(fromData?.id??null)}}
                            onCancel={()=>{return false;}}
                            okText="是"
                            cancelText="否"
                        >
                            <Button type="primary" danger icon={<BellOutlined />} onClick={()=>{}}>催办</Button>
                        </Popconfirm>

                    </div>

                </div>
{/*工单详情--------*/}
                <div className={'workOrderDetails_css_div_head_1'}>
                    <div className={'workOrderDetails_css_div_head_1_div_frame'}>
                        <div className="workOrderDetails_css_div_card-header">
                            <h3 className="workOrderDetails_css_div_card-title">工单详情</h3>
                        </div>
                        {/*表单*/}
                        <div style={{padding:'30px 30px 0 30px'}}>
                            <Descriptions labelStyle={{color:'#999999'}} column={3} layout="vertical">

                                <Descriptions.Item label="投诉人">{fromData.complainantName}</Descriptions.Item>
                                <Descriptions.Item label="联系电话">{fromData.contactPhone}</Descriptions.Item>
                                <Descriptions.Item label="所在区域">{fromData.area}</Descriptions.Item>

                                <Descriptions.Item label="详细地址">{fromData.address}</Descriptions.Item>
                                <Descriptions.Item label="创建时间">{fromData?.createdTime?moment(fromData?.createdTime).format('yyyy-MM-DD HH:MM'):null}</Descriptions.Item>
                                <Descriptions.Item label="紧急程度">
                                    {fromData?.emergencyLevel === 'high' ? <Tag color={'#ff4d4f'}>高</Tag> :
                                        fromData?.emergencyLevel === 'medium' ? <Tag color={'#faad14'}>中</Tag> :
                                            fromData?.emergencyLevel === 'low' ? <Tag color={'#52c41a'}>低</Tag>:''}
                                </Descriptions.Item>

                            </Descriptions>
                        </div>
                    </div>
                </div>

{/*投诉信息--------*/}
                <div className={'workOrderDetails_css_div_head_4'}>
                    <div className={'workOrderDetails_css_div_head_4_div_frame'}>
                        <div className="workOrderDetails_css_div_card-header">
                            <h3 className="workOrderDetails_css_div_card-title">投诉信息</h3>
                        </div>
                        {/*表单*/}
                        <div style={{padding:'30px 30px 0 30px'}}>
                            <Descriptions labelStyle={{color:'#999999'}} layout="vertical">

                                <Descriptions.Item label="投诉内容" span={3}>{fromData?.processNote??null}</Descriptions.Item>

                                <Descriptions.Item label="投诉类型">{fromData?.complaintType??null}</Descriptions.Item>
                                <Descriptions.Item label="处理人">{fromData.assignee??null}</Descriptions.Item>
                                <Descriptions.Item label="预计完成时间">{fromData?.expectedProcessTime?moment(fromData?.expectedProcessTime).format('yyyy-MM-DD HH:MM'):null}</Descriptions.Item>

                            </Descriptions>
                        </div>


                    </div>
                </div>


{/*处理记录--------*/}
                <div className={'workOrderDetails_css_div_head_2'}>
                    <div className={'workOrderDetails_css_div_head_2_div_frame'}>
                        <div className="workOrderDetails_css_div_card-header">
                            <h3 className="workOrderDetails_css_div_card-title">处理记录</h3>
                        </div>
                        <div style={{padding:'30px',height:'75%',overflowY: 'auto'}}>
                            <Timeline>
                                {this.state.processRecords.map(one=>{
                                    return(
                                        <Timeline.Item>
                                            <div>
                                                <span>{one?.recordTime??null}</span>
                                            </div>
                                            <div>
                                                <span>{one?.operationType??null}</span>
                                            </div>
                                        </Timeline.Item>
                                    )

                                })}

                            </Timeline>
                        </div>

                    </div>
                </div>
{/*附件信息----------*/}
                <div className={'workOrderDetails_css_div_head_5'}>
                    <div className={'workOrderDetails_css_div_head_5_div_frame'}>
                        <div className="workOrderDetails_css_div_card-header">
                            <h3 className="workOrderDetails_css_div_card-title">附件信息</h3>
                        </div>
                        <div style={{padding:'0 20px 0 20px'}}>
                            <Descriptions labelStyle={{color:'#999999'}} column={3} layout="vertical">

                                {this.state.imageDataS.map(one=>{
                                    return(
                                        <Descriptions.Item>
                                            <div style={{width:'230px',height:'100px',padding:'20px 0 0 0',
                                                textAlign: 'center',backgroundColor:'#fbfbfb',borderRadius: '3px', border: '1px #eaeaea solid',
                                                boxShadow: '0 1px 2px -2px rgba(0, 0, 0, 0.16), 0 3px 6px 0 rgba(0, 0, 0, 0.12)'
                                            }}>
                                                {one?.t?one?.t.indexOf('txt')!==-1
                                                    ? <FileTextOutlined style={{fontSize:'63px',color:'#4a8fd9'}}/> // 文件
                                                    :<FileImageOutlined style={{fontSize:'63px',color:'#4a8fd9'}}/> // 图片
                                                    :<FileUnknownOutlined style={{fontSize:'63px',color:'#4a8fd9'}}/>// 未知
                                                }
                                                <Tooltip title={one.n}>
                                                    <div className={'workOrderDetails_text_div_css_1'} style={{width:'80px'}}>
                                                        <span>{one.n}</span>
                                                    </div>
                                                </Tooltip>
                                                <div className={'workOrderDetails_Button_div_css_1'}  style={{width:'60px',padding:"2px"}}

                                                     onClick={()=>{

                                                     }}
                                                >

                                                    <DownloadOutlined />
                                                </div>

                                            </div>&nbsp;&nbsp;
                                        </Descriptions.Item>

                                    );

                                })}



                            </Descriptions>


                        </div>
                    </div>
                </div>







{/*处理结果--------*/}
                <div className={'workOrderDetails_css_div_head_3'} style={{display:'none'}}>
                    <div className={'workOrderDetails_css_div_head_3_div_frame'}>
                        <div className="workOrderDetails_css_div_card-header">
                            <h3 className="workOrderDetails_css_div_card-title">处理结果</h3>
                        </div>
                        <div style={{padding:'30px'}}>
                            <Form
                                labelCol={{ span: 4 }}
                                wrapperCol={{ span: 24 }}
                                layout="vertical"
                            >
                                <Form.Item name="name" label="处理状态" rules={[{ required: true, message: '请选择处置状态'}]}>
                                    <TreeSelect onChange={(e,v) => {}}
                                                showSearch
                                                style={{ width: '100%' }}
                                                dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                                                placeholder={'请选择处置状态'}
                                                allowClear
                                    >
                                        <option label={'继续处理'} value={1}>继续处理</option>
                                        <option label={'继续处理'} value={2}>完成处理</option>
                                        <option label={'继续处理'} value={3}>暂停处理</option>
                                        <option label={'继续处理'} value={4}>转单处理</option>
                                    </TreeSelect>
                                </Form.Item>
                                <Form.Item name="name" label="处理结果" rules={[{ required: true }]}>
                                    <TextArea
                                        // value={value}
                                        onChange={(e) => {}}
                                        placeholder="请详细描述处理和结果"
                                        autoSize={{ minRows: 3, maxRows: 5 }}
                                    />
                                </Form.Item>
                                <Form.Item name="name" label="解决方案" rules={[{ required: true }]}>
                                    <TextArea
                                        // value={value}
                                        onChange={(e) => {}}
                                        placeholder="如果需要后续跟进，请填写解决方案"
                                        autoSize={{ minRows: 3, maxRows: 5 }}
                                    />
                                </Form.Item>
                                <Form.Item name="name" label="上传处理凭证" rules={[{ required: true }]}>
                                    <Upload action={this.state.fileUrl} listType="picture-card">
                                        <button
                                            style={{ color: 'inherit', cursor: 'inherit', border: 0, background: 'none' }}
                                            type="button"
                                        >
                                            <PlusOutlined />
                                            <div style={{ marginTop: 5 }}>Upload</div>
                                        </button>
                                    </Upload>
                                </Form.Item>
                                <Form.Item name="name" label="备注说明" rules={[{ required: true }]}>
                                    <TextArea
                                        // value={value}
                                        onChange={(e) => {}}
                                        placeholder="其他需要说明的情况"
                                        autoSize={{ minRows: 3, maxRows: 5 }}
                                    />
                                </Form.Item>
                            </Form>
                        </div>
                    </div>
                </div>
{/*工单分配-弹窗-------*/}
                <Modal
                    title='工单分配'
                    style={{ top: 0 }}
                    visible={this.state.windowSwitch_1}
                    okText="确定"
                    // 点击确定
                    onOk={ () => {this.setState({windowSwitch_1:false});}}
                    // 点击取消
                    onCancel={() => {this.setState({windowSwitch_1:false});}}
                    width={500}
                    centered
                    footer={[
                        <Button key="back" onClick={()=>{
                            this.forms1.onModalClose();
                            this.setState({windowSwitch_1:false});
                        }}
                        >返回</Button>,
                        <Button key="submit" type="primary"
                                loading={this.state.loading_1}
                                onClick={()=>{
                                    this.setState({loading_1:true});
                                    this.workAllocation();
                                }}
                        >提交</Button>
                    ]}

                >
                    <div style={{width:'100%',height:'100%'}}>
                        <FormsTwo
                            ref={e => this.forms1 = e}
                            formParamenter={this.state.formParam_1}
                            data={this.state.initialValues_1}
                        />
                        <Checkbox onChange={(v)=>{this.setState({system:v.target.checked});}}>系统通知</Checkbox>
                        <Checkbox onChange={(v)=>{this.setState({sms:v.target.checked});}}>短信通知</Checkbox>
                    </div>
                </Modal>




            </div>
        )
    }
}
