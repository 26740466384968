import React, { Component } from 'react'
import { NavLink } from "react-router-dom"
import { Layout, Modal, Radio, message, Tag } from 'antd'
import {FORM_ITEM, TYPE_NAME} from '../../components/Const'

import TableComponents from '../../components/common/tables/TableComponents'
import {getDtoDataPage1, queryPushRecord} from '../../services/pushData'
import moment from "moment";
export default class YongZhouInstrumentInstallation extends Component {

    state = {
		// 表格参数
		tableParamenter: {
			manageName: "永州表具安装台帐",
			// 是否绑定左侧区域
			bindArea: false,
			// 表格行是否可选
            rowSelection: false,
			isNotInitData:true,// 是否停止默认获取数据
			// 获取表格参数数据
			localStorageName: 'YongZhouInstrumentInstallation',
			//下拉框长度
			selectWidth: '7.5vw',

			arr:[12],
			// 表头：变量和变量解释
			fileName: '永州表具安装台帐',
			header: {
				fullName: '姓名',
				meterNo: '表具条码(表钢号)',
				meterType: '计量类型',	// 1:金额
				phoneNumber: '电话或手机',
				state: '状态',
				createTime:'创建日期',
				userType: '是否民用',	// 民用: 0，非民用: 1
				address: '地址',
			},

			// 条件选择器参数
			conditionsParamenter: {
				// 条件
				parameter: [
					{
						type: TYPE_NAME.NAME,
						variableName: 'meterNo',
						str: '表具条码',
						initData: JSON.parse(localStorage.getItem('YongZhouPushRecord'))?.pushName
					},
					{
						type: FORM_ITEM.RANGE_PICKER,
						variableName: 'createTime',
						str: '创建日期',
						initData: JSON.parse(localStorage.getItem('YongZhouPushRecord'))?.businessCooperationNum
					},
				],

				// 条件查询
				onchange:(params)=>{
					this.onchangeMethod(params);
				},

				// 分页查询
				pageChange:(params)=>{
					this.onchangeMethod(params);
				}

			},
			params:{
				pageNumber: 1,
				pageSize: 12,
				page: 1,
				size: 12
			}
		},
	};

    componentDidMount(): void {
		this.table.clearConditionOne();
		this.onchangeMethod({page:1,size:12});
	}


	onchangeMethod= (params)=>{
		console.log(params);
		var param =  new URLSearchParams();

		param.append('pageNumber',params?.page??1);
		param.append('pageSize',params?.size??12);
		if(params?.meterNo){
			param.append('meterNo',params.meterNo??null);
		}
		if(params?.createTime){
			// param.append('startTime',params?.dateTime[0]?._d);
			// param.append('endTime',params?.dateTime[1]?._d);
			param.append('startTime',moment(params?.createTime[0]?._d).format('YYYY-MM-DD'));
			param.append('endTime',moment(params?.createTime[1]?._d).format('YYYY-MM-DD'));
		}
		getDtoDataPage1(param).then(
			response=>{
				if(response?.data?.data){
					this.table.setTableData('data',response?.data?.data.records);
					this.table.setTableData('totalCount',response?.data?.data.total);
				}else {
					this.table.setTableData('data',[]);
					this.table.setTableData('totalCount',0);
				}

			}
		)
	};



	setTableValue = (value,text,rowData) => {
		// meterType: '计量类型',	// 1:金额
		// userType: '是否民用',	// 民用: 0，非民用: 1

		switch (value) {
			case 'meterType':
				var title1=text?text.toString().indexOf('1')!==-1?'金额':'-':"-";
				return <>{<div className={'table_overflow'} title={title1}>{title1}</div>}</>;
			case 'userType':
				var title2=text?text.toString().indexOf('0')!==-1?'民用':'非民用':"-";
				return <>{<div className={'table_overflow'} title={title2}>{title2}</div>}</>;
			default:
				return text ? <div className={'table_overflow'} title={text}>{text}</div> : ''
		}
	};

    render() {

        return (
            <Layout className={'item_body_number_back tables'} style={{backgroundColor: '#ffffff'}}>
				<TableComponents
					paramenter={this.state.tableParamenter}
					setData={getDtoDataPage1}
					setTableValue={this.setTableValue}
					ref={e => this.table = e}
				>
				</TableComponents>
            </Layout>
        )
    }
}
