import React, { Component } from 'react'
import {
    Descriptions,
    Tag,
    Image,
    Timeline,
    Form,
    Input,
    TreeSelect,
    Upload,
    Button,
    Tooltip,
    Modal,
    Checkbox, message, Popconfirm
} from 'antd';

import './workOrdersProcess.less'
import { PlusOutlined, BellOutlined, UserAddOutlined, UploadOutlined,ArrowLeftOutlined,FileImageOutlined,FileUnknownOutlined,DownloadOutlined,FileTextOutlined} from '@ant-design/icons';
import FormsTwo from "../../components/common/forms/FormsTwo";
import {FORM_ITEM, TYPE_NAME} from "../../components/Const";
import {
    processWork, queryPageComplaintTypes,
    queryPageHandler,
    queryPicsInfoByGroupId,
    queryProcessRecordByWorkId,
    urging,
    workAllocation
} from "../../services/meter";
import moment from "moment";
const { TextArea } = Input;
export default class workOrdersProcess extends Component {
    state={
        // 上传参数信息
        fileUrl:'http://47.97.117.248:8352/ossManagement/ossUpload',
        fileList: [], // 设备照片
        // 表单数据
        fromData:this.props.location.state.rowData,
        // 图片
        imageDataS:[{n:null,u:null,t:null}],

        formParam_1: {
            modal: true,
            number: 1,
            labelCol: 0,
            layout: "vertical",
            parameter: [
                {variableName: 'finalStatus', str: '处理状态', rules: TYPE_NAME.REQ_SELECT,
                    data:[
                        {key:'继续处理',value:'继续处理'},
                        {key:'完成处理',value:'完成处理'},
                        {key:'暂停处理',value:'暂停处理'},
                        {key:'转单处理',value:'转单处理'}
                    ]
                },
                {variableName: 'resultDescription', str: '处理结果', rules: TYPE_NAME.TEXT_AREA,autoSize:{ minRows: 3, maxRows: 5 }},
                {variableName: 'solution', str: '解决方案', rules: TYPE_NAME.TEXT_AREA,autoSize:{ minRows: 3, maxRows: 5 }},
                {variableName: 'note', str: '备注说明', rules: TYPE_NAME.TEXT_AREA,autoSize:{ minRows: 3, maxRows: 5 }},
            ]
        },

        processRecords:[],  // 处理记录
        personChargeS:[],   // 负责人
        ComplaintTypes:[],  // 投诉类型

        loading_1:false,    // 提交加载开关
    };

    // 初始化
    componentDidMount(): void {
        console.log(this.state.fromData);
        this.queryPicsInfoByGroupId();      // 根据图片组ID查询图片
        this.queryProcessRecordByWorkId();  // 根据工单ID查询工单记录
        this.queryPageHandler();            // 查询所有的处理人
        this.queryPageComplaintTypes();     // 分页查询所有投诉类型
    }
    // 查询所有的处理人
    queryPageHandler=()=>{
        var param = {page:1, size:999};
        queryPageHandler(param).then(
            response=>{
                if(response?.data?.data){
                    this.setState({personChargeS:response?.data?.data??[]})
                }
            }
        )
    };

    // 分页查询所有投诉类型
    queryPageComplaintTypes= () =>{
        var param = {
            page:1,
            size:999
        };
        queryPageComplaintTypes(param).then(
            response => {
                if(response?.data?.data){
                    const {data} = response.data;
                    this.setState({ComplaintTypes:data??[]})
                }

            }
        )
    };
    // 通过id查找投诉类型名称
    ComplaintTypesById=(id)=>{
        var ComplaintTypes = this.state.ComplaintTypes;
        var name='';
        ComplaintTypes.map(one=>{
            if(one.id===id){
                name=one?.typeName??''
            }
        });

        return name;
    };
    // 通过id查找投诉类型名称
    ComplaintTypesById_1=(id)=>{
        var ComplaintTypes = this.state.ComplaintTypes;
        var name='';
        ComplaintTypes.map(one=>{
            if(one.id===id){
                name=one?.processingTimeLimit??''
            }
        });

        return name;
    };
    // 通过id查找对应名称
    queryPageHandlerById=(operationId)=>{
        var personChargeS = this.state.personChargeS;
        var name='系统';
        personChargeS.map(one=>{
            if(one.userId===operationId){
                name=one?.name??'系统'
            }
        });
        return name;
    };

    // 根据工单ID查询工单记录
    queryProcessRecordByWorkId =()=>{

        var workId = this.state.fromData?.id;
        if(!workId){return false;}
        queryProcessRecordByWorkId({workId}).then(
            response=>{
                if(response?.data?.data){
                    this.setState({processRecords:response?.data?.data??[]})
                }
            }
        );
    };

    // 根据图片组ID查询图片
    queryPicsInfoByGroupId =()=>{

        queryPicsInfoByGroupId({picGroupId:this.props.location.state.rowData.picGroupsId}).then(response=>{
            if(response?.data?.data){
                var imageDataS = this.state.imageDataS;
                imageDataS=[];
                var data = response?.data?.data;
                data.map(one=>{
                    if(one?.imgUrl){
                        var imgUrl = one?.imgUrl;
                        var i=0;
                        //获取名字
                        while (i<10){
                            i++;
                            if(imgUrl.indexOf('/')!==-1){
                                imgUrl=imgUrl.substring(imgUrl.indexOf('/')+1,imgUrl.length);
                            }else{i=11;}
                        }
                        // 获取类型
                        var type=null;
                        if(imgUrl.indexOf('txt')!==-1){
                            type='txt';
                        }else if((imgUrl.indexOf('png')!==-1) && (imgUrl.indexOf('jpg')!==-1)){
                            type='jpg';
                        }
                        imageDataS.push({n:imgUrl,u:one?.imgUrl,t:type});
                    }
                });
                console.log(imageDataS);
                this.setState({imageDataS:imageDataS});
            }
        })
    };
    // 照片点击事件
    onChangeUpload =  file => {
        if (file.file.status !== 'uploading') {
        }
        if (file.file.status === 'done') {
            message.success(`${file.file.name} 上传成功`);
        } else if (file.file.status === 'error') {
            message.error(`${file.file.name} 上传异常.`);
        }
        console.log(file.fileList);
        this.setState({fileList:file.fileList});
    };
    processWork = async ()=> {
        var formData = await this.forms1.onFinishNotReset();
        if(!formData){return false;}
        this.setState({loading_1:true});
        var fileList = this.state.fileList;
        var param = Object.assign({},formData,
            {
                operationId:JSON.parse(sessionStorage.getItem("user")).id??null,
                workOrderId:this.state.fromData?.id??null,
                picGroupsId:[]
            });
        fileList.map(one=>{param.picGroupsId.push(one?.response??null)});
        processWork(param).then(
            response=>{
                if(response?.data?.data){
                    message.info('提交成功');
                    this.queryProcessRecordByWorkId();
                }else{
                    message.info('提交失败');
                }
                this.setState({loading_1:false});
        })
    };


    // 描述类型-1  新建工单
    desType_1=(data)=>{
        return(
            <div>
                <div>
                    <span>{data?.operationType??null}</span>
                </div>
                <div>
                    <span>{this.queryPageHandlerById(data.operationId)}{data?.operationType??null}</span>
                </div>
                <div>
                    <span style={{fontSize:'14px',color:'#999999'}}>创建人:&nbsp;{this.queryPageHandlerById(data.operationId)}&nbsp;&nbsp;&nbsp;
                        工单类型:&nbsp;{this.ComplaintTypesById(this.state.fromData?.complaintType)}
                    </span>
                </div>
            </div>
        );
    };
    // 描述类型-2  工单分配
    desType_2=(data)=>{
        return(
            <div>
                <div>
                    <span>{data?.operationType??null}</span>
                </div>
                <div>
                    <span>{this.queryPageHandlerById(data.operationId)}{data?.operationType??null}</span>
                </div>
                <div>
                    <span style={{fontSize:'14px',color:'#999999'}}>
                        操作人:&nbsp;{this.queryPageHandlerById(data.operationId)}&nbsp;&nbsp;&nbsp;
                        优先级:&nbsp;{
                        this.state.fromData?.emergencyLevel === 'high' ? '高' :
                            this.state.fromData?.emergencyLevel === 'medium' ? '中' :
                                this.state. fromData?.emergencyLevel === 'low' ? '低':''
                    }&nbsp;&nbsp;&nbsp;
                        预计完成时间:&nbsp;{this.state.fromData?.expectedProcessTime}
                    </span>
                </div>
            </div>
        );
    };

    // 描述类型-3  工单分配
    desType_3=(data)=>{
        return(
            <div>
                <div>
                    <span>{data?.operationType??null}</span>
                </div>
                <div>
                    <span>{this.queryPageHandlerById(data.operationId)}{data?.operationType??null}</span>
                </div>
                <div>
                    <span style={{fontSize:'14px',color:'#999999'}}>
                        处理人:&nbsp;{this.queryPageHandlerById(data.operationId)}&nbsp;&nbsp;&nbsp;
                        处理时长:&nbsp;{this.ComplaintTypesById_1(this.state.fromData.complaintType)}&nbsp;&nbsp;&nbsp;
                        状态:&nbsp;{data?.state}
                    </span>
                </div>
            </div>
        );
    };

    render(){
        const {fromData} = this.state;
        return(
            <div id={'workOrdersProcess'}>
                {/*标题*/}
                <div className={'workOrdersProcess_topSearchBox'}>
                    <div className={'workOrdersProcess_topSearchBoxH3'}>
                        <h3 style={{float:'left'}}>处理工单 - GR20230615001</h3>
                    </div>
                    {/*按钮*/}
                    <div style={{float:'right'}}>
                        <Button icon={<ArrowLeftOutlined />} onClick={()=>{this.props.history.go(-1)}}>返回</Button>
                    </div>
                </div>
{/*工单详情--------*/}
                <div className={'workOrdersProcess_css_div_head_1'}>
                    <div className={'workOrdersProcess_css_div_head_1_div_frame'}>
                        <div className="workOrdersProcess_css_div_card-header">
                            <h3 className="workOrdersProcess_css_div_card-title">工单详情</h3>

                            <div style={{float:'right'}}>
                                {fromData?.state === '待处理' ? <Tag color={'#1890ff'}>待处理</Tag> :
                                    fromData?.state === '处理中' ? <Tag color={'#faad14'}>处理中</Tag> :
                                        fromData?.state === '已超时' ? <Tag color={'#ff4d4f'}>已超时</Tag>:
                                            fromData?.state === '已完成' ? <Tag color={'#52c41a'}>已完成</Tag>:''}
                            </div>

                        </div>

                        {/*表单*/}
                        <div style={{padding:'30px 30px 0 30px'}}>
                            <Descriptions labelStyle={{color:'#999999'}} column={2} >
                                <Descriptions.Item label="投诉人">{fromData.complainantName}</Descriptions.Item>

                                <Descriptions.Item label="联系电话">{fromData.contactPhone}</Descriptions.Item>

                                <Descriptions.Item label="所在区域">{fromData.area}</Descriptions.Item>
                                <Descriptions.Item label="详细地址">{fromData.address}</Descriptions.Item>

                                <Descriptions.Item label="投诉类型">{fromData?.complaintType??null}</Descriptions.Item>
                                <Descriptions.Item label="紧急程度">
                                    {fromData?.emergencyLevel === 'high' ? <Tag color={'#ff4d4f'}>高</Tag> :
                                        fromData?.emergencyLevel === 'medium' ? <Tag color={'#faad14'}>中</Tag> :
                                            fromData?.emergencyLevel === 'low' ? <Tag color={'#52c41a'}>低</Tag>:''}
                                </Descriptions.Item>

                                <Descriptions.Item label="创建时间">{fromData?.createdTime?moment(fromData?.createdTime).format('yyyy-MM-DD HH:MM'):null}</Descriptions.Item>
                                <Descriptions.Item label="预计完成时间">{fromData?.expectedProcessTime?moment(fromData?.expectedProcessTime).format('yyyy-MM-DD HH:MM'):null}</Descriptions.Item>

                                <Descriptions.Item label="投诉内容" span={2}>{fromData?.processNote??null}</Descriptions.Item>

                                <Descriptions.Item label="附件" span={2}>
                                    {this.state.imageDataS.map(one=>{
                                        return(
                                            <Descriptions.Item>
                                                <div style={{width:'230px',height:'100px',padding:'20px 0 0 0',
                                                    textAlign: 'center',backgroundColor:'#fbfbfb',borderRadius: '3px', border: '1px #eaeaea solid',
                                                    boxShadow: '0 1px 2px -2px rgba(0, 0, 0, 0.16), 0 3px 6px 0 rgba(0, 0, 0, 0.12)'
                                                }}>
                                                    {one?.t?one?.t.indexOf('txt')!==-1
                                                        ? <FileTextOutlined style={{fontSize:'63px',color:'#4a8fd9'}}/> // 文件
                                                        :<FileImageOutlined style={{fontSize:'63px',color:'#4a8fd9'}}/> // 图片
                                                        :<FileUnknownOutlined style={{fontSize:'63px',color:'#4a8fd9'}}/>// 未知
                                                    }
                                                    <Tooltip title={one.n}>
                                                        <div className={'workOrdersProcess_text_div_css_1'} style={{width:'120px'}}>
                                                            <span>{one.n}</span>
                                                        </div>
                                                    </Tooltip>

                                                </div>&nbsp;&nbsp;
                                            </Descriptions.Item>

                                        );

                                    })}
                                </Descriptions.Item>
                            </Descriptions>
                        </div>

                    </div>
                </div>
{/*处理记录--------*/}
                <div className={'workOrdersProcess_css_div_head_2'}>
                    <div className={'workOrdersProcess_css_div_head_2_div_frame'}>
                        <div className="workOrdersProcess_css_div_card-header">
                            <h3 className="workOrdersProcess_css_div_card-title">处理记录</h3>
                        </div>
                        <div style={{padding:'30px',height:'75%',overflowY: 'auto'}}>
                            <Timeline>
                                {this.state.processRecords.map(one=>{
                                    return(
                                        <Timeline.Item>
                                            {
                                                one.operationType.indexOf('新建工单')!==-1?this.desType_1(one)
                                                    :one.operationType.indexOf('工单分配')!==-1?this.desType_2(one):this.desType_3(one)
                                            }
                                        </Timeline.Item>
                                    )

                                })}
                            </Timeline>
                        </div>

                    </div>
                </div>
{/*处理结果--------*/}
                <div className={'workOrdersProcess_css_div_head_3'}>
                    <div className={'workOrdersProcess_css_div_head_3_div_frame'}>
                        <div className="workOrdersProcess_css_div_card-header">
                            <h3 className="workOrdersProcess_css_div_card-title">处理结果</h3>
                        </div>
                        <div style={{padding:'30px 30px 0 30px'}}>
                            <FormsTwo
                                ref={e => this.forms1 = e}
                                formParamenter={this.state.formParam_1}
                                data={this.state.essentialData}
                            />
                            <Upload
                                action={this.state.fileUrl}
                                onChange={this.onChangeUpload}
                            >
                                <button
                                    style={{ color: 'inherit', cursor: 'inherit', border: 0, background: 'none' }}
                                    type="button"
                                >
                                    <Button icon={<UploadOutlined />}>上传附件</Button>
                            </button>
                            </Upload>
                        </div>
                        <div style={{padding:'10px 30px 0 0',height:'60px'}}>
                            <div style={{float:'right'}}>
                                <Button icon={<ArrowLeftOutlined />} onClick={()=>{this.props.history.go(-1)}}>返回</Button>
                                &nbsp;&nbsp;
                                <Button type={'primary'} loading={this.state.loading_1} onClick={()=>{this.processWork();}}>提交并处理结果</Button>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        )
    }
}
