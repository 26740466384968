

import userTracesPage from '../pages/login/userTracesPage'  // 用户登入登出日志管理

// 用户管理
import ClientBusiness from '../pages/client/business/Business'
import ClientBusinessNew from '../pages/client/businessNew/Business_new'

// 居民管理
import resident from '../pages/client/resident/resident'
// 居民管理-新增修改管理
import residentAccount from '../pages/client/resident/residentAccount'
// 工商户管理
import ClientIndustry from '../pages/client/businessIndustry/ClientIndustry'
import BusinessMaintainNew from '../pages/client/businessIndustry/businessMaintainNew'
import InstallationMapNew from '../pages/client/businessIndustry/InstallationMapNew'
import devInstallationNew from '../pages/client/businessIndustry/devInstallationNew'
import unloadDevNew from '../pages/client/businessIndustry/unloadDevNew'
// 工商户安检管理
import industrySecurity from '../pages/client/industrySecurity/industrySecurity'
import industrySecurityDetails from '../pages/client/industrySecurity/industrySecurityDetails'
import securityDetails from  '../pages/client/industrySecurity/securityDetails'
// 工商户安检管理
import SafetySelfInspection from '../pages/client/safetySelfInspection/SafetySelfInspection'
import SafetySelfInspectionDetails from '../pages/client/safetySelfInspection/SafetySelfInspectionDetails'

import SelfInspectionDetails from '../pages/client/safetySelfInspection/SelfInspectionDetails'
import AddSelfInspectionDetails from '../pages/client/safetySelfInspection/AddSelfInspectionDetails'
// 工商户自检报表
import SafetyReport from '../pages/client/report/SafetyReport'
// 工商户安全评估
import SafetyAssessment from '../pages/client/safetyAssessment/SafetyAssessment'
// 工商户安全评估详情
import SafetyAssessmentDetails from '../pages/client/safetyAssessment/SafetyAssessmentDetails'
import SafetyAssessmentDetailsNew from '../pages/client/safetyAssessment/SafetyAssessmentDetailsNew'
// 风险热力图
import RiskMapBox from "../pages/client/riskMap/RiskMapBox";
// 风险热力图
import customerVisualDataInterfaceBox from "../pages/customerVisualDataInterface/customerVisualDataInterfaceBox";
// 风险热力图
import gasDataPanelBox from "../pages/gasDataPanel/gasDataPanelBox";


import InstallationInfo from '../pages/client/businessNew/InstallationInfo'
import devInstallation from '../pages/client/businessNew/devInstallation'
import InstallationMap from '../pages/client/businessNew/InstallationMap'
import unloadDev from '../pages/client/businessNew/unloadDev'
import OpenAccount from '../pages/client/openAccount/OpenAccount'
import OpenAccountNew from '../pages/client/openAccount/openAccountNew'
import InstallTable from '../pages/client/installTable/InstallTable'
import BusinessMaintain from '../pages/client/businessMaintain/BusinessMaintain'


//推送数据
import PushRecord from '../pages/pushData/PushRecord'
import PushRecordDetails from '../pages/pushData/PushRecordDetails'
import PushConfiguration from '../pages/pushData/PushConfiguration'
import PushConfigurationDetails from '../pages/pushData/PushConfigurationDetails'
import YongZhouPushRecord from '../pages/pushData/YongZhouPushRecord'// 永州数据推送
import YongZhouInstrumentInstallation from '../pages/pushData/YongZhouInstrumentInstallation'// 永州表具安装台帐

import DevicePushConfiguration from '../pages/pushData/DevicePushConfiguration'
import DevicePushRecords from '../pages/pushData/DevicePushRecords'
// 报表查询
import DailyConsumption from '../pages/report/dailyConsumption/DailyConsumption'
import DataDifference from '../pages/report/dataDifference/DataDifference'
import InfoManagement from '../pages/report/infoManagement/InfoManagement'
import MeterReading from '../pages/report/meterReading/MeterReading'
import NotReturned from '../pages/report/notReturned/NotReturned'
import Unregistered from '../pages/report/unregistered/Unregistered'
import HistoricalData from '../pages/report/historicalData/HistoricalData'
import RealTimeData from '../pages/report/realTimeData/RealTimeData'
import AbnormalData from '../pages/report/abnormalData/AbnormalData'
import AbnormalUsage from '../pages/report/abnormalUsage/AbnormalUsage'
import historicalReport from '../pages/report/historicalReport/historicalReport'

// 计量点管理
import AddPoint from '../pages/point/addPoint/AddPoint'
import  PointManagement from '../pages/point/management/PointManagement'
import  gasProperties from '../pages/point/gasProperties/gasProperties'
import  gasDevice from '../pages/point/gasDevice/gasDevice'
import  allRelationships from '../pages/point/allRelationships/allRelationships'





// //个人中心
// import PersonalCenter from '../pages/point/personalCenter/PersonalCenter'

// 表具信息管理
import MenterBusiness from '../pages/meter/business/Business'
import MenterManagement from '../pages/meter/management/MeterManagement'

import ModifyLedger from '../pages/meter/ledger/ModifyLedger'
import ReplaceLedger from '../pages/meter/ledger/ReplaceLedger'
import PointModifyLedger from '../pages/meter/ledger/PointModifyLedger'
import RemoveLedger from '../pages/meter/ledger/RemoveLedger'
// import ReportQuery from '../pages/meter/reportQuery/ReportQuery'
import MenterOperating from '../pages/meter/operating/MenterOperating'
import MenterCollection from '../pages/meter/collection/MenterCollection'
import MeterInstall from '../pages/meter/install/MeterInstall'
//  设备信息管理
import equipments from "../pages/equipment/equipment/equipments";
import equipmentTable from "../pages/equipment/equipmentTable/equipmentTable";  // 设备详情页
import equipmentBe from "../pages/equipment/equipmentTable/equipmentBe";  // 设备详情页
import addEquipment from "../pages/equipment/addPage/addEquipment";

import deviceManagement from "../pages/device/management/deviceManagement";  // 设备详情页
import addDevice from "../pages/device/addDevice/addDevice";
import dataItemManagement from "../pages/device/dataItemManagement/dataItemManagement";
import addDataItem from "../pages/device/addDataItem/addDataItem";

import addEquipmentClass from "../pages/equipment/addClassPage/addEquipmentClass";//型号管理

import querySubscriber from "../pages/device/querySubscriber/querySubscriber";//订阅管理

import queryReportConfig from "../pages/device/queryReportConfig/queryReportConfig";//报表规则配置

import getAbandonDevicePaging from "../pages/device/getAbandonDevicePaging/getAbandonDevicePaging";//废弃设备

import handheldDevices from "../pages/equipment/handheldDevices/handheldDevices";// 手持智慧终端

import deviceConfiguration from "../pages/equipment/deviceConfiguration/deviceConfiguration";// 设备配置

import deviceLifeCycle from "../pages/device/deviceLifeCycle/deviceLifeCycle";// 设备生命周期



// 采购商表具管理
import orders from "../pages/procurement/order"
import shopping from "../pages/procurement/shopping";
import shoppingNumber from "../pages/procurement/shoppingNumber";
//  供应商表具管理
// import maintain from '../pages/meter/business/Business'
import maintain from '../pages/meter/management/MeterManagement'
import  HydrogenManagement from '../pages/meter/management/ HydrogenManagement'

import order from "../pages/supplier/order/order";                          //  订单管理
import deliver from "../pages/supplier/deliver/deliver";                    //  送货

//权限管理
import BasicSettings from '../pages/admin/management/BasicSettings'
// import AddPost  from '../pages/admin/add/AddPost'
import AccountCenter from '../pages/admin/management/AccountCenter'
import ApprovalManagement from '../pages/admin/management/ApprovalManagement'
import AddAccount from '../pages/admin/add/AddAccount'
import AddAppUser from '../pages/admin/addAppUser/AddAppUser'

import UpdateInfo from '../pages/admin/update/UpdateInfo'

import UpAppData from '../pages/admin/upAppData/UpAppData'

//app权限管理
import AppBasicSettings from '../pages/admin/management/AppBasicSettings'
import AppAccountCenter from '../pages/admin/management/AppAccountCenter'
import AppApprovalManagement from '../pages/admin/management/AppApprovalManagement'
import OrgTypeManagement from '../pages/admin/management/OrgTypeManagement'





//APP用户审批
import AppApprovalEdit from '../pages/admin/AppApprovalEdit/AppApprovalEdit'


//台账


//安装
import installManagement from '../pages/account/installManagement/installManagement'
//维修
import repairManagement from '../pages/account/repairManagement/repairManagement'
//安检
import securityCheckManagement from '../pages/account/securityCheckManagement/securityCheckManagement'
//换表
import changeMeterManagement from '../pages/account/changeMeterManagement/changeMeterManagement'
//报废
import scrapBookManagement from '../pages/account/scrapBookManagement/scrapBookManagement'
// //首检
import firstMeterManagement from '../pages/account/firstMeterManagement/firstMeterManagement'
//送检
import submissionManagement from '../pages/account/submissionManagement/submissionManagement'


//表具检定

//检定首页
import verificationIndex from '../pages/meterVerifvication/verificationIndex/verificationIndex'
//检定计划
import verificationPlan from '../pages/meterVerifvication/verificationPlan/verificationPlan'
//周检到期
import verificationWeek from '../pages/meterVerifvication/verificationWeek/verificationWeek'



//仓库管理

//出库
import IssueList from '../pages/storeManagement/IssueList/IssueList'
//入库
import warehousingList from '../pages/storeManagement/warehousingList/warehousingList'
//仓库配置
import storeConfig from '../pages/storeManagement/storeConfig/storeConfig'







//台账


//sim卡管理

//SIM卡信息管理
import simCardMsgManagement from '../pages/simManagement/simCardMsgManagement/simCardMsgManagement'
//SIM卡信息管理-厂家
import simCardFacMsgManagement from '../pages/simManagement/simCardFacMsgManagement/simCardFacMsgManagement'
//SIM卡审批管理
import simCardApprovalManagement from '../pages/simManagement/simCardApprovalManagement/simCardApprovalManagement'
//SIM卡报废管理
import simCardScrapManagement from '../pages/simManagement/simCardScrapManagement/simCardScrapManagement'
//我的SIM卡
import mySimCardManagement from '../pages/simManagement/mySimCardManagement/mySimCardManagement'



// 订单管理
// ----订单新奥
import orderFormXinAoManagement from '../pages/orderForm/xinAo/orderFormXinAoManagement'
import orderFormXinAoDetails from '../pages/orderForm/xinAo/orderFormXinAoDetails'

import orderFormGCManagement from '../pages/orderForm/gongChang/orderFormGCManagement'
import orderFormGCDetails from '../pages/orderForm/gongChang/orderFormGCDetails'

import yjlTest from '../pages/orderForm/manufactor/yjlTest'


// import DetailInfo from '../pages/admin/detail/DetailInfo'
import AuthorityManagement from '../pages/admin/management/AuthorityManagement'

import areaTree from "../pages/admin/area/areaTree";

//痕迹管理
import TraceRecord from '../pages/mark/management/TraceRecord'
import UploadRecord from '../pages/data/management/UploadRecord'
//报警系统
import AlarmInfo from '../pages/alarm/management/AlarmInfo'
import AlarmUserManagement from '../pages/alarm/management/AlarmUserManagement'
import OfflineMessage from '../pages/alarm/detail/OfflineMessage'
import MessageManagement from '../pages/alarm/management/MessageManagement'
import OnlineMessage from '../pages/alarm/detail/OnlineMessage'
import AlarmConfig from '../pages/alarm/management/AlarmConfig'

import AlarmHandleInfo from '../pages/alarm/management/details/AlarmHandleInfo'

//工单系统
import WorkOrder from '../pages/work/management/WorkOrder'

// //表具导入
import MeterImportRecord from '../pages/meterImport/MeterImportRecord'
import ImportInfoRecord from '../pages/meterImport/ImportInfoRecord'

//燃气报警器检定
import certificateManagement from '../pages/verifvication/certificateManagement/certificateManagement'
import certificateTypeOneManagement from '../pages/verifvication/certificateManagement/certificateTypeOneManagement'
import bottleManagement from '../pages/verifvication/bottleManagement/bottleManagement'
import entrustManagement from '../pages/verifvication/entrustManagement/entrustManagement'
import warningManagement from '../pages/verifvication/warningManagement/warningManagement'
import addEntrust from '../pages/verifvication/addEntrust/addEntrust'
import verificationRecordManagement from '../pages/verifvication/verificationRecordManagement/verificationRecordManagement'
import customerManagement from '../pages/verifvication/customerManagement/customerManagement'
import verificationRegistration from '../pages/verifvication/verificationRegistration/verificationRegistration'
import verificationMultifunctional from '../pages/verifvication/verificationMultifunctional/verificationMultifunctional'
import getVerificationRecord from '../pages/verifvication/getVerificationRecord/getVerificationRecord'
import TemporaryGasCylinderList from '../pages/verifvication/TemporaryGasCylinderList/TemporaryGasCylinderList'
import verificationRecordManagementToVoid from '../pages/verifvication/verificationRecordManagementToVoid/verificationRecordManagementToVoid'
// import workbench from '../pages/verifvication/workbench/workbench'
import collectionManagement from '../pages/verifvication/collectionManagement/collectionManagement'
import collectionDetail from '../pages/verifvication/collectionDetail/collectionDetail'






//地图DEMO
import mapTest from '../pages/mapDemo/mapTest/mapTest'
import mapDemo from '../pages/mapDemo/mapTest/mapDemo'

//二维码管理
import qrCodeManagement from '../pages/qrCode/qrCodeManagement/qrCodeManagement'
import alarmDetails from '../pages/qrCode/alarmDetails/alarmDetails'
//设备实时监控
import deviceRealTimeData from '../pages/qrCode/deviceRealTimeData/deviceRealTimeData'
//设备实时监控详情
import deviceRealDetails from '../pages/qrCode/deviceRealTimeData/details/deviceRealDetails'
//设备历史监控
import deviceRealHistory from '../pages/qrCode/deviceRealTimeData/details/deviceRealHistory'

// 生命周期
import lifeCycleData from '../pages/qrCode/lifeCycle/lifeCycleData'
// 设备信息管理
import deviceDataInfo from '../pages/qrCode/deviceDataInfo/deviceDataInfo'

// 阀井历史记录
import NewPointManagement from '../pages/qrCode/newPointManagement/NewPointManagement'

// 设备信息配置-报警消息配置信息
import deviceConfigure from '../pages/qrCode/deviceConfigure/deviceConfigure'

import newConfigurePage from '../pages/qrCode/deviceConfigure/details/newConfigurePage'
import CustomerAuth from '../pages/qrCode/deviceConfigure/details/CustomerAuth'

// 区域报警通知配置
import regionDeviceConfigure from '../pages/qrCode/regionDeviceConfigure/regionDeviceConfigure'
// 配置区域报警通知
import newRegionConfigurePage from '../pages/qrCode/regionDeviceConfigure/details/newRegionConfigurePage'


// 设备地图
import deviceMap from '../pages/qrCode/deviceMap/deviceMap'

//
import addDevicePage from '../pages/qrCode/deviceDataInfo/details/addDevicePage'


//蒸汽报表
import gasReport from '../pages/steamReport/gasReport/gasReport'
import consumptionReport from '../pages/steamReport/consumptionReport/consumptionReport'
import detailedReport from '../pages/steamReport/detailedReport/detailedReport'
import gasLossReport from '../pages/steamReport/gasLossReport/gasLossReport'
import realTimeReport from '../pages/steamReport/realTimeReport/realTimeReport'
import warmPressureReport from '../pages/steamReport/warmPressureReport/warmPressureReport'
import detailedReportTable from '../pages/steamReport/detailedReportTable/detailedReportTable'
import consumptionReportTable from '../pages/steamReport/consumptionReportTable/consumptionReportTable'

import usagePlanTable from '../pages/steamReport/usagePlanTable/usagePlanTable'
import planReportTable from '../pages/steamReport/planReportTable/planReportTable'










//ikit管理
import versionInfo from '../pages/IKit/versionInfo/versionInfo'
import devInfo from '../pages/IKit/devInfo/devInfo'
import commentInfo from '../pages/IKit/commentInfo/commentInfo'
// import certificateInfo from '../pages/IKit/certificateInfo/certificateInfo'
// import devStateInfo from '../pages/IKit/devStateInfo/devStateInfo'
// import testDateInfo from '../pages/IKit/testDateInfo/testDateInfo'
// import testResultInfo from '../pages/IKit/testResultInfo/testResultInfo'
import userInfo from '../pages/IKit/userInfo/userInfo'


// 客户报表
import customerReportPage from '../pages/statisticalReport/customerReport/customerReportPage'
// 用气点用量报表
import gasReportPage from '../pages/statisticalReport/customerReport/gasReportPage'
// 表具用量报表
import tablewareReportPage from '../pages/statisticalReport/customerReport/tablewareReportPage'



// 多客户用量对比
import customersAnalysisPage from '../pages/statisticalReport/dataAnalysis/customersAnalysisPage'
// 多表具用量对比
import meterssAnalysisPage from '../pages/statisticalReport/dataAnalysis/meterssAnalysisPage'
// 多用气点用量对比
import gassAnalysPage from '../pages/statisticalReport/dataAnalysis/gassAnalysPage'

// 单客户多时段用量对比
import customerAnalysisPage from '../pages/statisticalReport/dataAnalysis/customerAnalysisPage'
// 单表具多时段用量对比
import metersAnalysisPage from '../pages/statisticalReport/dataAnalysis/metersAnalysisPage'
// 单用气点多时段用量对比
import gasAnalysisPage from '../pages/statisticalReport/dataAnalysis/gasAnalysisPage'
// 单表具多时段温压对比
import metersTPAnalysisPage from '../pages/statisticalReport/dataAnalysis/metersTPAnalysisPage'
// 单用气点多时温压量对比
import gasTPAnalysisPage from '../pages/statisticalReport/dataAnalysis/gasTPAnalysisPage'



// 数值预计信息
import earlyWarningInfo from '../pages/earlyWarning/earlyWarningInfo'
// 数值预计信息
import earlyWarningInfoDispose from '../pages/earlyWarning/earlyWarningInfoDispose'
// 创建数值预警配置
import earlyWarningCU from '../pages/earlyWarning/earlyWarningCU'


// 创建数值预警配置
import transferMap from '../pages/earlyWarning/map/transferMap'

import MeterMap from "../pages/earlyWarning/map/meterMap";

import configurationPage from '../pages/configuration/configurationPage'// 首页配置
import customerConfiguration from '../pages/configuration/customerConfiguration'// 客户配置
import securityInspectionTemplate from '../pages/configuration/securityInspectionTemplate'// 工商户安检模板管理
import selfInspectionTemplate from '../pages/configuration/selfInspectionTemplate'// 工商户安检模板管理

import HiddenDangersOfStoves from '../pages/secure/HiddenDangersOfStoves'// 安全隐患
import approvalOfHiddenDangers from '../pages/secure/approvalOfHiddenDangers'// 安全隐患
import hiddenDangerList from '../pages/secure/hiddenDangerList'// 隐患列表


import textConfiguration from '../pages/textEditing/textConfiguration'// 学习配置
import taskDetails from '../pages/textEditing/taskDetails'        // 任务详情


import community from '../pages/community/community'                        // 小区管理
import addOrModifyCommunity from '../pages/community/addOrModifyCommunity'  // 新增修改小区

import station from '../pages/station/station'                              // 门站管理
import addOrModifyStation from '../pages/station/addOrModifyStation'        // 门站管理


//报警系统
import AlarmInfoNew from '../pages/alarm/management/details/AlarmInfoNew'

//居民自检
import residentIndustrySecurity from '../pages/client/residentIndustrySecurity/residentIndustrySecurity'
//居民安全自检管理-记录明细
import residentIndustrySecurityDetails from '../pages/client/residentIndustrySecurity/residentIndustrySecurityDetails'
//居民安检管理-新增修改明细
import residentSecurityDetails from '../pages/client/residentIndustrySecurity/residentSecurityDetails'
//居民安全自检管理-记录明细
import residentSafetySelfInspection from '../pages/client/residentSafetySelfInspection/residentSafetySelfInspection'
import residentSafetySelfInspectionDetails from '../pages/client/residentSafetySelfInspection/residentSafetySelfInspectionDetails'

// 地图管理
import mapManageBox from '../pages/mapManage/mapManageBox'
import mapManageBox_v1 from '../pages/mapManage/mapManageBox_v.1'
import customerRecord from '../pages/client/customerRecord/customerRecord'

// 巡检计划
import inspection from '../pages/client/inspection/inspection'
// 巡检配置页
import configuration from '../pages/client/inspection/configuration'
// 巡检执行页
import execute from '../pages/client/inspection/execute'
// 巡检执行页
import contentConfiguration from '../pages/client/inspection/contentConfiguration'

import dataCenter from '../pages/dataCenter/dataCenter_v.01'    // 数据统计中心
import workOrder from '../pages/workOrder/workOrder'    // 工单列表
import workOrderDetails from '../pages/workOrder/workOrderDetails'    // 工单详情
import createWorkOrder from '../pages/workOrder/createWorkOrder'    // 新建工单
import workOrdersProcess from '../pages/workOrder/workOrdersProcess'    // 处理工单
import myWorkOrders from '../pages/workOrder/myWorkOrders'    // 处理工单
export const components = {

    myWorkOrders:myWorkOrders,          // 我的工单
    workOrdersProcess:workOrdersProcess,// 处理工单
    createWorkOrder:createWorkOrder,    // 新建工单
    workOrderDetails:workOrderDetails,  // 工单详情
    workOrder:workOrder,            // 工单列表
    dataCenter:dataCenter,      // 数据统计中心

    inspection:inspection,      // 巡检计划
    configuration:configuration,// 巡检配置页
    execute:execute,            //巡检执行页
    contentConfiguration:contentConfiguration,// 巡检内容配置

    pageUserTraces:userTracesPage,  // 用户登入登出日志管理
    customerRecord:customerRecord,//客户导入管理
    mapManageBox:mapManageBox,  // 地图管理
    mapManageBoxV1:mapManageBox_v1,


    residentIndustrySecurity:residentIndustrySecurity,          // 居民户自检
    residentIndustrySecurityDetails:residentIndustrySecurityDetails,// 居民户自检管理-记录明细
    residentSecurityDetails:residentSecurityDetails,                // 居民安检管理-新增修改明细

    residentSafetySelfInspection:residentSafetySelfInspection,              //居民安全自检管理
    residentSafetySelfInspectionDetails:residentSafetySelfInspectionDetails,//居民安全自检管理-记录明细

    deviceConfiguration:deviceConfiguration,// 设备配置


    resident:resident,      //居民管理
    residentAccount:residentAccount,
    station:station,
    addOrModifyStation:addOrModifyStation,

    community:community,    // 小区管理
    addOrModifyCommunity:addOrModifyCommunity,// 新增修改小区

    textConfiguration:textConfiguration,// 学习配置
    taskDetails:taskDetails,            // 任务详情

    HiddenDangersOfStoves:HiddenDangersOfStoves,//安全隐患
    approvalOfHiddenDangers:approvalOfHiddenDangers,// 隐患审批
    hiddenDangerList:hiddenDangerList,// 隐患列表
    configurationPage:configurationPage,        // 首页配置
    customerConfiguration:customerConfiguration,// 客户配置
    securityInspectionTemplate:securityInspectionTemplate,// 工商户安检模板管理
    selfInspectionTemplate:selfInspectionTemplate,// 工商户自检模板管理
    ModifyLedger:ModifyLedger, // 表具修改日志
    ReplaceLedger:ReplaceLedger,
    PointModifyLedger:PointModifyLedger, // 计量点修改日志
    RemoveLedger:RemoveLedger,

    transferMap:transferMap,  // 表具地图

    earlyWarningInfo:earlyWarningInfo,              // 数值预警信息
    earlyWarningInfoDispose:earlyWarningInfoDispose,// 预警配置
    earlyWarningCU:earlyWarningCU,                  // 创建数值预警配置

    customerReportPage:customerReportPage,          // 客户报表
    gasReportPage:gasReportPage,                    // 用气点用量报表
    tablewareReportPage:tablewareReportPage,        // 新客户报表


    customersAnalysisPage:customersAnalysisPage,    // 多客户用量对比
    meterssAnalysisPage:meterssAnalysisPage,        // 多表具用量对比
    gassAnalysPage:gassAnalysPage,                  // 多表具点用量对比
    customerAnalysisPage:customerAnalysisPage,      // 单客户多时段用量对比
    metersAnalysisPage:metersAnalysisPage,          // 单表具多时段用量对比
    gasAnalysisPage:gasAnalysisPage,                // 单用气点多时段用量对比
    metersTPAnalysisPage:metersTPAnalysisPage,      // 单表具多时段温压对比
    gasTPAnalysisPage:gasTPAnalysisPage,            // 单用气点多时温压量对比

    // PersonalCenter:PersonalCenter,
    ClientBusiness: ClientBusiness,
    ClientBusinessNew:ClientBusinessNew,
    ClientIndustry:ClientIndustry,          // 工商客户
    BusinessMaintainNew:BusinessMaintainNew,// 工商户详情页
    InstallationMapNew:InstallationMapNew,  // 安装点地图
    devInstallationNew:devInstallationNew,  // 设备安装
    unloadDevNew:unloadDevNew,// 设备卸载


    industrySecurity:industrySecurity,      // 工商户安检管理
    industrySecurityDetails:industrySecurityDetails,// 工商户安检管理-记录明细

    securityDetails:securityDetails,        // 添加修改记录明细

    SafetySelfInspection:SafetySelfInspection,              // 工商户安全自检
    SafetySelfInspectionDetails:SafetySelfInspectionDetails,// 工商户安全自检-记录明细
    SelfInspectionDetails:SelfInspectionDetails,            // 工商户安全自检-记录详细
    AddSelfInspectionDetails:AddSelfInspectionDetails,

    SafetyReport:SafetyReport,// 工商户自检报表

    SafetyAssessment:SafetyAssessment,
    SafetyAssessmentDetails:SafetyAssessmentDetails,// 工商户安全评估
    SafetyAssessmentDetailsNew:SafetyAssessmentDetailsNew,
    RiskMapBox:RiskMapBox,// 风险热力图
    customerVisualDataInterfaceBox:customerVisualDataInterfaceBox,// 客户可视化数据大屏
    gasDataPanelBox:gasDataPanelBox,
    InstallationInfo:InstallationInfo,
    InstallationMap:InstallationMap,
    devInstallation:devInstallation,
    unloadDev:unloadDev,
    OpenAccount: OpenAccount,
    OpenAccountNew: OpenAccountNew,
    InstallTable: InstallTable,
    BusinessMaintain: BusinessMaintain,

    DailyConsumption: DailyConsumption,
    DataDifference: DataDifference,
    InfoManagement: InfoManagement,
    MeterReading: MeterReading,
    NotReturned: NotReturned,
    Unregistered: Unregistered,
    HistoricalData: HistoricalData,
    RealTimeData: RealTimeData,
    AbnormalData: AbnormalData,
    AbnormalUsage: AbnormalUsage,
    historicalReport:historicalReport,//历史报表
    AddPoint: AddPoint,
    PointManagement : PointManagement,
    gasProperties:gasProperties,
    gasDevice:gasDevice,
    allRelationships:allRelationships,
    MenterBusiness: MenterBusiness,
    MenterManagement: MenterManagement,
    HydrogenManagement:HydrogenManagement,// 氢气管理
    MenterOperating : MenterOperating,
    MenterCollection:MenterCollection,
    MeterInstall: MeterInstall,
    equipments: equipments,
    equipmentTable: equipmentTable,
    equipmentBe:equipmentBe,
    orders: orders,
    shopping: shopping,
    shoppingNumber: shoppingNumber,
    maintain: maintain,

    // openMenter: openMenter,
    order: order,
    deliver: deliver,
    BasicSettings: BasicSettings,
    AppBasicSettings:AppBasicSettings,
    AppAccountCenter: AppAccountCenter,
    AppApprovalManagement:AppApprovalManagement,
    OrgTypeManagement:OrgTypeManagement,
    AccountCenter: AccountCenter,
    ApprovalManagement:ApprovalManagement,
    AddAccount: AddAccount,
    AddAppUser:AddAppUser,
    UpdateInfo: UpdateInfo,
    UpAppData:UpAppData,
    AppApprovalEdit:AppApprovalEdit,
    simCardMsgManagement:simCardMsgManagement,
    simCardApprovalManagement:simCardApprovalManagement,
    simCardScrapManagement:simCardScrapManagement,
    simCardFacMsgManagement:simCardFacMsgManagement,
    mySimCardManagement:mySimCardManagement,
    installManagement:installManagement,
    repairManagement:repairManagement,
    securityCheckManagement:securityCheckManagement,
    changeMeterManagement:changeMeterManagement,
    scrapBookManagement:scrapBookManagement,
    firstMeterManagement:firstMeterManagement,
    submissionManagement:submissionManagement,
    verificationIndex:verificationIndex,
    verificationPlan:verificationPlan,
    verificationWeek:verificationWeek,
    IssueList:IssueList,
    warehousingList:warehousingList,
    storeConfig:storeConfig,
    TraceRecord:TraceRecord,
    AlarmInfo:AlarmInfo,
    AlarmInfoNew:AlarmInfoNew,
    AlarmHandleInfo:AlarmHandleInfo,// 报警处理详情
    AlarmUserManagement:AlarmUserManagement,
    MessageManagement:MessageManagement,
    OfflineMessage:OfflineMessage,
    OnlineMessage:OnlineMessage,
    AlarmConfig:AlarmConfig,
    AuthorityManagement:AuthorityManagement,
    areaTree:areaTree,// 区域树
    UploadRecord:UploadRecord,
    WorkOrder:WorkOrder,
    MeterImportRecord:MeterImportRecord,
    ImportInfoRecord:ImportInfoRecord,
    addEquipment:addEquipment,
    deviceManagement:deviceManagement,
    addDevice:addDevice,
    dataItemManagement:dataItemManagement,
    addDataItem:addDataItem,
    certificateManagement:certificateManagement,
    certificateTypeOneManagement:certificateTypeOneManagement,// 证书列表管理
    bottleManagement:bottleManagement,
    entrustManagement:entrustManagement,
    warningManagement:warningManagement,
    addEntrust:addEntrust,
    verificationRecordManagement:verificationRecordManagement,
    verificationRecordManagementToVoid:verificationRecordManagementToVoid,
    customerManagement:customerManagement,
    verificationRegistration:verificationRegistration,
    verificationMultifunctional:verificationMultifunctional,//多功能页面
    getVerificationRecord:getVerificationRecord,
    TemporaryGasCylinderList:TemporaryGasCylinderList,
    mapTest:mapTest,
    mapDemo:mapDemo,
    qrCodeManagement:qrCodeManagement,
    addEquipmentClass:addEquipmentClass,
    querySubscriber:querySubscriber,
    queryReportConfig:queryReportConfig,
    getAbandonDevicePaging:getAbandonDevicePaging,
    handheldDevices:handheldDevices,// 手持智慧终端

    deviceLifeCycle:deviceLifeCycle,//设备生命周期
    gasReport:gasReport,
    versionInfo:versionInfo,
    devInfo:devInfo,
    commentInfo:commentInfo,
    // certificateInfo:certificateInfo,
    // devStateInfo:devStateInfo,
    // testDateInfo:testDateInfo,
    // testResultInfo:testResultInfo,
    userInfo:userInfo,
    // workbench:workbench,
    collectionManagement:collectionManagement,
    collectionDetail:collectionDetail,
    consumptionReport:consumptionReport,
    detailedReport:detailedReport,
    detailedReportTable:detailedReportTable,
    consumptionReportTable:consumptionReportTable,
    gasLossReport:gasLossReport,
    realTimeReport:realTimeReport,
    warmPressureReport:warmPressureReport,
    alarmDetails:alarmDetails,
    deviceRealTimeData:deviceRealTimeData,
    deviceRealDetails:deviceRealDetails,
    deviceRealHistory:deviceRealHistory,// 历史记录
    addDevicePage:addDevicePage,
    lifeCycleData:lifeCycleData,
    deviceDataInfo:deviceDataInfo,
    newPointManagement:NewPointManagement,// 阀井历史记录
    deviceConfigure:deviceConfigure,
    newConfigurePage:newConfigurePage,
    regionDeviceConfigure:regionDeviceConfigure,    // 区域报警通知管理
    newRegionConfigurePage:newRegionConfigurePage,  // 配置区域报警通知
    CustomerAuth:CustomerAuth,
    deviceMap:deviceMap,
    usagePlanTable:usagePlanTable,
    planReportTable:planReportTable,
    orderFormXinAoManagement:orderFormXinAoManagement,
    orderFormXinAoDetails:orderFormXinAoDetails,
    orderFormGCManagement:orderFormGCManagement,
    orderFormGCDetails:orderFormGCDetails,
    yjlTest:yjlTest,
    //数据推送
    PushRecord:PushRecord,
    PushRecordDetails : PushRecordDetails ,
    PushConfiguration : PushConfiguration ,
    PushConfigurationDetails : PushConfigurationDetails,
    YongZhouPushRecord:YongZhouPushRecord,  // 永州数据推送记录
    YongZhouInstrumentInstallation:YongZhouInstrumentInstallation,

    DevicePushConfiguration:DevicePushConfiguration,// 设备数据推送
    DevicePushRecords:DevicePushRecords,
};

// 路由
export const linkMenus = [

    {
        name: '客户关系管理',
        url: '/customer',
        children: [
            {
                name: '客户管理',
                url: '/clientBusiness',
                components: ClientBusiness,
                children: []
            },
            {
                name: '开户',
                url: '/openAccount',
                components: OpenAccount,
                isSecondaryPage: true,
                children: []
            },
            {
                name: '计量点装表',
                url: '/installTable',
                components: InstallTable,
                isSecondaryPage: true,
                children: []
            },
            {
                name: '计量点业务维护',
                url: '/businessMaintain',
                components: BusinessMaintain,
                isSecondaryPage: true,
                children: []
            }
        ]
    },
    {
        name: '计量点信息管理',
        url: '/point',
        children: [
            {
                name: '计量点管理',
                url: '/pointManagement',
                components: PointManagement,
                children: []
            },
            {
                name: '计量点新增',
                url: '/addPoint',
                components: AddPoint,
                isSecondaryPage: true,
                children: []
            },
            // {
            //     name: '个人中心',
            //     url: '/personalCenter',
            //     components: PersonalCenter,
            //     isSecondaryPage: true,
            //     children: []
            // },
        ]
    },
    {
        name: '表具信息管理',
        url: '/meter',
        children: [
            {
                name: '表具管理',
                url: '/meterManagement',
                components: MenterManagement,
                children: []
            },
            {
                name: '表具操作',
                url: '/meterOperating',
                components: MenterOperating,
                isSecondaryPage: true,
                children: []
            },
            {
                name: '表具安装',
                url: '/meterInstall',
                components: MeterInstall,
                isSecondaryPage: true,
                children: []
            },
            // {
            //     name: 'ddddd',
            //     url: '/dynamicPpanel',
            //     components: DynamicPpanel,
            //     children: []
            // },
            {
                name: '表具业务',
                url: '/meterBusiness',
                components: MenterBusiness,
                isSecondaryPage: true,
                children: []
            }
        ]
    },
    {
        name: '报表查询',
        url: '/report',
        children: [
            {
                name: '用户导入数据差异报表',
                url: '/dataDifference',
                components: DataDifference,
                children: []
            },
            {
                name: '用户信息管理报表',
                url: '/infoManagement',
                components: InfoManagement,
                children: []
            },
            {
                name: '表具历史数据查询报表',
                url: '/historicalData',
                components: HistoricalData,
                children: []
            },
            {
                name: '用户抄表和未回传明细报表',
                url: '/notReturned',
                components: NotReturned,
                children: []
            },
            // {
            //     name: '抄表率统计报表',
            //     url: '/meterReading',
            //     components: MeterReading,
            //     children: []
            // },
            {
                name: '表具实时数据报表',
                url: '/realTimeData',
                components: RealTimeData,
                children: []
            },
            {
                name: '表具异常数据报表',
                url: '/abnormalData',
                components: AbnormalData,
                children: []
            },
            {
                name: '未注册用户报表',
                url: '/unregistered',
                components: Unregistered,
                children: []
            },
            // {
            //     name: '日用气量报表',
            //     url: '/dailyConsumption',
            //     components: DailyConsumption,
            //     children: []
            // },
            // {
            //     name: '表具异常用气报表',
            //     url: '/abnormalUsage',
            //     components: AbnormalUsage,
            //     children: []
            // }
        ]
    },
    {
        name: '设备信息管理',
        url: '/device',
        children: [
            {
                name: '设备管理',
                url: '/equipments',
                components: equipments,
                isSecondaryPage: true,  // 现实开关
                children: []
            },
            {
                name: '设备详情页',
                url: '/equipmentTable',
                components: equipmentTable,
                isSecondaryPage: true,  // 现实开关
                children: []
            },
            {
                name: '设备通信失败记录',
                url: '/equipmentBe',
                components: equipmentBe,
                isSecondaryPage: true,  // 现实开关
                children: []
            },
            {
                name: '设备信息管理',
                url: '/addEquipment',
                components: addEquipment,
                isSecondaryPage: true,  // 现实开关
                children: []
            },
        ]
    },
    //  采购商表具管理
    {
        name: '采购管理',
        url: '/purchase',
        children: [
            {
                name:'订单管理',
                url:'/orders',
                components:orders,
                children:[]
            },
            {
                name:'购物车',
                url:'/shopping',
                components:shopping,
                isSecondaryPage: true,  // 现实开关
                children:[]
            },
            {
                name:'购物车',
                url:'/shoppingNumber',
                components:shoppingNumber,
                isSecondaryPage: true,  // 现实开关
                children:[]
            },
        ]
    },
    // 供应商表具管理
    {
        name: '供应商表具管理',
        url: '/supplier',
        children: [
            {
                name: '表具管理',
                url: '/maintain',
                components: maintain,
                children: []
            },
            // {
            //     name: '表具操作',
            //     url: '/openMenter',
            //     components: openMenter,
            //     isSecondaryPage: true,
            //     children: []
            // },

            {
                name:'订单管理',
                url:'/order',
                components:order,
                children:[]
            },
            {
                name:'送货管理',
                url:'/deliver',
                components:deliver,
                isSecondaryPage: true,  // 现实开关
                children:[]

            },
        ]
    },//

    //用户权限管理
    {
        name: '权限管理',
        url: '/auth',
        children: [
            {
                name: '组织结构管理',
                url: '/basicSettings',
                components: BasicSettings,
                children: []
            },
            {
                name: '账户中心',
                url: '/accountCenter',
                components: AccountCenter,
                children: []
            },
            {
                name: '审批管理',
                url: '/approvalManagement',
                components: ApprovalManagement,
                children: []
            },

            {
                name: '权限管理',
                url: '/authorityManagement',
                components: AuthorityManagement,
                children: []
            },
            {
                name: '账户新增',
                url: '/addAccount',
                components: AddAccount,
                isSecondaryPage: true,
                children: []
            },
            {
                name: '修改信息',
                url: '/updateInfo',
                components: UpdateInfo,
                isSecondaryPage: true,
                children: []
            },
            {
                name: '客户权限',
                url: '/customerAuth',
                components: CustomerAuth,
                isSecondaryPage: true,
                children: []
            },

        ]
    },//

    //痕迹管理
    {
        name: '痕迹管理',
        url: '/trace',
        children: [
            {
                name: '痕迹记录',
                url: '/traceRecord',
                components: TraceRecord,
                children: []
            },
        ]
    },
    //表具导入
    {
        name: '表具导入管理',
        url: '/meterImport',
        children: [
            {
                name: '表具导入记录',
                url: '/meterImportRecord',
                components: MeterImportRecord,
                children: []
            },
            {
                name: '导入信息记录',
                url: '/importInfoRecord',
                components: ImportInfoRecord,
                children: []
            },
        ]
    },

    //数据管理
    {
        name: '上传数据管理',
        url: '/uploaddata',
        children: [

            {
                name: '上传数据记录',
                url: '/uploadRecord',
                components: UploadRecord,
                children: []
            }
        ]
    },

     //报警管理
     {
        name: '报警管理',
        url: '/alarmMent',
        children: [
            {
                name: '报警信息',
                url: '/alarmInfo',
                components: AlarmInfo,
                children: []
            },
            {
                name: '查询报警信息',
                url: '/AlarmInfoNew',
                components: AlarmInfoNew,
                children: []
            },


            // {
            //     name: '报警统计',
            //     url: '/alarmStatistics',
            //     components: AlarmStatistics,
            //     children: []
            // },
            {
                name: '报警通知人管理',
                url: '/alarmUserManagement',
                components: AlarmUserManagement,
                children: []
            },
            {
                name: '报警配置',
                url: '/alarmConfig',
                components: AlarmConfig,
                children: []
            },
            {
                name: '消息管理',
                url: '/messageManagement',
                components: MessageManagement,
                children: []
            },
            {
                name: '即时消息',
                url: '/onlineMessage',
                components: OnlineMessage,
                isSecondaryPage: true,
                children: []
            },
            {
                name: '离线消息',
                url: '/offlineMessage',
                components: OfflineMessage,
                isSecondaryPage: true,
                children: []
            },
        ]
    },//报警管理

    {
        name: '工单管理',
        url: '/work',
        children: [
            {
                name: '工单信息',
                url: '/workOrder',
                components: WorkOrder,
                children: []
            }
        ]
    },//工单管理

    // {
    //     name: '工单管理',
    //     url: '/map',
    //     children: [
    //         {
    //             name: '设备地图',
    //             url: '/meterMap',
    //             components: MeterMap,
    //             children: []
    //         }
    //     ]
    // },//工单管理

]
