import React, { Component } from 'react'
import {Button, DatePicker, Divider, Form, Input, Layout, message, Modal, Select, Space, Spin, Table, Tabs,Radio} from "antd";

import { StepBackwardOutlined ,StepForwardOutlined,ArrowLeftOutlined} from '@ant-design/icons';
import {getAllClassAndTemp} from "../../../services/equipment";
import {pageValveInfo} from "../../../services/qrCode";
import {
    addInspectionDev,
    addPoPatrolScheme,
    getInspectionDevInPatrolId,
    removeInspectionDev,
    updatePoPatrolScheme
} from "../../../services/meter";
import moment from "moment";
const { RangePicker } = DatePicker;
const { TextArea } = Input;
const {TabPane} = Tabs;

// 巡检计划
export default class configuration extends Component {
    state={
        noMsgJuder_1:false,// 加载按钮
        isModalOpen_1:false,// 弹窗-1-设备
        isModalName_1:'添加',//弹窗标题-1
        isModalName_2:'添加',//弹窗标题-2
        InputData:'',      // 设备输入框
        initialValues_1:{},// 表单内容-1-巡检
        initialValues_2:{},// 表单内容-2-设备

        // 设备列表-1-设备类型列表
        optionDataS_1:[],
        // 设备列表-2-设备编号列表
        optionDataS_2:[],
        // 设备列表-2-查询参数
        optionPage: {
            devType:null,
            page: 1,
            size:5,
            AllPage:0,
        },
        // 弹窗-设备表格-标头
        tableData_1_head:[
            {title: '设备编号', dataIndex: 'devId', key: 'devId'},
            {title: '设备类型', dataIndex: 'devType', key: 'devType',
                render: (text, rowData,index) => {
                    if(!text)return false;
                    let name = '-';
                    this.state.optionDataS_1.map(one=>{
                        if(Number(text) === Number(one.value)){
                            name = one.label;
                        }});
                    return<><div style={{width:'100%'}}>{name}</div></>
                }
            },
            {title: '操作', dataIndex: 'edit',width:'200px', align:'center',
                render: (text, rowData,index) => {
                    return<>
                        <div style={{width:'100%'}}>
                            <div  title={'删除'} style={{cursor: 'pointer',width:'60px',float:'left'}}>
                                <u style={{color:'red'}} onClick={()=>{this.deleteDev(rowData);}}>删除</u>
                            </div>
                        </div>
                    </>
                }
            }
        ],
        tableData_1_data:[], // 弹窗-设备表格-数据
        tableData_1_loading:false, // 弹窗-设备表格-加载

        // 弹窗-设备表格-标头
        tableData_2_head:[
            {title: '线路编号', dataIndex: 'route', key: 'route'},
            {title: '线路名称', dataIndex: 'name', key: 'name',},
            {title: '街道', dataIndex: 'street', key: 'street',},
            {title: '描述', dataIndex: 'des', key: 'des',},
            {title: '状态', dataIndex: 'state', key: 'state',},
            {title: '操作', dataIndex: 'edit',width:'200px', align:'center',
                render: (text, rowData,index) => {
                    return<>
                        <div style={{width:'100%'}}>
                            <div  title={'删除'} style={{cursor: 'pointer',width:'60px',float:'left'}}>
                                <u style={{color:'red'}} onClick={()=>{}}>删除</u>
                            </div>
                        </div>
                    </>
                }
            }
        ],
        tableData_2_data:[],        // 弹窗-设备表格-数据
        tableData_2_loading:false,  // 弹窗-设备表格-加载
    };
    componentWillMount = async ()=> {
        // 初始化数据整理
        var rowData = this.props?.location.state?.rowData;
        if(rowData){
            if(rowData?.startTimeOfPlan && rowData?.endTimeOfPlan){
                rowData.dateTime=[moment(rowData?.startTimeOfPlan),moment(rowData?.endTimeOfPlan)];
            }
            if(rowData?.inspector && typeof rowData?.inspector === "string"){
                rowData.inspector=rowData?.inspector?JSON.parse(rowData?.inspector):null;
            }
            this.setState({initialValues_1:rowData});
            this.getInspectionDevInPatrolId(rowData.id); // 根据巡检计划ID查询巡检设备
        }

        // 查询设备类型
        getAllClassAndTemp().then(e=>{
            var datas=[];
            e?.data?.data.map(one=>{datas.push({key:one.devClassId,value:one.devClassId,label:one.devClassName})});
            this.setState({optionDataS_1:e?.data?.data??[]})
        });

    };


    // 根据巡检计划ID查询巡检设备
    getInspectionDevInPatrolId=(id)=>{

        getInspectionDevInPatrolId({patrolId:id}).then(
            response=>{
                if(response?.data?.data){
                    this.setState({tableData_1_data:response?.data?.data});
                }else{
                    this.setState({tableData_1_data:[]});
                }
            }
        )
    };



    // 新增巡检计划
    addPoPatrolScheme=async()=> {
        // 巡检表单
        var formData = await this.form1.validateFields();
        if (!formData) {return false;}
        var param = {
            'areaId':JSON.parse(localStorage.getItem('menus'))?.[0],    // 区域id
            "area": formData.area??null,                                    // 巡检区域
            "createTime": null,                                             // 创建时间
            "description": formData.description??null,                      // 计划描述
            "startTimeOfPlan": formData.dateTime ?moment(formData.dateTime[0]._d) : null, // 计划开始时间
            "endTimeOfPlan": formData.dateTime ?moment(formData.dateTime[1]._d) : null,   // 计划结束时间
            "id": null,                    // id
            "inspector": formData.inspector?JSON.stringify(formData.inspector):null ,     // 巡检人
            "name": formData.name??null,            // 计划名称
            "planNumber": null,                     // 计划编号
            "state": 0,                             // 计划状态(0-未开始/1-进行中/2-已完成)
            "updateTime": null                      // 修改时间
        };
        this.setState({noMsgJuder_1:true});   // 加载按钮
        // 区域选择
        if(JSON.parse(localStorage.getItem('menus'))?.[0]===undefined){return message.info("请选择区域")}
        console.log(this.state.tableData_1_data.length);
        Modal.confirm({
            title: `是否绑定到${localStorage.getItem('areaName')}?`,
            onOk: () => {
                addPoPatrolScheme(param).then(
                    response=>{
                        if(response?.data?.data){
                            message.info('提交成功');
                            this.setState({noMsgJuder_1:false,isModalOpen_1:false});
                            if(this.state.tableData_1_data.length!==0){
                                this.addInspectionDev(response?.data?.data);    // 新增巡检设备
                            }
                            this.props.history.go(-1);
                        }else{
                            message.info('提交失败')
                        }
                    }
                );
            }
        });
    };

    // 修改巡检计划
    updatePoPatrolScheme = async () => {
        var formData = await this.form1.validateFields();
        if (!formData) {return false;}
        var initialValues1 = this.state.initialValues_1;// 选取的巡检计划

        var param = {
            'areaId':JSON.parse(localStorage.getItem('menus'))?.[0],    // 区域id
            "area": formData.area??null,    // 巡检区域
            "createTime": null,             // 创建时间
            "description": formData.description??null,  // 计划描述
            "startTimeOfPlan": formData.dateTime ?moment(formData.dateTime[0]._d) : null, // 计划开始时间
            "endTimeOfPlan": formData.dateTime ?moment(formData.dateTime[1]._d) : null,   // 计划结束时间
            "id": initialValues1?.id??null,                 // id
            "inspector": formData.inspector?JSON.stringify(formData.inspector):null ,  // 巡检人
            "name": formData.name??null,                    // 计划名称
            "planNumber": initialValues1?.planNumber??null, // 计划编号
            "state": 0,                                     // 计划状态(0-未开始/1-进行中/2-已完成)
            "updateTime": null                              // 修改时间
        };
        this.setState({noMsgJuder_1:true});
        // 区域选择
        if(JSON.parse(localStorage.getItem('menus'))?.[0]===undefined){return message.info("请选择区域")}
        Modal.confirm({
            title: `是否绑定到${localStorage.getItem('areaName')}?`,
            onOk: () => {
                updatePoPatrolScheme(param).then(
                    response=>{
                        if(response?.data?.data){
                            message.info('修改成功');
                            this.setState({isModalOpen_1:false});
                            // 判断有无巡检设备
                            if(this.state.tableData_1_data.length!==0){
                                this.addInspectionDev(this.state.initialValues_1.id); // 新增巡检设备
                            }
                            this.props.history.go(-1);

                        }else{
                            message.info('提交失败')
                        }
                        this.setState({noMsgJuder_1:false});
                    }
                );
            }
        });
    };
    // 后台请求-新增巡检设备
    addInspectionDev = async (patrolSchemeId) =>{
        var tableData1Data = this.state.tableData_1_data;
        tableData1Data.map(one=>{
            if(!one?.id){
                one.patrolSchemeId=patrolSchemeId;
                addInspectionDev(one).then(
                    response=>{
                        if(!response?.data?.data){return message.info('设备提交失败');}
                    }
                );
            }
        });

        this.setState({tableData_1_data:tableData1Data,isModalOpen_1:false})
    };

    // 本地-添加巡检设备
    addDev = async () =>{
        // 获取添加设备的表单内容
        var data = await this.form2.validateFields();
        if(!data){return false;}
        var initialValues3 = this.state.initialValues_3;// 内框内选取的设备
        var optionPage = this.state.optionPage;         // 设备表格参数-内保存选取的设备类型
        var param={
            "createTime": null,                         // 创建时间
            "devId": initialValues3?initialValues3.devId:data.devId,        // device服务主键id
            "devType": optionPage?.devType??null,       // 设备类型
            "id": null,                                 // Id
            "patrolSchemeId": null,                     // 巡检计划Id
            "source": initialValues3?'系统内':'系统外',  // 来源(系统内/系统外)
            "updateTime": null                          // 修改时间
        };
        var tableData1Data = [...this.state.tableData_1_data];
        tableData1Data.push(param);
        this.setState({tableData_1_data:tableData1Data,isModalOpen_1:false})

    };
    // 删除设备
    deleteDev=(data)=>{
        var tableData1Data = [...this.state.tableData_1_data];
        // 删除设备，有id需要走后台操作
        if(data.id){
            this.setState({tableData_1_loading: true});
            removeInspectionDev({id:data.id}).then(
                response=>{
                    if(!response?.data?.data){return message.info('删除失败');}
                    for(var i=0;i<tableData1Data.length;i++){
                        if(tableData1Data[i].id===data.id){tableData1Data.splice(i,1);}
                    }
                    this.setState({tableData_1_loading: false,tableData_1_data:tableData1Data});
                }
            );
        }else{
            for(var i=0;i<tableData1Data.length;i++){
                if(tableData1Data[i].devId===data.devId){tableData1Data.splice(i,1);}
            }
            this.setState({tableData_1_loading: false,tableData_1_data:tableData1Data});
        }
    };

    // 弹窗-刷新设备列表,查询号码，类型，页数
    pageValveInfo=(steelNum,devType,page)=>{
        var optionPage = this.state.optionPage;
        var param={
            steelNum:steelNum??null,
            devType:devType??null,
            size:optionPage.size,
            page:page??1
        };
        pageValveInfo(param).then(
            response=>{
                if(response?.data?.data){
                    optionPage.AllPage=Math.ceil(response?.data?.pageInfo.total/optionPage.size);
                    this.setState({optionPage:optionPage});
                }
                this.setState({optionDataS_2:response?.data?.data??[]});
            }
        )
    };



    render(){
        const {
            initialValues_1,initialValues_2,noMsgJuder_1,isModalOpen_1,
            isModalName_1,tableData_1_head,tableData_1_data,tableData_1_loading,
            optionDataS_1,optionDataS_2,optionPage,InputData
        } = this.state;
        return (
            <Layout className={'item_body_number_back tables'} style={{backgroundColor: '#ffffff'}}>
                <div style={{width:'100%',height:'100%'}}>
                    {/*等待页面---------------------------------------------------------------------------------------*/}
                    <div>
                        <div style={{
                            display:noMsgJuder_1? null:'none', position: 'absolute', top:'0',
                            paddingTop:'10%', width:'96%', height:'100%', fontSize:'30px', textAlign:'center',
                            overflow:'auto', color:'#797979', zIndex:'2', backgroundColor: 'rgba(245,245,245,0.5)'
                        }}>
                            <Spin />等待中
                        </div>
                    </div>
                    {/*表单-------------------------------------------------------------------------------------------*/}
                    <div style={{width:'100%',height:'430px'}}>
                        <div style={{width:'100%',height:'50px'}}>
                            <div style={{width:'100%',height:'42px',padding:'5px 0 0 10px'}}>
                                <div style={{width:'87%',height:'100%',float:'left'}}>
                                    <span style={{fontSize:'18px',fontWeight:'bold'}}>基本信息</span>
                                </div>
                                <div style={{width:'12%',height:'100%',float:'left'}}>
                                    <Button style={{margin:'0 0 0 20px'}} onClick={() => this.props.history.go(-1)}>返回</Button>
                                    &nbsp;&nbsp;
                                    <Button type="primary"  htmlType="submit"
                                            onClick={()=>{
                                                // 选取的巡检表
                                                if(initialValues_1?.id){
                                                    this.updatePoPatrolScheme();// 修改巡检计划
                                                }else{
                                                    this.addPoPatrolScheme();   // 新增巡检计划
                                                }
                                            }}
                                    >保存</Button>

                                </div>
                            </div>
                            <div style={{width:'100%',height:'1px',backgroundColor:'#f1f1f1'}}/>
                        </div>
                        <div style={{width:'100%',height:'400px',overflowY: 'auto'}}>
                            <Form
                                ref={e => this.form1 = e}
                                labelCol={{ span: 4 }}
                                wrapperCol={{ span: 15 }}
                                layout="horizontal"
                                initialValues={initialValues_1}
                                style={{ maxWidth: 600 }}
                                onFinish={(value)=>{}}
                                onFinishFailed={(value)=>{}}
                            >
                                <Form.Item label="计划名称" name={'name'} rules={[{ required: true }]}>
                                    <Input />
                                </Form.Item>
                                <Form.Item label="计划时间" name={'dateTime'} rules={[{ required: true }]}>
                                    <RangePicker />
                                </Form.Item>
                                <Form.Item label="巡查区域" name={'area'} rules={[{ required: true }]}>
                                    <TextArea rows={3} />
                                </Form.Item>
                                <Form.Item label="计划描述" name={'description'}>
                                    <TextArea rows={3} />
                                </Form.Item>
                                <Form.Item label="巡检人" name={'inspector'} rules={[{ required: true }]}>
                                    <Select
                                        mode="tags"
                                        style={{ width: '100%' }}
                                        onChange={(text)=>{}}
                                        tokenSeparators={[',']}
                                    />
                                </Form.Item>
                            </Form>
                        </div>
                    </div>
                    {/*  功能列表  */}
                    <div style={{width:'100%',height:'380px',padding:'5px'}}>
                        <Tabs
                            // activeKey={activeKey}// onTabClick={this.tabsClick}
                            tabPosition={"top"} animated={true}
                        >
{/*设备信息----------------------------------------------*/}
                            <TabPane tab="设备信息" key="1">
                                <div>
                                    {/*添加设备按钮*/}
                                    <div style={{width:'100%',height:'40px'}}>
                                        <div style={{float:'right'}}>
                                            <Button type="primary" htmlType="submit" onClick={()=>{this.setState({isModalOpen_1:true})}}>添加设备</Button>
                                        </div>
                                    </div>
                                    {/*表格*/}
                                    <div>
                                        <Table
                                            columns={tableData_1_head}
                                            dataSource={tableData_1_data}
                                            bordered
                                            loading={tableData_1_loading}
                                            pagination = {{
                                                showSizeChanger:true,
                                                showQuickJumper: true,
                                                total:tableData_1_data.length,
                                                pageSize:4,
                                                showTotal: (count = tableData_1_data.length)=>{
                                                    return '共' + tableData_1_data.length+'条数据'
                                                },
                                                defaultCurrent: 1,
                                                pageSizeOptions: ['4']
                                            }}
                                        />
                                    </div>
                                </div>
                            </TabPane>
                        </Tabs>
                    </div>
{/*  保存--------------------------------------------  */}
{/*                    <div style={{width:'100%',height:'50px',padding:'5px'}}>*/}
{/*                        <div style={{float:'right'}}>*/}
{/*                            <div style={{width:'100px',float:'left'}}>*/}
{/*                                <Button type="primary"  htmlType="submit"*/}
{/*                                        onClick={()=>{*/}
{/*                                            // 选取的巡检表*/}
{/*                                            if(initialValues_1?.id){*/}
{/*                                                this.updatePoPatrolScheme();// 修改巡检计划*/}
{/*                                            }else{*/}
{/*                                                this.addPoPatrolScheme();   // 新增巡检计划*/}
{/*                                            }*/}
{/*                                        }}*/}
{/*                                >保存</Button>*/}
{/*                            </div>*/}
{/*                        </div>*/}
{/*                    </div>*/}
{/*内部弹窗-设备----------------------------------------------------------*/}
                    <Modal
                        title={isModalName_1}
                        okText="确定"
                        open={isModalOpen_1}
                        onOk={()=>{this.addDev();}}
                        onCancel={()=>{
                            this.form2.resetFields();   // 清空表2数据
                            this.setState({isModalOpen_1:false,initialValues_2:{}});
                        }}
                        width={600}
                        centered
                    >
                        <div style={{width:'100%'}}>
                            <div style={{paddingLeft:'30px'}}>
                                <Form
                                    ref={e => this.form2 = e}
                                    labelCol={{ span: 5 }}
                                    wrapperCol={{ span: 14 }}
                                    layout="horizontal"
                                    initialValues={initialValues_2}
                                    style={{ maxWidth: 600 }}
                                    onFinish={(value)=>{}}
                                    onFinishFailed={(value)=>{}}

                                >
                                    <Form.Item name="devType" label="设备类型" rules={[{ required: true }]}>
                                        <Select allowClear
                                                fieldNames={{ label: 'devClassName', value: 'devClassId', key:'devClassId' }}
                                                options={optionDataS_1}
                                                onChange={(a,b)=>{
                                                    optionPage.devType=a;
                                                    optionPage.page=1;
                                                    optionPage.AllPage=0;
                                                    this.pageValveInfo(null,a,1);
                                                }}
                                        />
                                    </Form.Item>

                                    <Form.Item label="设备编号" name={'devId'} rules={[{ required: true }]}>
                                        <Select
                                            fieldNames={{ label: 'devId', value: 'devId', key:'id' }}
                                            options={optionDataS_2}
                                            onChange={(a,b)=>{this.setState({initialValues_3:b})}}
                                            dropdownRender={(menu) => (
                                                <>
                                                    {menu}
                                                    <Divider style={{ margin: '8px 0' }} />
                                                    <Space style={{ padding: '0 8px 4px' }}>
                                                        <div>
                                                            <Input placeholder={'手输编号'} style={{width:'120px'}}
                                                                   value={InputData}
                                                                   onChange={(text)=>{
                                                                       this.setState({initialValues_3:null, InputData:text.target.value,});
                                                                   }}
                                                            />
                                                            &nbsp;
                                                            <a type="text"
                                                               onClick={()=>{this.form2.setFieldsValue({['devId']:InputData});}}>
                                                                添加
                                                            </a>
                                                            <span>&nbsp;&nbsp;总页数&nbsp;{optionPage.AllPage}&nbsp;</span>
                                                            {/*上一页*/}
                                                            <StepBackwardOutlined
                                                                style={{
                                                                    color:optionPage.page<=1?'#ccc':'#5496d9',
                                                                    padding: '0 3px 4px',
                                                                    cursor:'pointer'}}
                                                                onClick={()=>{
                                                                    if(optionPage.page<=1){return false;}
                                                                    optionPage.page=optionPage.page-1;
                                                                    this.pageValveInfo(null,optionPage.devType,optionPage.page);
                                                                }}
                                                            />
                                                            {/*所在页数*/}
                                                            <span>{optionPage.page}</span>
                                                            {/*下一页*/}
                                                            <StepForwardOutlined
                                                                style={{
                                                                    color:optionPage.page>=optionPage.AllPage?'#ccc':'#5496d9',
                                                                    padding: '0 3px 4px' ,
                                                                    cursor:'pointer'}}
                                                                onClick={()=>{
                                                                    if(optionPage.page>=optionPage.AllPage){return false;}
                                                                    optionPage.page=optionPage.page+1;
                                                                    this.pageValveInfo(null,optionPage.devType,optionPage.page);
                                                                }}
                                                            />
                                                        </div>
                                                    </Space>
                                                </>
                                            )}
                                        />
                                    </Form.Item>
                                </Form>
                            </div>
                        </div>
                    </Modal>
                </div>
            </Layout>
        );
    }
}
